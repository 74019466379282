.MyProfilePublicBlockOverAll{
    width: 1156px;
    margin: 0 auto;
    margin-top: 30px;
}
.MyProfileLeft{
    margin-right: 30px;
}
.MyProfileAccordionoverall{
    border-radius: 10px!important;
    background-color: #F9F9FC!important;
    padding: 16px 14px!important;
}
.MyProfileAccordion{
    background-color: #F9F9FC!important;
    border: none!important;
}
.MyProfileAccordionUL{
    padding: 0px!important;
}
.MyProfileAccordionUL li{
    margin-bottom: 10px!important;
}
.MyProfileAccordionUL li a{
    display: flex!important;
    align-items: center!important;
    justify-content: center!important;
    text-align: start!important;    
    color: #19191D!important;
    font-size: 16px!important;
    line-height: 42px!important;
    font-weight: 400!important;
}
.MyProfileAccordionULbg a{
    background-color: #E1E1E3!important;
    font-weight: 600!important;
    border-radius: 5px!important;
}
.MyProfileAccordionbutton:focus, .MyProfileAccordionbutton:focus-visible{
    outline: none!important;
    border: none!important;
    box-shadow: none!important;
    border-color: transparent!important;
}
.MyProfileBlockSpan span{
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #463209;
}
.MyProfileBlockIcon{
    font-size: 16px;
    color: #B88217;
    margin-right: 7px;
}
.MyProfileBlockIcon i{
    padding: 6px;
    border-radius: 50%;
    background-color: #FFEFD1;
}
.MyProfilePublicBlockSpan span{
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #463209;
}
.MyProfilePublicBlockIcon{
    font-size: 16px;
    color: #B88217;
    margin-right: 7px;
}
.MyProfilePublicBlockIcon i{
    padding: 6px;
    border-radius: 50%;
    background-color: #FFEFD1;
}
.MyProfileA{
    margin-right: 16px!important;
}
.MyProfileA a{
    padding: 1px 7px!important;
    background-color: #4A4B57!important;
    font-size: 14px!important;
    font-weight: 500!important;
    line-height: 20px!important;
    color: #F7F7FA!important;
    border-radius: 10px!important;
}
.MyProfileAbg a{
    background-color: #E1E1E3!important;
    color: #19191D!important;
}
.MyProfileAbg1 a{
    background-color: #19191D!important;
    color: #E1E1E3!important;
}
.MyProfileAccordionh2{
    line-height: 42px!important;
    margin-bottom: 10px!important;
}
.MyProfileAccordionbutton{
    font-size: 16px!important;
    font-weight: 500!important;
    background-color: #F9F9FC!important;    
    box-shadow: none!important;
    border: none!important;
    border-color: none!important;
    outline: none!important;
    text-align: right!important;
    display: flex;
    padding: 0px!important;
}
.MyProfileDownArrow i{
    font-size: 20px!important;
    margin-right: 14px!important;
}
.MyProfileAccordionbutton::after{
    background: none!important;
    content: none!important;    
}
.MyProfileAccordionbutton:not(.collapsed){
    color: #19191D!important;
}
.MyProfileAccordionbutton span{
    color: #19191D!important;
    font-size: 16px!important;
    line-height: 24px!important;
    font-weight: 500!important;
}
.MyProfileSpan{
    line-height: 42px;
    margin-bottom: 10px!important;
}
.MyProfileSpanLast{
    margin-bottom: 0px!important;
}
.MyProfileSpan span{
    margin-left: 16px!important;
    font-size: 16px!important;
    font-weight: 500!important;
    cursor: pointer;
}
.MyProfileRight{
    border: 1px solid #E1E1E3;
    border-radius: 12px;
}
.MyProfilePictureSection{
    padding: 25px 30px 25px 40px;
    border-bottom: 1px solid #E1E1E3;
}
.LastBoxBorder{
    border: 1px solid #E1E1E3!important;
    border-radius: 0px 0px 12px 12px!important;
}
.MyProfileImgPart{
    padding: 36px 0px 14px 0px;
}
.MyProfilePublicBlockh6 h6{
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    color: #19191D;
}
.MyProfileh6 h6{
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    color: #19191D;
}
.MyProfileImg img{
    width: 170px;
    height: 170px;
    object-fit: cover;
    border-radius: 50%;
}
.MyProfileImgEdit{
    width: 56px;
    height: 56px;
    background-color: #5A5B6A;
    border-radius: 50%;
    bottom: -8px;
    right: 21px;
    box-shadow: 1px 2px #E1E1E3;
}
.MyProfileImgEdit i{
    font-size: 17px;
    color: #F7F7FA;
}
.MyProfileConth5 h5{
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    color: #19191D;
}
.MyProfileNameSection{
    margin-bottom: 15px;
}
.MyProfileContbutton button{
    padding: 4px 10px;
    background-color: #EBF2FF;
    border-radius: 5px;
}
.MyProfileContbutton i{
    color: #2264D1;
    font-size: 16px;
    margin-right: 7px;
}
.MyProfileContbutton span{
    color: #2264D1;
    font-size: 14px;
    font-weight: 500px;
    line-height: 20px;
}
.MyProfileContul li i{
    width: 30px;
    height: 30px;
    font-size: 17px;
    background-color: #F1F1F9;
    color: #B4B4BB;
    border-radius: 50%;
    margin-right: 7px;
}
.MyProfileContul li{
    margin-bottom: 12px;
}
.MyProfileh6div{
    margin-bottom: 14px;
}
.MyProfileContul li a{
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #19191D;
    cursor: pointer;
}
.MyProfileInformation li{
    font-size: 14px;
    font-weight: 500;
    line-height: 33px;
    color: #0C2146;
}
.cardpart li{
    line-height: 40px;
}
.MyProfilecard i{
    font-size: 28px;
    color: #009CDE;
}
.PaymentAmount{
    color: #2979FF;
}
.MyProfileInformationimg img{
    width: 69px;
    height: 18px;
    object-fit: fill;
    margin-left: 10px;
}

@media (max-width: 1250px) and (min-width: 1100px){
    .MyProfilePublicBlockOverAll{
        width: 980px;
    }
    .MyProfileImg{
        margin-right: 25px!important;
    }
    .MyProfileImg img{
        width: 150px;
        height: 150px;
    }
    .MyProfileImgEdit{
        width: 50px;
        height: 50px;
        bottom: -3px;
        right: 15px;
    }
    .MyProfileImgEdit i{
        font-size: 17px;
    }
    .MyProfileA a{
        font-size: 11px!important;
    }
    .MyProfileSpan span{
        font-size: 13px!important;
    }
    .MyProfileAccordionbutton span{
        font-size: 13px!important;
    }
    .MyProfileAccordionUL li a{
        font-size: 13px!important;
    }
}
@media (max-width: 1100px) and (min-width: 992px){
    .MyProfilePublicBlockOverAll{
        width: 900px;
    }
    .MyProfileSpan span{
        font-size: 14px!important;
    }
    .MyProfileImg{
        margin-right: 25px!important;
    }
    .MyProfileImg img{
        width: 130px;
        height: 130px;
    }
    .MyProfileImgEdit{
        width: 40px;
        height: 40px;
        bottom: 16px;
        right: 18px;
    }
    .MyProfileImgEdit i{
        font-size: 15px;
    }
    .MyProfileLeft{
        margin-right: 22px!important;
    } 
    .MyProfileA a{
        font-size: 10px!important;
    }
    .MyProfileSpan span{
        font-size: 12px!important;
    }
    .MyProfileAccordionbutton span{
        font-size: 12px!important;
    }
    .MyProfileAccordionUL li a{
        font-size: 12px!important;
        line-height: 32px!important;
    }
    .MyProfileSpan{
        line-height: 32px!important;
    }
    .MyProfileSpan span{
        margin-left: 12px!important;
    }
    .MyProfileA{
        margin-right: 12px!important;
    }  
}
@media (max-width: 992px) and (min-width: 850px){
    .MyProfilePublicBlockOverAll{
        width: 770px;
    }
    .MyProfileLeft{
        margin-right: 0px!important;
    }
    .MyProfileAccordionoverall{
        margin-bottom: 30px!important;
    }
    .MyProfileImgEdit{
        width: 50px;
        height: 50px;
        bottom: 3px;
        right: 51px;
    }
    .MyProfileConth5 h5{
        font-size: 20px;
    }
}
@media (max-width: 850px) and (min-width: 750px){
    .MyProfilePublicBlockOverAll{
        width: 670px;
    }
    .MyProfileLeft{
        margin-right: 0px!important;
    }
    .MyProfileAccordionoverall{
        margin-bottom: 30px!important;
    }
    .MyProfileImgPart{
        padding: 20px 0px 0px 0px;
    }
    .MyProfileImg img{
        width: 160px;
        height: 160px;
    }
    .MyProfileImgEdit{
        width: 50px;
        height: 50px;
        bottom: 3px;
        right: 30px;
    }
    .MyProfileImg{
        margin-right: 20px!important;
    }
    .MyProfileConth5 h5{
        font-size: 20px;
    }
}
@media (max-width: 750px) and (min-width: 600px){
    .MyProfilePublicBlockOverAll{
        width: 550px;
    }
    .MyProfileLeft{
        margin-right: 0px!important;
    }
    .MyProfileAccordionoverall{
        margin-bottom: 30px!important;
    }
    .MyProfileImg img{
        width: 140px;
        height: 140px;
    }
    .MyProfileImgEdit{
        width: 45px;
        height: 45px;
        bottom: 3px;
        right: 20px;
    }
    .MyProfileImgEdit i{
        font-size: 17px!important;
    }
    .MyProfileImg{
        margin-right: 20px!important;
    }
    .MyProfileConth5 h5{
        font-size: 17px;
    }
    .MyProfileh6 h6{
        font-size: 17px;
        line-height: 0px;
    }
    .MyProfilePublicBlockh6 h6{
        font-size: 17px;
        line-height: 0px;
    }
    .MyProfileImgPart{
        padding: 10px 0px 0px 0px;
    }
    .MyProfilePictureSection{
        padding: 25px 25px 15px 25px;
    }
    .MyProfileInformation li{
        font-size: 13px;
    }
}
@media (max-width: 600px) and (min-width: 500px){
    .MyProfilePublicBlockOverAll{
        width: 450px;
    }
    .MyProfileLeft{
        margin-right: 0px!important;
    }
    .MyProfileAccordionoverall{
        margin-bottom: 30px!important;
    }
    .MyProfileImgEdit{
        width: 40px;
        height: 40px;
        bottom: 20px;
        right: 20px;
    }
    .MyProfileImgEdit i{
        font-size: 14px!important;
    }
    .MyProfileImg img{
        width: 110px;
        height: 110px;
    }
    .MyProfileImg{
        margin-right: 20px!important;
    }
    .MyProfileConth5 h5{
        font-size: 15px;
    }
    .MyProfileh6 h6{
        font-size: 15px;
        line-height: 0px;
    }
    .MyProfilePublicBlockh6 h6{
        font-size: 15px;
        line-height: 0px;
    }
    .MyProfileImgPart{
        padding: 20px 0px 0px 0px;
    }
    .MyProfilePictureSection{
        padding: 20px 15px 10px 20px;
    }
    .MyProfileInformation li{
        font-size: 13px;
    }
    .MyProfileAccordionbutton span{
        font-size: 14px!important;
    }
    .MyProfileSpan span{
        font-size: 14px!important;
    }
    .MyProfileNameSection{
        margin-bottom: 5px!important;
    }
    .MyProfileContbutton i{
        font-size: 14px;
    }
    .MyProfileContbutton span{
        font-size: 13px;
    }
    .MyProfileContbutton button{
        padding: 2px 8px;
    }
    .MyProfileAccordionUL li a{
        font-size: 14px!important;
    }
}
@media (max-width: 500px) and (min-width: 400px){
    .MyProfilePublicBlockOverAll{
        width: 370px;
    }
    .MyProfileSpan{
        line-height:35px!important;
    }
    .MyProfileLeft{
        margin-right: 0px!important;
    }
    .MyProfileAccordionoverall{
        margin-bottom: 20px!important;
    }
    .MyProfileImgEdit{
        width: 35px;
        height: 35px;
        bottom: 20px;
        right: 10px;
    }
    .MyProfileImgEdit i{
        font-size: 13px!important;
    }
    .MyProfileImg img{
        width: 100px;
        height: 100px;
    }
    .MyProfileImg{
        margin-right: 15px!important;
    }
    .MyProfileConth5 h5{
        font-size: 15px;
    }
    .MyProfileh6 h6{
        font-size: 15px;
        line-height: 0px;
    }
    .MyProfilePublicBlockh6 h6{
        font-size: 14px;
        line-height: 0px;
    }
    .MyProfilePublicBlockSpan span{
        font-size: 13px;
    }
    .MyProfileImgPart{
        padding: 10px 0px 0px 0px;
    }
    .MyProfileInformation li{
        font-size: 13px;
    }
    .MyProfileAccordionbutton span{
        font-size: 14px!important;
    }
    .MyProfileSpan span{
        font-size: 14px!important;
    }
    .MyProfilePictureSection{
        padding: 20px 15px 5px 12px;
    }
    .MyProfileNameSection{
        margin-bottom: 5px!important;
    }
    .MyProfileContbutton i{
        font-size: 14px;
    }
    .MyProfileContbutton span{
        font-size: 13px;
    }
    .MyProfileContbutton button{
        padding: 2px 8px;
    }
    .MyProfileAccordionUL li a{
        font-size: 14px!important;
        line-height: 35px!important;
    }
    .MyProfileContul li a{
        font-size: 13px;
    }
    .MyProfileBlockSpan span{
        font-size: 13px;
    }
    .MyProfileBlockIcon{
        font-size: 14px;
        margin-right: 7px;
    }
    .MyProfileBlockIcon i{
        padding: 6px;
    }
}
@media (max-width: 400px) and (min-width: 350px){
    .MyProfilePublicBlockOverAll{
        width: 320px;
    }
    .MyProfileSpan{
        line-height: 26px!important;
    }
    .MyProfileLeft{
        margin-right: 0px!important;
    }
    .MyProfileAccordionoverall{
        margin-bottom: 20px!important;
    }
    .MyProfileImgEdit{
        width: 25px;
        height: 25px;
        bottom: 37px;
        right: 17px;
    }
    .MyProfileImgEdit i{
        font-size: 10px!important;
    }
    .MyProfileImg img{
        width: 75px;
        height: 75px;
    }
    .MyProfileImg{
        margin-right: 15px!important;
    }
    .MyProfileConth5 h5{
        font-size: 15px;
    }
    .MyProfileh6 h6{
        font-size: 15px;
        line-height: 0px;
    }
    .MyProfilePublicBlockh6 h6{
        font-size: 13px;
        line-height: 0px;
    }
    .MyProfilePublicBlockSpan span{
        font-size: 12px;
    }
    .MyProfilePublicBlockIcon{
        font-size: 14px;
        margin-right: 5px;
    }
    .MyProfilePublicBlockIcon i{
        padding: 4px;
    }
    .MyProfileImgPart{
        padding: 10px 0px 0px 0px;
    }
    .MyProfileInformation li{
        font-size: 11px;
    }
    .MyProfileAccordionbutton span{
        font-size: 13px!important;
    }
    .MyProfileSpan span{
        font-size: 13px!important;
    }
    .MyProfilePictureSection{
        padding: 20px 15px 5px 12px;
    }
    .MyProfileNameSection{
        margin-bottom: 5px!important;
    }
    .MyProfileContbutton i{
        font-size: 13px;
    }
    .MyProfileContbutton span{
        font-size: 12px;
    }
    .MyProfileContbutton button{
        padding: 1px 7px;
    }
    .MyProfileAccordionUL li a{
        font-size: 13px!important;
        line-height: 30px!important;
    }
    .MyProfileContul li a{
        font-size: 12px;
    }
    .MyProfileContul li{
        margin-bottom: 7px;
    }
    .MyProfileA a{
        font-size: 12px!important;
    }
    .MyProfileBlockSpan span{
        font-size: 13px;
    }
    .MyProfileBlockIcon{
        font-size: 14px;
        margin-right: 7px;
    }
    .MyProfileBlockIcon i{
        padding: 6px;
    }
}
@media (max-width: 350px) and (min-width: 275px){
    .MyProfilePublicBlockOverAll{
        width: 270px;
    }
    .MyProfileContul li i{
        width: 25px;
        height: 25px;
        font-size: 14px;
    }
    .MyProfileSpan{
        line-height: 26px!important;
    }
    .MyProfileLeft{
        margin-right: 0px!important;
    }
    .MyProfileAccordionoverall{
        margin-bottom: 20px!important;
    }
    .MyProfileImgEdit{
        width: 25px;
        height: 25px;
        bottom: 30px;
        right: 14px;
    }
    .MyProfileImgEdit i{
        font-size: 9px!important;
    }
    .MyProfileImg img{
        width: 60px;
        height: 60px;
    }
    .MyProfileImg{
        margin-right: 10px!important;
    }
    .MyProfileConth5 h5{
        font-size: 13px;
    }
    .MyProfileh6 h6{
        font-size: 13px!important;
        line-height: 0px;
    }
    .MyProfilePublicBlockh6 h6{
        font-size: 11px;
        line-height: 0px;
    }
    .MyProfilePublicBlockSpan span{
        font-size: 10px;
    }
    .MyProfilePublicBlockIcon{
        font-size: 12px;
        margin-right: 2px;
    }
    .MyProfilePublicBlockIcon i{
        padding: 4px;
    }
    .MyProfileImgPart{
        padding: 10px 0px 0px 0px;
    }
    .MyProfileInformation li{
        font-size: 9px;
        line-height: 28px;
    }
    .MyProfileAccordionbutton span{
        font-size: 11px!important;
    }
    .MyProfileSpan span{
        font-size: 11px!important;
    }
    .MyProfilePictureSection{
        padding: 15px 12px 5px 12px;
    }
    .MyProfileNameSection{
        margin-bottom: 5px!important;
    }
    .MyProfileContbutton i{
        font-size: 10px;
    }
    .MyProfileContbutton span{
        font-size: 10px;
    }
    .MyProfileContbutton button{
        padding: 0px 7px;
    }
    .MyProfileAccordionUL li a{
        font-size: 12px!important;
        line-height: 27px!important;
    }
    .MyProfileContul li a{
        font-size: 10px;
    }
    .MyProfileContul li{
        margin-bottom: 5px!important;
    }
    .MyProfileA a{
        font-size: 10px!important;
    }
    .MyProfilecard i{
        font-size: 22px;
    }
    .MyProfileBlockSpan span{
        font-size: 11px;
    }
    .MyProfileBlockIcon{
        font-size: 12px;
        margin-right: 5px;
    }
    .MyProfileBlockIcon i{
        padding: 6px;
    }
}
@media (max-width: 275px){
    .MyProfilePublicBlockOverAll{
        width: 270px;
    }
    .MyProfileContul li i{
        width: 25px;
        height: 25px;
        font-size: 14px;
    }
    .MyProfileSpan{
        line-height: 26px!important;
    }
    .MyProfileLeft{
        margin-right: 0px!important;
    }
    .MyProfileAccordionoverall{
        margin-bottom: 20px!important;
    }
    .MyProfileImgEdit{
        width: 25px;
        height: 25px;
        bottom: 30px;
        right: 14px;
    }
    .MyProfileImgEdit i{
        font-size: 9px!important;
    }
    .MyProfileImg img{
        width: 60px;
        height: 60px;
    }
    .MyProfileImg{
        margin-right: 10px!important;
    }
    .MyProfileConth5 h5{
        font-size: 13px;
    }
    .MyProfileh6 h6{
        font-size: 13px;
        line-height: 0px;
    }
    .MyProfilePublicBlockh6 h6{
        font-size: 11px;
        line-height: 0px;
    }
    .MyProfilePublicBlockSpan span{
        font-size: 10px;
    }
    .MyProfilePublicBlockIcon{
        font-size: 12px;
        margin-right: 2px;
    }
    .MyProfilePublicBlockIcon i{
        padding: 4px;
    }
    .MyProfileImgPart{
        padding: 10px 0px 0px 0px;
    }
    .MyProfileInformation li{
        font-size: 9px;
        line-height: 28px;
    }
    .MyProfileAccordionbutton span{
        font-size: 11px!important;
    }
    .MyProfileSpan span{
        font-size: 11px!important;
    }
    .MyProfilePictureSection{
        padding: 15px 12px 5px 12px;
    }
    .MyProfileNameSection{
        margin-bottom: 5px!important;
    }
    .MyProfileContbutton i{
        font-size: 10px;
    }
    .MyProfileContbutton span{
        font-size: 10px;
    }
    .MyProfileContbutton button{
        padding: 0px 7px;
    }
    .MyProfileAccordionUL li a{
        font-size: 12px!important;
        line-height: 27px!important;
    }
    .MyProfileContul li a{
        font-size: 10px;
    }
    .MyProfileContul li{
        margin-bottom: 5px!important;
    }
    .MyProfileA a{
        font-size: 10px!important;
    }
    .MyProfilecard i{
        font-size: 22px;
    }
    .MyProfileBlockSpan span{
        font-size: 11px;
    }
    .MyProfileBlockIcon{
        font-size: 12px;
        margin-right: 5px;
    }
    .MyProfileBlockIcon i{
        padding: 6px;
    }
}
