.GateKeeperOverall{
    width: 1156px;
    margin: 0 auto;
    padding-top:100px
}
.GateKeeperImage img{
    width: 212px;
    height: 212px;
    object-fit: cover;
    border-radius: 50%;
}
.GateKeeperImage{
    margin-right: 24px;
}
.GateKeeperImgSection{
    margin-bottom: 8px;
}
.GateKeeperImgSectionName h5{
    font-size: 24px;
    line-height: 36px;
    font-weight: 700;
    color: #19191D;
}
.GateKeeperImgSectionName{
    margin-bottom: 24px;
}
.GateKeeperBlockSpan span{
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #463209;
}
.GateKeeperBlockIcon{
    font-size: 16px;
    color: #B88217;
    margin-right: 7px;
}
.GateKeeperBlockIcon i{
    padding: 6px;
    border-radius: 50%;
    background-color: #FFEFD1;
}
.GateKeeperImgSectionIconsBG i{
    background-color: #EBF2FF!important;
    color: #2264D1!important;
}
.GateKeeperImgSectionIcons li i{
    width: 30px;
    height: 30px;
    font-size: 17px;
    background-color: #F1F1F9;
    color: #4A4B57;
    border-radius: 50%;
    margin-right: 7px;
}
.GateKeeperImgSectionIcons li{
    margin-bottom: 12px;
}
.GateKeeperImgSectionIcons li a{
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #19191D;
    cursor: pointer;
}
.GateKeeperNumsBlue{
    border-bottom: 4px solid #2264D1;
}
.GateKeeperUniversesBlue span{
    color: #0C2146!important;
}
.GateKeeperNums{
    padding: 0px 12px;
}
.GateKeeperA{
    margin-right: 8px!important;
}
.GateKeeperA a{
    padding: 1px 7px!important;
    background-color: #EDEDF0!important;
    font-size: 14px!important;
    font-weight: 500!important;
    line-height: 20px!important;
    color: #19191D!important;
    border-radius: 10px!important;
}
.GateKeeperAbg a{
    background-color: #4A4B57!important;
    color: #F7F7FA!important;
}
.GateKeeperUniverses span{
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #4A4B57;
}
.universeButton{
    padding: 0px 15px 5px 15px!important;
    color: #4A4B57!important;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}
.universeButton.active{
    color: #0C2146!important;
    outline: none;
    background: none!important;
    border-bottom: 4px solid #2264D1!important;
    border-radius: 3px!important;
}
.GateKeeperBoxSection{
    padding: 24px;
    background-color: #F7F7FA;
    border-radius: 10px;
}
.GateKeeperBoxSectionName h6{
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    color: #19191D;
}
.GateKeeperBoxSectionSubName span{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #787885;
}
.GateKeeperBoxSectionSubName{
    margin-bottom: 24px;
}
.GateKeeperBoxBorder{
    padding-bottom: 20px;
    border-bottom: 2px solid #E1E1E3;
}
.HomeBrowseContributionsDots i{
    width: 100%;
    display: flex;
    justify-content: center;
    height: 20px;    
    background-color: #E1E1E3;
    font-size: 24px;
    border-radius: 4px;
    color: #5A5B6A;
    margin-bottom: 10px;
}
.HomeBrowseContributionsSmallDots i{
    width: 32px;
    display: flex;
    justify-content: center;
    height: 22px;    
    background-color: #E1E1E3;
    font-size: 24px;
    border-radius: 4px;
    color: #5A5B6A;
}
.GateKeeperBox{
    width: 100%!important;
    background-color: #ECF7ED;
    border: solid 2px #A9D3AB;
    border-radius: 10px;
    padding: 12px 16px;
    box-shadow: -1px 2px #A9D3AB;
}
.GateKeeperBoxPara p {
    color: #2B662E;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 1px;
}
.GateKeeperBoxh6 h6 {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    color: #132C14;
}
.GateKeeperBoxSpan{
    width: auto;
    background-color: #fff;
    border-radius: 25px;
    float: left;
}
.GateKeeperBoxSpan span {
    padding: 4px 10px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    display: flex;
    align-items: center;
}
.GateKeeperBoxSpan i {
    font-size: 20px;
    margin-right: 13px;
}
.GateKeeperthumbsup span {
    color: #4F91FF;
}
.GateKeeperthumbsup i {
    color: #9DC2FF;
}
.GateKeeperuser span {
    color: #EF6292;
}
.GateKeeperuser i {
    color: #EF6292;
}
.GateKeeperrandom span {
    color: #FB982E;
}
.GateKeeperrandom i {
    color: #FDC68B;
}
.GateKeeperBoxTwo{
    padding-top: 22px;
}
.GateKeeperContributionBoxSection{
    padding: 24px 0px;
    background-color: #F7F7FA;
    border-radius: 10px;
}
.GateKeeperContributionBox{
    width: 95%!important;
    background-color: #ECF7ED;
    border: solid 2px #A9D3AB;
    border-radius: 10px;
    padding: 12px 16px;
    box-shadow: -1px 2px #A9D3AB;
    margin-bottom: 15px;
}
.ContributionUniHead span{
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #3A3A44;
}
.ContributionPara p {
    color: #2B662E;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 1px;
}
.ContributionNumber{
    margin-bottom: 10px;
}
.ContributionNumber span{
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #9696A0;
}
.ContributionImagePart{
    margin-bottom: 14px;
}
.ContributionImage{
    width: auto;
    background-color: #fff;
    border-radius: 25px;
    float: left;    
}
.ContributionImage img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    object-fit: cover;
}
.ContributionImage span {
    padding: 4px 10px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #65B168;
    display: flex;
    align-items: center;
}
.ContributionGuarding{
    width: auto;
    background-color: #EBF2FF;
    border-radius: 25px;
    float: left;
}
.ContributionGuarding span {
    padding: 4px 10px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #2264D1;
    display: flex;
    align-items: center;
}
.ContributionGuarding i {
    font-size: 25px;
    color: #9DC2FF;
    margin-right: 6px;
}
.GateKeeperContributionBoxBorder{
    padding-bottom: 20px;
    border-bottom: 2px solid #E1E1E3;
}
.ContributionMaster{
    width: auto;
    background-color: #FEE8E7;
    border-radius: 25px;
    float: left;
}
.ContributionMaster span {
    padding: 4px 10px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #C8372D;
    display: flex;
    align-items: center;
}
.ContributionMaster i {
    font-size: 20px;
    color: #FAA9A3;
    margin-right: 6px;
}
.GateKeeperIconsSection{
    margin-bottom: 14px;
}
.GateKeeperTwoIcon i{
    padding: 4px 10px;
    background-color: #fff;
    border-radius: 20px;
    font-size: 20px;
    color: #787885;
}
.GateKeeperPagination li a{
    width: 43px;
    height: 40px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #EDEDF0;
    color: #787885!important;
    margin-right: 16px;
    border: none!important;
    border-radius: 4px!important;
}
.GateKeeperPagination li a:focus{
    box-shadow: none;
    background-color: #787885;
    color: #FFFFFF!important;
}
.GateKeeperPagination i{
    color: #ECF7ED;
    font-size: 18px;
    width: 43px;
    height: 40px;
    border-radius: 4px!important;
    background-color: #65B168;
}
.ViewAllBtn {
    background:#2264d1;
    border:none;
    outline: none;
    padding: 10px;
    border: 2px solid white;
    color:white;
    border-radius: 5px;
}
.ViewAllBtn:hover{
    background: white;
    color: black;
    border: 2px solid black;
}
.right-align{
    float: right;
}


@media (max-width:1300px) and (min-width:1100px) {
    .GateKeeperOverall{
        width: 1040px;
    }
    .GateKeeperImage img{
        width: 175px;
        height: 175px;
    }
}
@media (max-width:1100px) and (min-width:992px) {
    .GateKeeperOverall{
        width: 940px;
    }
    .GateKeeperImage img{
        width: 175px;
        height: 175px;
    }
    .GateKeeperBox{
        padding: 12px 10px;
    }
    .ContributionImage span {
        font-size: 13px;
    }
    .ContributionGuarding span{
        font-size: 13px;
    }
    .ContributionMaster span{
        font-size: 13px;
    }
}
@media (max-width:992px) and (min-width:850px) {
    .GateKeeperOverall{
        width: 810px;
    }
    .GateKeeperImage img{
        width: 175px;
        height: 175px;
    }
    .GateKeeperImage{
        margin-right: 20px;
    }
    .GateKeeperImgSectionName{
        margin-bottom: 17px;
    }
}
@media (max-width:850px) and (min-width:767px) {
    .GateKeeperOverall{
        width: 720px;
    }
    .GateKeeperImage img{
        width: 155px;
        height: 155px;
    }
    .GateKeeperImage{
        margin-right: 15px;
    }
    .GateKeeperImgSectionName h5{
        font-size: 22px;
    }
    .GateKeeperImgSectionName{
        margin-bottom: 12px;
    }
    .universeButton{
        padding: 0px 12px 5px 12px!important;
        font-size: 15px;   
    }
    .GateKeeperBoxSection{
        padding: 18px;
    }
    .GateKeeperBoxSectionSubName {
        margin-bottom: 12px;
    }
}
@media (max-width:767px) and (min-width:650px) {
    .GateKeeperOverall{
        width: 620px;
    }
    .GateKeeperImage img{
        width: 155px;
        height: 155px;
    }
    .GateKeeperImage{
        margin-right: 15px;
    }
    .GateKeeperImgSectionName h5{
        font-size: 20px;
    }
    .GateKeeperBoxh6 h6{
        font-size: 18px;
    }
    .GateKeeperImgSectionName{
        margin-bottom: 10px;
    }
    .GateKeeperBlockSpan span{
        font-size: 16px;
    }
    .GateKeeperBlockIcon{
        font-size: 15px;
        margin-right: 7px;
    }
    .GateKeeperBlockIcon i{
        padding: 6px;
    }
    .GateKeeperBoxSpan span {
        padding: 4px 10px;
        font-size: 12px;
    }
    .GateKeeperBoxSpan i {
        font-size: 18px;
        margin-right: 8px;
    }
    .GateKeeperImgSectionIcons li a{
        font-size: 16px;
    }
    .universeButton{
        padding: 0px 6px 5px 6px!important;
        font-size: 15px;   
    }
    .GateKeeperBoxSection{
        padding: 18px;
    }
    .GateKeeperBoxSectionSubName {
        margin-bottom: 12px;
    }
    .GateKeeperPagination li a{
        width: 35px;
        height: 35px;
        font-size: 15px;
        margin-right: 10px;
    }
    .GateKeeperPagination i{
        font-size: 16px;
        width: 35px;
        height: 35px;
    }
    .ContributionImage span {
        font-size: 14px;
    }
    .ContributionGuarding span{
        font-size: 14px;
    }
    .ContributionMaster span{
        font-size: 14px;
    }
    .GateKeeperContributionBoxBorder {
        padding-bottom: 10px;
    }
}
@media (max-width:650px) and (min-width:550px) {
    .GateKeeperOverall{
        width: 520px;
    }
    .GateKeeperContributionBoxBorder {
        padding-bottom: 10px;
    }
    .GateKeeperImage img{
        width: 140px;
        height: 140px;
    }
    .GateKeeperImage{
        margin-right: 15px;
    }
    .GateKeeperImgSectionName h5{
        font-size: 20px;
    }
    .GateKeeperBoxh6 h6{
        font-size: 18px;
    }
    .GateKeeperImgSectionName{
        margin-bottom: 10px;
    }
    .GateKeeperBlockSpan span{
        font-size: 15px;
    }
    .GateKeeperBlockIcon{
        font-size: 15px;
        margin-right: 7px;
    }
    .GateKeeperBlockIcon i{
        padding: 6px;
    }
    .GateKeeperBoxSpan span {
        padding: 4px 10px;
        font-size: 12px;
    }
    .GateKeeperBoxSpan i {
        font-size: 18px;
        margin-right: 8px;
    }
    .GateKeeperImgSectionIcons li a{
        font-size: 15px;
    }
    .universeButton{
        padding: 0px 15px 5px 15px!important;
        font-size: 14px;   
    }
    .GateKeeperA a{
        padding: 1px 7px!important;
        font-size: 13px!important;
    }
    .GateKeeperBoxSection{
        padding: 12px 18px;
    }
    .GateKeeperBoxSectionSubName {
        margin-bottom: 12px;
    }
    .GateKeeperBoxTwo {
        padding-top: 10px;
    }
    .ContributionImage span {
        font-size: 14px;
    }
    .ContributionGuarding span{
        font-size: 14px;
    }
    .ContributionMaster span{
        font-size: 14px;
    }
    .GateKeeperPagination li a{
        width: 30px;
        height: 30px;
        font-size: 14px;
        margin-right: 8px;
    }
    .GateKeeperPagination i{
        font-size: 15px;
        width: 30px;
        height: 30px;
    }
}
@media (max-width:550px) and (min-width:450px) {
    .GateKeeperOverall{
        width: 420px;
    }
    .GateKeeperContributionBoxBorder {
        padding-bottom: 10px;
    }
    .GateKeeperImage img{
        width: 130px;
        height: 130px;
    }
    .GateKeeperImage{
        margin-right: 15px;
    }
    .GateKeeperImgSectionName h5{
        font-size: 20px;
    }
    .GateKeeperBoxSection{
        padding: 12px 18px;
    }
    .GateKeeperBoxSectionSubName {
        margin-bottom: 8px;
    }
    .GateKeeperBoxTwo {
        padding-top: 10px;
    }
    .GateKeeperBoxh6 h6{
        font-size: 18px;
    }
    .GateKeeperImgSectionName{
        margin-bottom: 10px;
    }
    .GateKeeperBlockSpan span{
        font-size: 13px;
    }
    .GateKeeperImgSectionIcons li a{
        font-size: 13px;
    }
    .GateKeeperBlockIcon{
        font-size: 15px;
        margin-right: 7px;
    }
    .GateKeeperBlockIcon i{
        padding: 6px;
    }
    .GateKeeperBoxSpan span {
        padding: 4px 10px;
        font-size: 12px;
    }
    .GateKeeperBoxSpan i {
        font-size: 18px;
        margin-right: 8px;
    }
    .universeButton{
        padding: 0px 10px 5px 10px!important;
        font-size: 14px;   
    }
    .GateKeeperA a{
        padding: 1px 7px!important;
        font-size: 11px!important;
    }
    .GateKeeperBoxSectionName h6{
        font-size: 18px;
    }
    .GateKeeperBoxSectionSubName span{
        font-size: 15px;
    } 
    .ContributionImage span {
        font-size: 13px;
    }
    .ContributionGuarding span{
        font-size: 13px;
    }
    .ContributionMaster span{
        font-size: 13px;
    }
    .ContributionNumber span{
        font-size: 15px;
    }
    .GateKeeperPagination li a{
        width: 30px;
        height: 30px;
        font-size: 14px;
        margin-right: 8px;
    }
    .GateKeeperPagination i{
        font-size: 15px;
        width: 30px;
        height: 30px;
    }
}
@media (max-width:450px) and (min-width:400px) {
    .GateKeeperOverall{
        width: 380px;
    }
    .GateKeeperContributionBoxBorder {
        padding-bottom: 10px;
    }
    .GateKeeperImage img{
        width: 100px;
        height: 100px;
    }
    .GateKeeperImage{
        margin-right: 15px;
    }
    .GateKeeperImgSectionName h5{
        font-size: 20px;
    }
    .GateKeeperBoxh6 h6{
        font-size: 18px;
    }
    .GateKeeperImgSectionName{
        margin-bottom: 7px;
    }
    .GateKeeperBlockSpan span{
        font-size: 13px;
    }
    .GateKeeperImgSectionIcons li a{
        font-size: 13px;
    }
    .GateKeeperBlockIcon{
        font-size: 15px;
        margin-right: 7px;
    }
    .GateKeeperBlockIcon i{
        padding: 6px;
    }
    .GateKeeperBoxSpan span {
        padding: 4px 10px;
        font-size: 12px;
    }
    .GateKeeperBoxSpan i {
        font-size: 18px;
        margin-right: 8px;
    }
    .universeButton{
        padding: 0px 8px 0px 8px!important;
        font-size: 12px;   
    }
    .GateKeeperA a{
        padding: 1px 4px!important;
        font-size: 10px!important;
    }
    .GateKeeperA{
        margin-right: 3px!important;
    }
    .GateKeeperBoxSectionName h6{
        font-size: 18px;
    }
    .GateKeeperBoxSectionSubName span{
        font-size: 15px;
    }
    .GateKeeperImgSectionIcons li{
        margin-bottom: 8px;
    }
    .GateKeeperBoxSection{
        padding: 12px 18px;
    }
    .GateKeeperBoxSectionSubName {
        margin-bottom: 8px;
    }
    .GateKeeperBoxTwo {
        padding-top: 10px;
    }
    .ContributionImage span {
        font-size: 13px;
    }
    .ContributionGuarding span{
        font-size: 13px;
    }
    .ContributionMaster span{
        font-size: 13px;
    }
    .ContributionNumber span{
        font-size: 15px;
    }
    .GateKeeperPagination li a{
        width: 25px;
        height: 25px;
        font-size: 13px;
        margin-right: 6px;
    }
    .GateKeeperPagination i{
        font-size: 14px;
        width: 25px;
        height: 25px;
    }
    .GateKeeperBoxPara p{
        font-size: 15px;
    }
    .ContributionPara  p{
        font-size: 15px;
    }
}
@media (max-width:400px) and (min-width:350px) {
    .GateKeeperOverall{
        width: 330px;
    }
    .GateKeeperContributionBox {
        margin-bottom: 10px;
    }
    .GateKeeperContributionBoxSection {
        padding: 15px 0px;
    }
    .GateKeeperContributionBoxBorder {
        padding-bottom: 10px;
    }
    .GateKeeperImage img{
        width: 90px;
        height: 90px;
    }
    .GateKeeperImage{
        margin-right: 15px;
    }
    .GateKeeperImgSectionName h5{
        font-size: 20px;
    }
    .GateKeeperBoxh6 h6{
        font-size: 16px;
    }
    .GateKeeperImgSectionName{
        margin-bottom: 4px;
    }
    .GateKeeperBlockSpan span{
        font-size: 13px;
    }
    .GateKeeperImgSectionIcons li a{
        font-size: 13px;
    }
    .GateKeeperBlockIcon{
        font-size: 15px;
        margin-right: 7px;
    }
    .GateKeeperBlockIcon i{
        padding: 6px;
    }
    .GateKeeperBoxSpan span {
        padding: 4px 10px;
        font-size: 12px;
    }
    .GateKeeperBoxSpan i {
        font-size: 18px;
        margin-right: 8px;
    }
    .universeButton{
        padding: 0px 8px 0px 8px!important;
        font-size: 12px;   
    }
    .GateKeeperA a{
        padding: 1px 4px!important;
        font-size: 10px!important;
    }
    .GateKeeperA{
        margin-right: 3px!important;
    }
    .GateKeeperBoxSectionName h6{
        font-size: 18px;
    }
    .GateKeeperBoxSectionSubName span{
        font-size: 15px;
    }
    .GateKeeperImgSectionIcons li{
        margin-bottom: 8px;
    }
    .GateKeeperBoxSection{
        padding: 12px 18px;
    }
    .GateKeeperBoxSectionSubName {
        margin-bottom: 8px;
    }
    .GateKeeperBoxTwo {
        padding-top: 10px;
    }
    .ContributionImage span {
        font-size: 13px;
    }
    .ContributionGuarding span{
        font-size: 13px;
    }
    .ContributionMaster span{
        font-size: 13px;
    }
    .ContributionNumber span{
        font-size: 15px;
    }
    .GateKeeperPagination li a{
        width: 25px;
        height: 25px;
        font-size: 13px;
        margin-right: 4px;
    }
    .GateKeeperPagination i{
        font-size: 14px;
        width: 25px;
        height: 25px;
    }
    .GateKeeperBoxPara p{
        font-size: 15px;
    }
    .ContributionPara  p{
        font-size: 15px;
    }
}
@media (max-width:350px) and (min-width:315px) {
    .GateKeeperOverall{
        width: 300px;
    }
    .GateKeeperContributionBox {
        margin-bottom: 10px;
    }
    .GateKeeperContributionBoxSection {
        padding: 15px 0px;
    }
    .GateKeeperContributionBoxBorder {
        padding-bottom: 10px;
    }
    .GateKeeperImage img{
        width: 80px;
        height: 80px;
    }
    .GateKeeperImage{
        margin-right: 15px;
    }
    .GateKeeperImgSectionName h5{
        font-size: 20px;
    }
    .GateKeeperBoxh6 h6{
        font-size: 15px;
        line-height: 25px;
    }
    .GateKeeperImgSectionName{
        margin-bottom: 4px;
    }
    .GateKeeperBlockSpan span{
        font-size: 13px;
    }
    .GateKeeperImgSectionIcons li a{
        font-size: 13px;
    }
    .GateKeeperBlockIcon{
        font-size: 15px;
        margin-right: 7px;
    }
    .GateKeeperBlockIcon i{
        padding: 6px;
    }
    .GateKeeperBoxSpan span {
        padding: 3px 7px;
        font-size: 11px;
    }
    .GateKeeperBoxSpan i {
        font-size: 15px;
        margin-right: 8px;
    }
    .universeButton{
        padding: 0px 8px 0px 8px!important;
        font-size: 12px;   
    }
    .GateKeeperA a{
        padding: 1px 4px!important;
        font-size: 10px!important;
    }
    .GateKeeperA{
        margin-right: 3px!important;
    }
    .GateKeeperBoxSectionName h6{
        font-size: 17px;
    }
    .GateKeeperBoxSectionSubName span{
        font-size: 14px;
    }
    .GateKeeperImgSectionIcons li{
        margin-bottom: 8px;
    }
    .GateKeeperBoxSection{
        padding: 12px 18px;
    }
    .GateKeeperBoxSectionSubName {
        margin-bottom: 8px;
    }
    .GateKeeperBoxTwo {
        padding-top: 10px;
    }
    .GateKeeperBoxPara p{
        font-size: 14px;
    }
    .ContributionImage span {
        font-size: 11px;
        padding: 3px 7px;
    }
    .ContributionGuarding span{
        font-size: 11px;
        padding: 3px 7px;
    }
    .ContributionMaster span{
        font-size: 11px;
        padding: 3px 7px;
    }
    .ContributionMaster i{
        font-size: 17px;
    }
    .ContributionNumber span{
        font-size: 13px;
    }
    .GateKeeperPagination li a{
        width: 20px;
        height: 20px;
        font-size: 12px;
        margin-right: 4px;
    }
    .GateKeeperPagination i{
        font-size: 12px;
        width: 20px;
        height: 20px;
    }
    .GateKeeperBoxPara p{
        font-size: 13px;
    }
    .ContributionPara p{
        font-size: 13px;
    }
    .ContributionNumber {
        margin-bottom: 4px;
    }
    .ContributionImagePart {
        margin-bottom: 8px;
    }
    .GateKeeperIconsSection {
        margin-bottom: 8px;
    }
    .ContributionImage img {
        width: 20px;
        height: 20px;
    }
    .ContributionGuarding i{
        font-size: 20px;
    }
    .GateKeeperTwoIcon i{
        font-size: 17px;
    }
}
@media (max-width:315px) and (min-width:275px) {
    .GateKeeperOverall{
        width: 260px;
    }
    .GateKeeperContributionBox {
        margin-bottom: 10px;
    }
    .GateKeeperContributionBoxSection {
        padding: 15px 0px;
    }
    .GateKeeperContributionBoxBorder {
        padding-bottom: 10px;
    }
    .GateKeeperImage img{
        width: 80px;
        height: 80px;
    }
    .GateKeeperImage{
        margin-right: 15px;
    }
    .GateKeeperImgSectionName h5{
        font-size: 20px;
    }
    .GateKeeperBoxh6 h6{
        font-size: 15px;
        line-height: 25px;
    }
    .GateKeeperImgSectionName{
        margin-bottom: 4px;
    }
    .GateKeeperBlockSpan span{
        font-size: 12px;
    }
    .GateKeeperImgSectionIcons li a{
        font-size: 12px;
    }
    .GateKeeperBlockIcon{
        font-size: 15px;
        margin-right: 7px;
    }
    .GateKeeperBlockIcon i{
        padding: 6px;
    }
    .GateKeeperBoxSpan span {
        padding: 3px 7px;
        font-size: 11px;
    }
    .GateKeeperBoxSpan i {
        font-size: 15px;
        margin-right: 8px;
    }
    .universeButton{
        padding: 0px 3px 0px 3px!important;
        font-size: 11px;   
    }
    .GateKeeperA a{
        padding: 1px 4px!important;
        font-size: 10px!important;
    }
    .GateKeeperA{
        margin-right: 3px!important;
    }
    .GateKeeperBoxSectionName h6{
        font-size: 17px;
    }
    .GateKeeperBoxSectionSubName span{
        font-size: 13px;
    }
    .GateKeeperImgSectionIcons li{
        margin-bottom: 8px;
    }
    .GateKeeperBoxSection{
        padding: 12px 8px;
    }
    .GateKeeperBoxSectionSubName {
        margin-bottom: 8px;
    }
    .GateKeeperBoxTwo {
        padding-top: 10px;
    }
    .GateKeeperBoxPara p{
        font-size: 13px;
    }
    .ContributionImage span {
        font-size: 11px;
        padding: 3px 7px;
    }
    .ContributionGuarding span{
        font-size: 11px;
        padding: 3px 7px;
    }
    .ContributionMaster span{
        font-size: 11px;
        padding: 3px 7px;
    }
    .ContributionMaster i{
        font-size: 17px;
    }
    .ContributionNumber span{
        font-size: 13px;
    }
    .GateKeeperPagination li a{
        width: 20px;
        height: 20px;
        font-size: 12px;
        margin-right: 4px;
    }
    .GateKeeperPagination i{
        font-size: 12px;
        width: 20px;
        height: 20px;
    }
    .GateKeeperBoxPara p{
        font-size: 13px;
    }
    .ContributionPara p{
        font-size: 13px;
    }
    .ContributionNumber {
        margin-bottom: 4px;
    }
    .ContributionImagePart {
        margin-bottom: 8px;
    }
    .GateKeeperIconsSection {
        margin-bottom: 8px;
    }
    .ContributionImage img {
        width: 20px;
        height: 20px;
    }
    .ContributionGuarding i{
        font-size: 20px;
    }
    .GateKeeperTwoIcon i{
        font-size: 17px;
    }
}

@media (max-width:275px){
    .GateKeeperOverall{
        width: 260px;
    }
    .GateKeeperContributionBox {
        margin-bottom: 10px;
    }
    .GateKeeperContributionBoxSection {
        padding: 15px 0px;
    }
    .GateKeeperContributionBoxBorder {
        padding-bottom: 10px;
    }
    .GateKeeperImage img{
        width: 70px;
        height: 70px;
    }
    .GateKeeperImage{
        margin-right: 15px;
    }
    .GateKeeperImgSectionName h5{
        font-size: 20px;
    }
    .GateKeeperBoxh6 h6{
        font-size: 16px;
    }
    .GateKeeperImgSectionName{
        margin-bottom: 4px;
    }
    .GateKeeperBlockSpan span{
        font-size: 12px;
    }
    .GateKeeperImgSectionIcons li a{
        font-size: 12px;
    }
    .GateKeeperBlockIcon{
        font-size: 15px;
        margin-right: 7px;
    }
    .GateKeeperBlockIcon i{
        padding: 6px;
    }
    .GateKeeperBoxSpan span {
        padding: 3px 7px;
        font-size: 10px;
    }
    .GateKeeperBoxSpan i {
        font-size: 15px;
        margin-right: 8px;
    }
    .universeButton{
        padding: 0px 3px 0px 3px!important;
        font-size: 11px;   
    }
    .GateKeeperA a{
        padding: 1px 4px!important;
        font-size: 10px!important;
    }
    .GateKeeperA{
        margin-right: 3px!important;
    }
    .GateKeeperBoxSectionName h6{
        font-size: 17px;
    }
    .GateKeeperBoxSectionSubName span{
        font-size: 13px;
    }
    .GateKeeperImgSectionIcons li{
        margin-bottom: 8px;
    }
    .GateKeeperBoxSectionSubName {
        margin-bottom: 9px;
    }
    .GateKeeperBoxSection {
        padding: 15px;
    }
    .GateKeeperBoxPara p{
        font-size: 13px;
    }
    .ContributionImage span {
        font-size: 11px;
        padding: 3px 7px;
    }
    .ContributionGuarding span{
        font-size: 11px;
        padding: 3px 7px;
    }
    .ContributionMaster span{
        font-size: 11px;
        padding: 3px 7px;
    }
    .ContributionMaster i{
        font-size: 17px;
    }
    .ContributionNumber span{
        font-size: 13px;
    }
    .GateKeeperPagination li a{
        width: 20px;
        height: 20px;
        font-size: 12px;
        margin-right: 4px;
    }
    .GateKeeperPagination i{
        font-size: 12px;
        width: 20px;
        height: 20px;
    }
    .GateKeeperBoxPara p{
        font-size: 13px;
    }
    .ContributionPara p{
        font-size: 13px;
    }
    .ContributionNumber {
        margin-bottom: 4px;
    }
    .ContributionImagePart {
        margin-bottom: 8px;
    }
    .GateKeeperIconsSection {
        margin-bottom: 8px;
    }
    .ContributionImage img {
        width: 20px;
        height: 20px;
    }
    .ContributionGuarding i{
        font-size: 20px;
    }
}
