body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: 'Inter', sans-serif;
}
input{
  outline: none;
  border: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
p{
  color: black;
  margin: 0;
}
a{
  text-decoration: none;
  padding: 0!important;
}
ul{
  margin: 0px;
  padding: 0px;
}
li{
  list-style: none;
}
button{
  border: none;
  background: none;
  margin: 0;
  padding: 0;
}
.floatleftcls{
  width: 100%;
  float: left;
}
.cursor{
  cursor: pointer;
}
h1,h2,h3,h4,h5,h6{
  color: black;
  margin: 0;
}
.width75cls{
  width: 75%;
  margin: 0 auto;
}
















