.HomeBrowseOverall{
    width: 1156px;
    margin: 0 auto;
    padding-top: 113px;
}
.HomeBrowseHeaderSection{
    padding: 10px 0;
}
.BrowseHeaderH5 h5 {
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    color: #19191D; 
   
}
.BrowseHeaderButton button i {
    font-size: 20px;
    padding: 7px 10px;
    border-radius: 5px;
    color: #B4B4BB;
    background-color: #EDEDF0;
}
.HomeBrowsePaginationBoxSection{
    margin-bottom: 25px;
}
.HomeBrowsePaginationBox{
    width: 100%!important;
    background-color: #ECF7ED;
    border: solid 2px #A9D3AB;
    border-radius: 10px;
    padding: 12px 10px;
    box-shadow: -1px 2px #A9D3AB;
    margin-bottom: 8px;
}
.HomeBrowsePaginationBoxPara p {
    color: #2B662E;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 1px;
}
.HomeBrowsePaginationBoxh6 h6 {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    color: #132C14;
}
.HomeBrowsePaginationBoxh6{
    margin-bottom: 14px;
    float: left;
}
.HomeBrowseBox{
    width: 100%!important;
    background-color: #ECF7ED;
    border: solid 2px #A9D3AB;
    border-radius: 10px;
    padding: 12px 10px;
    box-shadow: -1px 2px #A9D3AB;
    margin-bottom: 25px;
}
.HomeBrowseBoxPara p {
    color: #2B662E;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 1px;
}
.HomeBrowseBoxh6 h6 {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    color: #132C14;
}
.HomeBrowseBoxh6{
    margin-bottom: 14px;
    float: left;
}
.HomeBrowseImageSection{
    margin-bottom: 12px;
}
.HomeBrowseImage{
    width: auto;
    background-color: #fff;
    border-radius: 25px;
    float: left;
}
.HomeBrowseImage img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    object-fit: cover;
}
.HomeBrowseImage span {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding: 4px 10px;
    color: #65B168;
    display: flex;
    align-items: center;
}
.HomeBrowseIconsSection{
    margin-bottom: 14px;
}
.HomeBrowseIconsSpan{
    width: auto;
    background-color: #fff;
    border-radius: 25px;
    float: left;
}
.HomeBrowseIconsSpan span {
    padding: 4px 10px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    display: flex;
    align-items: center;
}
.HomeBrowseIconsSpan i {
    font-size: 20px;
    margin-right: 13px;
}
.HomeBrowsethumbsup span {
    color: #4F91FF;
}
.HomeBrowsethumbsup i {
    color: #9DC2FF;
}
.HomeBrowseuser span {
    color: #EF6292;
}
.HomeBrowseuser i {
    color: #EF6292;
}
.HomeBrowserandom span {
    color: #FB982E;
}
.HomeBrowserandom i {
    color: #FDC68B;
}
.HomeBrowseBoxUniHead{
    margin-bottom: 8px;
}
.HomeBrowseBoxUniHeadDots{
    width: 32px;
    height: 20px;    
    background-color: #E1E1E3;
    font-size: 24px;
    border-radius: 4px;
    color: #5A5B6A;
}
.HomeBrowseBoxUniHead span{
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: #3A3A44;
}
.HomeBrowseNumber{
    margin-bottom: 12px;
}
.HomeBrowseNumber span{
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #9696A0;
}
.HomeBrowseMaster{
    width: auto;
    background-color: #FEE8E7;
    border-radius: 25px;
    float: left;
}
.HomeBrowseMaster span {
    padding: 4px 10px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #C8372D;
    display: flex;
    align-items: center;
}
.HomeBrowseMaster i {
    font-size: 20px;
    color: #FAA9A3;
    margin-right: 6px;
}
.HomeBrowseIconsTwoIcon i{
    padding: 4px 10px;
    background-color: #fff;
    border-radius: 20px;
    font-size: 20px;
    color: #787885;
}
.HomeBrowseGuardian{
    width: auto;
    background-color: #EBF2FF;
    border-radius: 25px;
    float: left;
}
.HomeBrowseGuardian span {
    padding: 4px 10px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #2264D1;
    display: flex;
    align-items: center;
}
.HomeBrowseGuardian i {
    font-size: 25px;
    color: #9DC2FF;
    margin-right: 6px;
}
.HomeBrowseInputBox{
    width: 100%!important;
    background-color: #EDEDF0;
    border: solid 2px #9696A0;
    border-radius: 10px;
    padding: 12px 10px;
    box-shadow: -1px 2px #9696A0;
}
.PostHeader span{
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #000;
}
.PostButton button{
    font-size: 14px;
    padding: 12px 16px;
    line-height: 16px;
    font-weight: 700;   
}
.PostButtonGreen{
    color: #132C14;    
    border-radius: 5px;
    background-color: #ECF7ED;    
    border: 2px solid #65B168;
    margin-right: 16px;
}
.PostButtonPink{
    color: #43130F;
    background-color: #FEE8E7;    
    border: 2px solid #F6655A;    
    border-radius: 5px;
}
.NextPost span{
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #3A3A44;
    margin-right: 16px;
}
.HomeBrowseDropdown{
    width: 200px;
    height: 40px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #19191D!important;
    background-color: #fff;
    border: 1px solid #B4B4BB;
    text-align: left;
}
.HomeBrowseDropdown:hover{
    color: #19191D;
    background-color: #fff;
    border-color: #B4B4BB;
}
.HomeBrowseDropdown:focus{
    background-color: #fff;
    border-color: #B4B4BB;
    box-shadow: none!important;
}
.HomeBrowseDropdown::after{
    background: none;
    outline: none;
    position: relative;
    left: 51%;
    color: #B4B4BB;
    border-top: 0.4em solid;
    border-right: 0.4em solid transparent;
    border-bottom: 0;
    border-left: 0.4em solid transparent;
}
.HomeBrowseDropdown i{
    color: #B4B4BB;
    font-size: 20px;
}
.HomeBrowseDropdownUL{
    min-width: 200px;
}
.HomeBrowseDropdownUL li{
    padding: 0px 10px;
}
.HomeBrowseTextarea{
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 5px;
    outline: none;
    resize: none;
    border: none;
    box-shadow: 0px 2px #9696a079;
}
.HomeBrowseTextarea:focus{
    box-shadow: 0px 2px #9696a079;
    border: none;
}
.HomeBrowseTextareaPart textarea::placeholder{
    font-size: 16px!important;
    color: #9696A0;
    font-weight: 400;
    line-height: 24px;
}
.HomeBrowseTextareaPart h6{
    font-size: 12px; 
    color: #787885;
    text-align: end;
}
.HomeBrowsePagination li a{
    width: 43px;
    height: 40px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #EDEDF0;
    color: #787885!important;
    margin-right: 16px;
    border: none!important;
    border-radius: 4px!important;
}
.HomeBrowsePagination li a:focus{
    box-shadow: none;
    background-color: #787885;
    color: #FFFFFF!important;
}
.HomeBrowsePagination i{
    color: #ECF7ED;
    font-size: 18px;
    padding: 10px;
    border-radius: 4px!important;
    background-color: #65B168;
}

@media (max-width: 1250px) and (min-width: 1100px) {
    .HomeBrowseOverall{
        width: 1030px;
    }
    .HomeBrowseBox{
        margin-bottom: 15px;
   }
   .HomeBrowsePaginationBoxSection {
        margin-bottom: 15px;
   }
}
@media (max-width: 1100px) and (min-width: 992px) {
    .HomeBrowseOverall{
        width: 920px;
    }
    .HomeBrowseImage span {
        font-size: 13px;
        padding: 4px 10px;
    }
    .HomeBrowseImage img {
        width: 22px;
        height: 22px;
    }
    .HomeBrowseMaster span {
        padding: 4px 10px;
        font-size: 13px;
    }
    .HomeBrowseMaster i {
        font-size: 17px;
        margin-right: 6px;
    }
    .HomeBrowseIconsSpan span {
        padding: 4px 10px;
        font-size: 13px;
    }
    .HomeBrowseIconsSpan i {
        font-size: 18px;
        margin-right: 10px;
    }
    .HomeBrowseGuardian span {
        padding: 4px 10px;
        font-size: 13px;
    }
    .HomeBrowseGuardian i {
        font-size: 21px;
        margin-right: 6px;
    }
    .HomeBrowsePaginationBoxPara p{
        font-size: 15px;
    }
    .HomeBrowseBox{
        margin-bottom: 15px;
   }
   .HomeBrowsePaginationBoxSection {
        margin-bottom: 10px;
   }
}
@media (max-width: 992px) and (min-width: 850px) {
    .HomeBrowseOverall{
        width: 800px;
    }
    .HomeBrowseHeaderSection{
        padding: 5px 0;
    }
    .HomeBrowseImage span {
        font-size: 14px;
        padding: 4px 10px;
    }
    .HomeBrowseImage img {
        width: 22px;
        height: 22px;
    }
    .HomeBrowseNumber{
        margin-bottom: 7px;
    }
    .HomeBrowseImageSection{
        margin-bottom: 10px;
    }
    .HomeBrowseIconsSection {
        margin-bottom: 10px;
    }
    .HomeBrowseMaster span {
        padding: 4px 10px;
        font-size: 14px;
    }
    .HomeBrowseMaster i {
        font-size: 18px;
        margin-right: 6px;
    }
    .HomeBrowseIconsSpan span {
        padding: 4px 10px;
        font-size: 13px;
    }
    .HomeBrowseIconsSpan i {
        font-size: 18px;
        margin-right: 10px;
    }
    .HomeBrowseIconsTwoIcon i{
        font-size: 18px;
    }
    .HomeBrowseGuardian span {
        padding: 4px 10px;
        font-size: 14px;
    }
    .HomeBrowseGuardian i {
        font-size: 21px;
        margin-right: 6px;
    }
    .HomeBrowsePaginationBoxPara p{
        font-size: 15px;
    }
    .HomeBrowseBoxPara p{
        font-size: 15px;
    }
   .HomeBrowseBoxh6 {
        margin-bottom: 7px;
   }
   .HomeBrowseBox{
        margin-bottom: 15px;
   }
   .HomeBrowsePaginationBoxSection {
        margin-bottom: 10px;
   }
}
@media (max-width: 850px) and (min-width: 750px) {
    .HomeBrowseOverall{
        width: 700px;
    }
    .HomeBrowseHeaderSection{
        padding: 5px 0;
    }
    .HomeBrowseImage span {
        font-size: 14px;
        padding: 4px 10px;
    }
    .HomeBrowseImage img {
        width: 22px;
        height: 22px;
    }
    .HomeBrowseNumber{
        margin-bottom: 7px;
    }
    .HomeBrowseImageSection{
        margin-bottom: 10px;
    }
    .HomeBrowseIconsSection {
        margin-bottom: 10px;
    }
    .HomeBrowseMaster span {
        padding: 4px 10px;
        font-size: 14px;
    }
    .HomeBrowseMaster i {
        font-size: 18px;
        margin-right: 6px;
    }
    .HomeBrowseIconsSpan span {
        padding: 4px 10px;
        font-size: 13px;
    }
    .HomeBrowseIconsSpan i {
        font-size: 18px;
        margin-right: 10px;
    }
    .HomeBrowseIconsTwoIcon i{
        font-size: 18px;
    }
    .HomeBrowseGuardian span {
        padding: 4px 10px;
        font-size: 14px;
    }
    .HomeBrowseGuardian i {
        font-size: 21px;
        margin-right: 6px;
    }
    .HomeBrowsePaginationBoxPara p{
        font-size: 15px;
    }
    .HomeBrowseBoxPara p{
        font-size: 15px;
    }
   .HomeBrowseBoxh6 {
        margin-bottom: 7px;
   }
   .HomeBrowseBox{
        margin-bottom: 15px;
   }
   .HomeBrowsePaginationBoxSection {
        margin-bottom: 10px;
   }
}


