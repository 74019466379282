.sidebarParent {
  width: 100%;
  z-index: 999;
  position: absolute;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}

.header {
  padding: 20px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #ededf0;
}

.heading {
  font-weight: 700;
  font-size: 20px;
}
.closeBtn {
  background: #ededf0;
  font-weight: 500;
  font-size: 15px;
  border-radius: 100%;
  padding: 5px 10px;
}
.clearAllBtnWrapper {
  width: 100%;
  padding: 20px 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.clearAllBtn {
  background: transparent;
  color: #46a6f7;
  outline: none;
  border: none;
}

.radioBtnsWrapper {
  padding: 20px 20px;
}
.radioBtn {
  margin-right: 10px;
}
.radioBtnSelected {
  border-radius: 4px;
  background: #ededf0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.radioBtnCommon {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.mainContentSplitter {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background: #fff;
  width: 30%;
  height: 100%;
  float: right;
}

.btnsFooter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px 20px;
}
.body-locked {
  overflow: hidden;
}
.applyBtn{
padding:10px 20px;
background:#46a6f7;
color:white;
}
.cancelBtn{
padding:10px 20px;
border: 1px solid #B4B4BB;
margin-right:10px
}
.cancelBtn:hover{
  border: 1px solid #79797d;

}
.applyBtn:hover{
background:#2d6b9e;
}
@media all and (max-width: 700px) {
  .mainContentSplitter {
    width: 80%;
  }
}
