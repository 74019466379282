.startimg{
    background-color: #7fe4d02e;
    height: calc(100vh);
}
.logincont h5{
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #292A31; 
}
.logincont, .logincontlabel{
    flex-direction: column;
}
.logincontlabel ::placeholder{
    font-size: 14px;
    font-weight: 500;
}
.reseth5 h5{
    margin-bottom: 15px;
}
.tick{
    width: 110px;
    height: 110px;
    background-color: #0CCD93;
    border-radius: 50%;
    color: #fff;
    margin-bottom: 36px;
}
.tick i{
    font-size: 40px;    
}
.resetP p{
    font-weight: 500;
    font-size: 16px;
    color: #5A5B6A;
    line-height: 26px;
}
.resetP{    
    margin-bottom: 30px;
}
.logincontlabel label{
    font-weight: 500;
    font-size: 16px;
}
.logincontlabel input{    
    border: 1px solid #dee1e6;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 500;
}
.loginRegister{
    font-size: 14px;
    font-weight: 500;
}
.register{
    border-bottom: solid 1px #2979FF;
}
.continue{
    border: none;
    width: 100%;
    height: 64px;
    background-color: #2979FF;
    color: #fff;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 24px;
}
.pointerclass, .pointerclass input{
    cursor: pointer;
}

@media (max-width: 1250px) and (min-width: 1025px){
    .loginImg img{
        width: 280px;
        height: 230px;
    }
    .loginEllipse{
        width: 315px;
        height: 325px;
    }
    .phonenospan h6{
        font-size: 16px;
    }
}
@media (max-width: 1025px) and (min-width: 767px){
    .loginImg img{
        width: 280px;
        height: 230px;
    }
    .loginEllipse{
        width: 315px;
        height: 325px;
    }
}
@media (max-width: 767px) and (min-width: 500px){
    .logincont h5{
        margin-bottom: 18px; 
    }
    .logincontlabel input{
        height: 45px;
        margin-bottom: 13px;
    }
    .login{
        height: 45px;
        font-size: 14px;
        margin-bottom: 14px;
    }
    .continue{
        height: 55px;
    }
}
@media (max-width: 499px){
    .logincont h5{
        font-size: 19px;
        line-height: 30px;
        margin-bottom: 20px; 
    }
    .logincontlabel input{
        height: 40px;
        font-size: 14px;
        margin-bottom: 12px;
    }
    .login{
        height: 40px;
        font-size: 13px;
        margin-bottom: 14px;
    }
    .logincontlabel label{
        font-size: 14px;
        line-height: 12px;
        margin-bottom: 10px;
    }
    .logincontlabel ::placeholder{
        font-size: 12px;
    }
    .loginRegister{
        font-size: 11px;
    } 
    .loginForgot{
        margin-bottom: 12px;
    }
    .resetP p{
        font-size: 13px;
    }   
    .continue{
        height: 50px;
    }
}