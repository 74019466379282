.MyUniversePublicOverall{
    width: 1156px;
    margin: 0 auto;
    margin-top: 30px;
}
.MyProfileLeft{
    margin-right: 30px;
}
.MyProfileAccordionoverall{
    border-radius: 10px!important;
    background-color: #F9F9FC!important;
    padding: 16px 14px!important;
}
.MyProfileAccordion{
    background-color: #F9F9FC!important;
    border: none!important;
}
.MyProfileAccordionUL{
    padding: 0px!important;
}
.MyProfileAccordionUL li{
    margin-bottom: 10px!important;
}
.MyProfileAccordionUL li a{
    display: flex!important;
    align-items: center!important;
    justify-content: center!important;
    text-align: start!important;    
    color: #19191D!important;
    font-size: 16px!important;
    line-height: 42px!important;
    font-weight: 400!important;
}
.MyProfileAccordionULbg a{
    background-color: #E1E1E3!important;
    font-weight: 600!important;
    border-radius: 5px!important;
}
.MyProfileAccordionbutton:focus, .MyProfileAccordionbutton:focus-visible{
    outline: none!important;
    border: none!important;
    box-shadow: none!important;
    border-color: transparent!important;
}
.MyProfileA{
    margin-right: 16px!important;
}
.MyProfileA a{
    padding: 1px 7px!important;
    background-color: #4A4B57!important;
    font-size: 14px!important;
    font-weight: 500!important;
    line-height: 20px!important;
    color: #F7F7FA!important;
    border-radius: 10px!important;
}
.MyProfileAbg a{
    background-color: #E1E1E3!important;
    color: #19191D!important;
}
.MyProfileAbg1 a{
    background-color: #19191D!important;
    color: #E1E1E3!important;
}
.MyProfileAccordionh2{
    line-height: 42px!important;
    margin-bottom: 10px!important;
}
.MyProfileAccordionbutton{
    font-size: 16px!important;
    font-weight: 500!important;
    background-color: #F9F9FC!important;    
    box-shadow: none!important;
    border: none!important;
    border-color: none!important;
    outline: none!important;
    text-align: right!important;
    display: flex;
    padding: 0px!important;
}
.MyProfileDownArrow i{
    font-size: 20px!important;
    margin-right: 14px!important;
}
.MyProfileAccordionbutton::after{
    background: none!important;
    content: none!important;    
}
.MyProfileAccordionbutton:not(.collapsed){
    color: #19191D!important;
}
.MyProfileAccordionbutton span{
    color: #19191D!important;
    font-size: 16px!important;
    line-height: 24px!important;
    font-weight: 500!important;
}
.MyProfileAccordionULbg{
    background-color: #E1E1E3!important;
    font-weight: 600!important;
    border-radius: 5px!important;
}
.MyProfileSpan{
    line-height: 42px;
    margin-bottom: 10px!important;
}
.MyProfileSpanLast{
    margin-bottom: 0px!important;
}
.MyProfileSpan span{
    margin-left: 16px!important;
    font-size: 16px!important;
    font-weight: 500!important;
    cursor: pointer;
}
.MyProfileRight{
    border: 1px solid #E1E1E3;
    border-radius: 12px;
}
.MyUniverseSection{
    padding: 25px 30px 25px 40px;
    border-bottom: 1px solid #E1E1E3;
}
.LastBoxBorder{
    border: 1px solid #E1E1E3!important;
    border-radius: 0px 0px 12px 12px!important;
}
.MyUniverseh6 h6{
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    color: #19191D;
}
.AddNewUniverseSection{
    line-height: 0px!important;
    margin-bottom: 16px;
}
.Universesh6 h6{
    font-size: 20px;
    font-weight: 700;
    color: #19191D;
}
.Universesh6{
    margin-right: 16px;
}
.UniversesA a{
    padding: 1px 7px!important;
    background-color: #4A4B57!important;
    font-size: 14px!important;
    font-weight: 500!important;
    color: #F7F7FA!important;
    border-radius: 10px!important;
}
.universeplus{
    font-size: 30px;
    color: #4A4B57;
    margin-right: 11px;
    margin-bottom: 4px;
}
.AddNewUniversespan{
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: #4A4B57;
}
.AddNewUniverse{
    padding: 8px 16px;
    border: 1px solid #E1E1E3;
    border-radius: 7px;
    cursor: pointer;
}
.ListUniversesSpan{
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    color: #787885;
}
.MyUniversePrivate{
    margin-bottom: 24px;
}
.TimeUpgradeSection{    
    margin-bottom: 30px;
    padding: 12px 16px;
    background-color: #FEF2F1;
    border-radius: 10px;
    box-shadow: 0px 2px #dcc9c8;
}
.TimeUpgradeLogo{
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #C8372D;
    margin-right: 10px;
    margin-top: 9px;
}
.TimeUpgradeLogo i{
    color: #F7F7FA;
    font-size: 19px;
    top: 3px;
    left: 7px;
}
.TimeUpgradeh5 h5{
    font-size: 24px;
    font-weight: 700;
    line-height: 40px;
    color: #43130F;
}
.TimeUpgradeP p{
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    color: #43130F;
    letter-spacing: 1px;
}
.upgratenowspan span{
    font-size: 14px;
    font-weight: 700;
    padding: 12px 16px;
    background-color: #4F91FF;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
}
.MyUniverseBox{
    width: 100%!important;
    background-color: #ECF7ED;
    border: solid 2px #A9D3AB;
    border-radius: 10px;
    padding: 12px 16px;
    box-shadow: -1px 2px #A9D3AB;
}
.MyUniverseBoxIcons{
    margin-top: 5px;
    margin-bottom: 5px;
}
.MyUniverseBox p {
    color: #2B662E;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 1px;
}
.MyUniverseBoxh6 h6 {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    color: #132C14;
}
.MyUniverseBoxSpan{
    width: auto;
    background-color: #fff;
    border-radius: 25px;
    float: left;
}
.MyUniverseBoxSpan span {
    padding: 4px 10px;
    color: #65B168;
    display: flex;
    align-items: center;
}
.MyUniverseBoxSpan i {
    font-size: 20px;
}
.Universethumbsup span {
    color: #4F91FF;
    font-size: 14px;
}
.Universethumbsup i {
    color: #9DC2FF;
}
.Universeuser span {
    color: #EF6292;
    font-size: 14px;
}
.Universeuser i {
    color: #EF6292;
}
.Universerandom span {
    color: #FB982E;
    font-size: 14px;
}
.Universerandom i {
    color: #FDC68B;
}

@media (max-width: 1250px) and (min-width: 1100px){
    .MyUniversePublicOverall{
        width: 980px;
    }
    .MyProfileA a{
        font-size: 11px!important;
    }
    .MyProfileSpan span{
        font-size: 13px!important;
    }
    .MyProfileAccordionbutton span{
        font-size: 13px!important;
    }
    .MyProfileAccordionUL li a{
        font-size: 13px!important;
    }
    .MyProfileAccordionUL li a{
        font-size: 12px!important;
        line-height: 32px!important;
    }
    .MyProfileSpan{
        line-height: 32px!important;
    }
}
@media (max-width: 1100px) and (min-width: 992px){
    .MyUniversePublicOverall{
        width: 900px;
    }
    .MyProfileSpan span{
        font-size: 14px!important;
    }
    .MyProfileLeft{
        margin-right: 22px!important;
    } 
    .MyProfileA a{
        font-size: 10px!important;
    }
    .MyProfileSpan span{
        font-size: 12px!important;
    }
    .MyProfileAccordionbutton span{
        font-size: 12px!important;
    }
    .MyProfileAccordionUL li a{
        font-size: 12px!important;
        line-height: 32px!important;
    }
    .MyProfileSpan{
        line-height: 32px!important;
    }
    .MyProfileSpan span{
        margin-left: 12px!important;
    }
    .MyProfileA{
        margin-right: 12px!important;
    }  
}
@media (max-width: 992px) and (min-width: 850px){
    .MyUniversePublicOverall{
        width: 770px;
    }
    .MyProfileLeft{
        margin-right: 0px!important;
    }
    .MyProfileAccordionoverall{
        margin-bottom: 30px!important;
    }
} 
@media (max-width: 850px) and (min-width: 750px){
    .MyUniversePublicOverall{
        width: 670px;
    }
    .MyProfileLeft{
        margin-right: 0px!important;
    }
    .MyProfileAccordionoverall{
        margin-bottom: 30px!important;
    }
}
@media (max-width: 750px) and (min-width: 600px){
    .MyUniversePublicOverall{
        width: 550px;
    }
    .MyProfileLeft{
        margin-right: 0px!important;
    }
    .MyProfileAccordionoverall{
        margin-bottom: 30px!important;
    }
    .MyUniverseh6 h6{
        font-size: 20px;
        line-height: 30px;
    }
    .TimeUpgradeh5 h5{
        font-size: 22px!important;
    }
    .MyUniverseBoxSpan span{
        font-size: 14px;
    }
}
@media (max-width: 600px) and (min-width: 500px){
    .MyUniversePublicOverall{
        width: 450px;
    }
    .MyProfileLeft{
        margin-right: 0px!important;
    }
    .MyProfileAccordionoverall{
        margin-bottom: 30px!important;
    }
    .TimeUpgradeh5 h5{
        font-size: 20px!important;
    }
    .MyUniverseh6 h6{
        font-size: 18px;
        line-height: 30px;
    }
    .MyProfileInformation li{
        font-size: 13px;
    }
    .MyProfileAccordionbutton span{
        font-size: 14px!important;
    }
    .MyProfileSpan span{
        font-size: 14px!important;
    }
    .MyProfileAccordionUL li a{
        font-size: 14px!important;
    }
    .MyUniverseSection {
        padding: 25px 30px 25px 30px;
    }
    .MyUniverseBoxSpan span{
        font-size: 14px;
    }
}
@media (max-width: 500px) and (min-width: 400px){
    .MyUniversePublicOverall{
        width: 370px;
    }
    .MyProfileSpan{
        line-height:35px!important;
    }
    .MyProfileLeft{
        margin-right: 0px!important;
    }
    .MyProfileAccordionoverall{
        margin-bottom: 20px!important;
    }
    .MyProfileh6 h6{
        font-size: 15px;
        line-height: 0px;
    }
    .MyProfileAccordionbutton span{
        font-size: 14px!important;
    }
    .MyProfileSpan span{
        font-size: 14px!important;
    }
    .MyProfileAccordionUL li a{
        font-size: 14px!important;
        line-height: 35px!important;
    }
    .Universesh6 h6{
        font-size: 17px!important;
    }
    .UniversesA a{
        font-size: 13px!important;
    }
    .MyProfileA a{
        font-size: 13px!important;
    }
    .AddNewUniversespan {
        font-size: 13px!important;
    }
    .MyUniverseSection{
        padding: 15px 18px 22px 18px;
    }
    .MyUniversePrivate {
        margin-bottom: 12px;
    }
    .MyUniverseBoxSpan span{
        font-size: 14px;
    }
}
@media (max-width: 400px) and (min-width: 350px){
    .MyUniversePublicOverall{
        width: 320px;
    }
    .MyProfileSpan{
        line-height:35px!important;
    }
    .MyProfileLeft{
        margin-right: 0px!important;
    }
    .MyProfileAccordionoverall{
        margin-bottom: 20px!important;
    }
    .MyProfileh6 h6{
        font-size: 15px;
        line-height: 0px;
    }
    .MyProfileAccordionbutton span{
        font-size: 13px!important;
    }
    .MyProfileSpan span{
        font-size: 13px!important;
    }
    .MyProfileAccordionUL li a{
        font-size: 14px!important;
        line-height: 35px!important;
    }
    .Universesh6 h6{
        font-size: 15px!important;
    }
    .UniversesA a{
        font-size: 12px!important;
    }
    .MyProfileA a{
        font-size: 13px!important;
    }
    .AddNewUniversespan {
        font-size: 12px!important;
        line-height: 17px;
    }
    .AddNewUniverse {
        padding: 8px 8px;
    }
    .universeplus {
        font-size: 26px!important;
    }
    .MyUniverseSection{
        padding: 15px 18px 22px 18px;
    }
    .MyUniversePrivate {
        margin-bottom: 12px;
    }
    .MyUniverseh6 h6{
        font-size: 17px!important;
    }
    .ListUniversesSpan {
        font-size: 14px!important;
    }
    .TimeUpgradeh5 h5{
        font-size: 20px!important;
    }
    .TimeUpgradeP p{
        font-size: 14px!important;
    }
    .MyUniverseBox p{
        font-size: 14px!important;
    }
    .MyUniverseBoxh6 h6{
        font-size: 18px!important;
    }
    .MyUniverseBoxSpan span{
        font-size: 14px;
    }
}
@media (max-width: 350px) and (min-width: 275px){
    .MyUniversePublicOverall{
        width: 270px;
    }
    .MyProfileSpan{
        line-height:30px!important;
    }
    .MyProfileLeft{
        margin-right: 0px!important;
    }
    .MyProfileAccordionoverall{
        margin-bottom: 20px!important;
    }
    .MyProfileh6 h6{
        font-size: 15px;
        line-height: 0px;
    }
    .MyProfileAccordionbutton span{
        font-size: 12px!important;
    }
    .MyProfileSpan span{
        font-size: 12px!important;
    }
    .MyProfileAccordionUL li a{
        font-size: 12px!important;
        line-height: 28px!important;
    }
    .Universesh6 h6{
        font-size: 15px!important;
    }
    .Universesh6 {
        margin-right: 9px!important;
    }
    .UniversesA a{
        font-size: 12px!important;
    }
    .MyProfileA a{
        font-size: 12px!important;
    }
    .AddNewUniversespan {
        font-size: 10px!important;
        line-height: 14px;
    }
    .AddNewUniverse {
        padding: 7px 3px;
    }
    .universeplus {
        font-size: 22px!important;
        margin-right: 5px!important;
    }
    .MyUniverseSection{
        padding: 15px 18px 22px 18px;
    }
    .MyUniversePrivate {
        margin-bottom: 12px;
    }
    .MyUniverseh6 h6{
        font-size: 17px!important;
        line-height: 22px;
    }
    .ListUniversesSpan {
        font-size: 13px!important;
    }
    .TimeUpgradeh5 h5{
        font-size: 18px!important;
    }
    .TimeUpgradeP p{
        font-size: 12px!important;
    }
    .MyUniverseBox p{
        font-size: 13px!important;
    }
    .MyUniverseBoxh6 h6{
        font-size: 16px!important;
    }
    .upgratenowspan span{
        font-size: 12px!important;
        padding: 9px 13px;
    }
    .Universethumbsup span{
        font-size: 12px!important;
    }
    .MyUniverseBoxSpan i{
        font-size: 18px!important;
        margin-right: 7px!important;
    }
    .MyUniverseBoxSpan span{
        padding: 2px 8px;
        font-size: 12px;
    }
}
@media (max-width: 275px){
    .MyUniversePublicOverall{
        width: 270px;
    }
    .MyProfileSpan{
        line-height:30px!important;
    }
    .MyProfileLeft{
        margin-right: 0px!important;
    }
    .MyProfileAccordionoverall{
        margin-bottom: 20px!important;
    }
    .MyProfileh6 h6{
        font-size: 15px;
        line-height: 0px;
    }
    .MyProfileAccordionbutton span{
        font-size: 14px!important;
    }
    .MyProfileSpan span{
        font-size: 14px!important;
    }
    .MyProfileAccordionUL li a{
        font-size: 14px!important;
        line-height: 28px!important;
    }
    .Universesh6 h6{
        font-size: 15px!important;
    }
    .Universesh6 {
        margin-right: 9px!important;
    }
    .UniversesA a{
        font-size: 12px!important;
    }
    .MyProfileA a{
        font-size: 12px!important;
    }
    .AddNewUniversespan {
        font-size: 10px!important;
        line-height: 14px;
    }
    .AddNewUniverse {
        padding: 7px 3px;
    }
    .universeplus {
        font-size: 22px!important;
        margin-right: 5px!important;
    }
    .MyUniverseSection{
        padding: 15px 18px 22px 18px;
    }
    .MyUniversePrivate {
        margin-bottom: 12px;
    }
    .MyUniverseh6 h6{
        font-size: 17px!important;
        line-height: 22px;
    }
    .ListUniversesSpan {
        font-size: 13px!important;
    }
    .TimeUpgradeh5 h5{
        font-size: 18px!important;
    }
    .TimeUpgradeP p{
        font-size: 12px!important;
    }
    .MyUniverseBox p{
        font-size: 13px!important;
    }
    .MyUniverseBoxh6 h6{
        font-size: 16px!important;
    }
    .upgratenowspan span{
        font-size: 12px!important;
        padding: 9px 13px;
    }
    .Universethumbsup span{
        font-size: 12px!important;
    }
    .MyUniverseBoxSpan i{
        font-size: 18px!important;
        margin-right: 7px!important;
    }
    .MyUniverseBoxSpan span{
        padding: 2px 8px;
        font-size: 12px;
    }
}