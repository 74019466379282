.MyProfileOverAll{
    width: 1156px;
    margin: 0 auto;
    padding-top: 100px;
}
.MyProfileLeft{
    margin-right: 30px;
}
.MyProfileAccordionoverall{
    border-radius: 10px!important;
    background-color: #F9F9FC!important;
    padding: 16px 14px!important;
}
.MyProfileAccordion{
    background-color: #F9F9FC!important;
    border: none!important;
}
.MyProfileAccordionUL{
    padding: 0px!important;
}
.MyProfileAccordionUL li{
    margin-bottom: 10px!important;
}
.MyProfileAccordionUL li a{
    display: flex!important;
    align-items: center!important;
    justify-content: center!important;
    text-align: start!important;    
    color: #19191D!important;
    font-size: 16px!important;
    line-height: 42px!important;
    font-weight: 400!important;
}
.MyProfileAccordionULbg a{
    background-color: #E1E1E3!important;
    font-weight: 600!important;
    border-radius: 5px!important;
}
.MyProfileAccordionbutton:focus, .MyProfileAccordionbutton:focus-visible{
    outline: none!important;
    border: none!important;
    box-shadow: none!important;
    border-color: transparent!important;
}
.MyProfileA{
    margin-right: 16px!important;
}
.MyProfileA a{
    padding: 1px 7px!important;
    background-color: #4A4B57!important;
    font-size: 14px!important;
    font-weight: 500!important;
    line-height: 20px!important;
    color: #F7F7FA!important;
    border-radius: 10px!important;
}
.MyProfileAbg a{
    background-color: #E1E1E3!important;
    color: #19191D!important;
}
.MyProfileAbg1 a{
    background-color: #19191D!important;
    color: #E1E1E3!important;
}
.MyProfileAccordionh2{
    line-height: 42px!important;
    margin-bottom: 10px!important;
}
.MyProfileAccordionbutton{
    font-size: 16px!important;
    font-weight: 500!important;
    background-color: #F9F9FC!important;    
    box-shadow: none!important;
    border: none!important;
    border-color: none!important;
    outline: none!important;
    text-align: right!important;
    display: flex;
    padding: 0px!important;
}
.MyProfileDownArrow i{
    font-size: 20px!important;
    margin-right: 14px!important;
}
.MyProfileAccordionbutton::after{
    background: none!important;
    content: none!important;    
}
.MyProfileAccordionbutton:not(.collapsed){
    color: #19191D!important;
}
.MyProfileAccordionbutton span{
    color: #19191D!important;
    font-size: 16px!important;
    line-height: 24px!important;
    font-weight: 500!important;
}
.MyProfileSpan{
    line-height: 42px;
    margin-bottom: 10px!important;
}
.MyProfileSpanLast{
    margin-bottom: 0px!important;
}
.MyProfileSpan span{
    margin-left: 16px!important;
    font-size: 16px!important;
    font-weight: 500!important;
    cursor: pointer;
}
.MyProfileRight{
    border: 1px solid #E1E1E3;
    border-radius: 12px;
}
.MyProfileadd{
    border: 1px solid #E1E1E3;
    border-radius: 12px;
    padding: 50px;
}


.MyProfilePictureSection{
    padding: 25px 30px 25px 40px;
    border-bottom: 1px solid #E1E1E3;
}
.LastBoxBorder{
    border: 1px solid #E1E1E3!important;
    border-radius: 0px 0px 12px 12px!important;
}
.MyProfileImgPart{
    padding: 36px 0px 14px 0px;
}
.align{
    justify-content: center;
}
.MyProfileh6 h6{
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    color: #19191D;
}
.MyProfileImg img{
    width: 170px;
    height: 170px;
    object-fit: cover;
    border-radius: 50%;
}
.MyProfileImgEdit{
    width: 56px;
    height: 56px;
    background-color: #5A5B6A;
    border-radius: 50%;
    bottom: -8px;
    right: 21px;
    box-shadow: 1px 2px #E1E1E3;
}
.MyProfileImgEdit i{
    font-size: 17px;
    color: #F7F7FA;
}
.MyProfileConth5 h5{
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    color: #19191D;
}
.MyProfileNameSection{
    margin-bottom: 15px;
}
.MyProfileContbutton button{
    padding: 4px 10px;
    background-color: #EBF2FF;
    border-radius: 5px;
}
.MyProfileContbutton i{
    color: #2264D1;
    font-size: 16px;
    margin-right: 7px;
}
.MyProfileContbutton span{
    color: #2264D1;
    font-size: 14px;
    font-weight: 500px;
    line-height: 20px;
}
.MyProfileContul li i{
    width: 30px;
    height: 30px;
    font-size: 17px;
    background-color: #F1F1F9;
    color: #B4B4BB;
    border-radius: 50%;
    margin-right: 7px;
}
.MyProfileContul li{
    margin-bottom: 12px;
}
.MyProfileh6div{
    margin-bottom: 14px;
}
.MyProfileContul li a{
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #19191D;
    cursor: pointer;
}
.MyProfileInformation li{
    font-size: 14px;
    font-weight: 500;
    line-height: 33px;
    color: #0C2146;
}
.cardpart li{
    line-height: 40px;
}
.MyProfilecard i{
    font-size: 28px;
    color: #009CDE;
}
.PaymentAmount{
    color: #2979FF;
}
.MyProfileInformationimg img{
    width: 69px;
    height: 18px;
    object-fit: fill;
    margin-left: 10px;
}
.mousepointer{
    cursor: pointer;
}

.button {
    background-color: #4CAF50;
    border: none;
    border-radius: 5px;
    color: white;
    padding: 5px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
  }

@media (max-width: 1250px) and (min-width: 1100px){
    .MyProfileOverAll{
        width: 980px;
    }
    .MyProfileImg{
        margin-right: 25px!important;
    }
    .MyProfileImg img{
        width: 150px;
        height: 150px;
    }
    .MyProfileImgEdit{
        width: 50px;
        height: 50px;
        bottom: -3px;
        right: 15px;
    }
    .MyProfileImgEdit i{
        font-size: 17px;
    }
    .MyProfileA a{
        font-size: 11px!important;
    }
    .MyProfileSpan span{
        font-size: 13px!important;
    }
    .MyProfileAccordionbutton span{
        font-size: 13px!important;
    }
    .MyProfileAccordionUL li a{
        font-size: 13px!important;
    }
}
@media (max-width: 1100px) and (min-width: 992px){
    .MyProfileOverAll{
        width: 900px;
    }
    .MyProfileSpan span{
        font-size: 14px!important;
    }
    .MyProfileImg{
        margin-right: 25px!important;
    }
    .MyProfileImg img{
        width: 130px;
        height: 130px;
    }
    .MyProfileImgEdit{
        width: 40px;
        height: 40px;
        bottom: 16px;
        right: 18px;
    }
    .MyProfileImgEdit i{
        font-size: 15px;
    }
    .MyProfileLeft{
        margin-right: 22px!important;
    } 
    .MyProfileA a{
        font-size: 10px!important;
    }
    .MyProfileSpan span{
        font-size: 12px!important;
    }
    .MyProfileAccordionbutton span{
        font-size: 12px!important;
    }
    .MyProfileAccordionUL li a{
        font-size: 12px!important;
        line-height: 32px!important;
    }
    .MyProfileSpan{
        line-height: 32px!important;
    }
    .MyProfileSpan span{
        margin-left: 12px!important;
    }
    .MyProfileA{
        margin-right: 12px!important;
    }  
}
@media (max-width: 992px) and (min-width: 850px){
    .MyProfileOverAll{
        width: 770px;
    }
    .MyProfileLeft{
        margin-right: 0px!important;
    }
    .MyProfileAccordionoverall{
        margin-bottom: 30px!important;
    }
    .MyProfileImgEdit{
        width: 50px;
        height: 50px;
        bottom: 3px;
        right: 51px;
    }
    .MyProfileConth5 h5{
        font-size: 20px;
    }
}
@media (max-width: 850px) and (min-width: 750px){
    .MyProfileOverAll{
        width: 670px;
    }
    .MyProfileLeft{
        margin-right: 0px!important;
    }
    .MyProfileAccordionoverall{
        margin-bottom: 30px!important;
    }
    .MyProfileImgPart{
        padding: 20px 0px 0px 0px;
    }
    .MyProfileImg img{
        width: 160px;
        height: 160px;
    }
    .MyProfileImgEdit{
        width: 50px;
        height: 50px;
        bottom: 3px;
        right: 30px;
    }
    .MyProfileImg{
        margin-right: 20px!important;
    }
    .MyProfileConth5 h5{
        font-size: 20px;
    }
}
@media (max-width: 750px) and (min-width: 600px){
    .MyProfileOverAll{
        width: 550px;
    }
    .MyProfileLeft{
        margin-right: 0px!important;
    }
    .MyProfileAccordionoverall{
        margin-bottom: 30px!important;
    }
    .MyProfileImg img{
        width: 140px;
        height: 140px;
    }
    .MyProfileImgEdit{
        width: 45px;
        height: 45px;
        bottom: 3px;
        right: 20px;
    }
    .MyProfileImgEdit i{
        font-size: 17px!important;
    }
    .MyProfileImg img{
        width: 115px;
        height: 115px;
    }
    .MyProfileImg{
        margin-right: 20px!important;
    }
    .MyProfileConth5 h5{
        font-size: 17px;
    }
    .MyProfileh6 h6{
        font-size: 17px;
        line-height: 0px;
    }
    .MyProfileImgPart{
        padding: 10px 0px 0px 0px;
    }
    .MyProfilePictureSection{
        padding: 25px 25px 15px 25px;
    }
    .MyProfileInformation li{
        font-size: 13px;
    }
}
@media (max-width: 600px) and (min-width: 500px){
    .MyProfileOverAll{
        width: 450px;
    }
    .MyProfileLeft{
        margin-right: 0px!important;
    }
    .MyProfileAccordionoverall{
        margin-bottom: 30px!important;
    }
    .MyProfileImgEdit{
        width: 40px;
        height: 40px;
        bottom: 20px;
        right: 20px;
    }
    .MyProfileImgEdit i{
        font-size: 14px!important;
    }
    .MyProfileImg img{
        width: 110px;
        height: 110px;
    }
    .MyProfileImg{
        margin-right: 20px!important;
    }
    .MyProfileConth5 h5{
        font-size: 15px;
    }
    .MyProfileh6 h6{
        font-size: 15px;
        line-height: 0px;
    }
    .MyProfileImgPart{
        padding: 20px 0px 0px 0px;
    }
    .MyProfilePictureSection{
        padding: 20px 15px 10px 20px;
    }
    .MyProfileInformation li{
        font-size: 13px;
    }
    .MyProfileAccordionbutton span{
        font-size: 14px!important;
    }
    .MyProfileSpan span{
        font-size: 14px!important;
    }
    .MyProfileNameSection{
        margin-bottom: 5px!important;
    }
    .MyProfileContbutton i{
        font-size: 14px;
    }
    .MyProfileContbutton span{
        font-size: 13px;
    }
    .MyProfileContbutton button{
        padding: 2px 8px;
    }
    .MyProfileAccordionUL li a{
        font-size: 14px!important;
    }
}
@media (max-width: 500px) and (min-width: 400px){
    .MyProfileOverAll{
        width: 370px;
    }
    .MyProfileSpan{
        line-height:35px!important;
    }
    .MyProfileLeft{
        margin-right: 0px!important;
    }
    .MyProfileAccordionoverall{
        margin-bottom: 20px!important;
    }
    .MyProfileImgEdit{
        width: 35px;
        height: 35px;
        bottom: 20px;
        right: 10px;
    }
    .MyProfileImgEdit i{
        font-size: 13px!important;
    }
    .MyProfileImg img{
        width: 100px;
        height: 100px;
    }
    .MyProfileImg{
        margin-right: 15px!important;
    }
    .MyProfileConth5 h5{
        font-size: 15px;
    }
    .MyProfileh6 h6{
        font-size: 15px;
        line-height: 0px;
    }
    .MyProfileImgPart{
        padding: 10px 0px 0px 0px;
    }
    .MyProfileInformation li{
        font-size: 13px;
    }
    .MyProfileAccordionbutton span{
        font-size: 14px!important;
    }
    .MyProfileSpan span{
        font-size: 14px!important;
    }
    .MyProfilePictureSection{
        padding: 20px 15px 5px 12px;
    }
    .MyProfileNameSection{
        margin-bottom: 5px!important;
    }
    .MyProfileContbutton i{
        font-size: 14px;
    }
    .MyProfileContbutton span{
        font-size: 13px;
    }
    .MyProfileContbutton button{
        padding: 2px 8px;
    }
    .MyProfileAccordionUL li a{
        font-size: 14px!important;
        line-height: 35px!important;
    }
    .MyProfileContul li a{
        font-size: 13px;
    }
}
@media (max-width: 400px) and (min-width: 350px){
    .MyProfileOverAll{
        width: 320px;
    }
    .MyProfileSpan{
        line-height: 26px!important;
    }
    .MyProfileLeft{
        margin-right: 0px!important;
    }
    .MyProfileAccordionoverall{
        margin-bottom: 20px!important;
    }
    .MyProfileImgEdit{
        width: 25px;
        height: 25px;
        bottom: 37px;
        right: 17px;
    }
    .MyProfileImgEdit i{
        font-size: 10px!important;
    }
    .MyProfileImg img{
        width: 75px;
        height: 75px;
    }
    .MyProfileImg{
        margin-right: 15px!important;
    }
    .MyProfileConth5 h5{
        font-size: 15px;
    }
    .MyProfileh6 h6{
        font-size: 15px;
        line-height: 0px;
    }
    .MyProfileImgPart{
        padding: 10px 0px 0px 0px;
    }
    .MyProfileInformation li{
        font-size: 11px;
    }
    .MyProfileAccordionbutton span{
        font-size: 13px!important;
    }
    .MyProfileSpan span{
        font-size: 13px!important;
    }
    .MyProfilePictureSection{
        padding: 20px 15px 5px 12px;
    }
    .MyProfileNameSection{
        margin-bottom: 5px!important;
    }
    .MyProfileContbutton i{
        font-size: 13px;
    }
    .MyProfileContbutton span{
        font-size: 12px;
    }
    .MyProfileContbutton button{
        padding: 1px 7px;
    }
    .MyProfileAccordionUL li a{
        font-size: 13px!important;
        line-height: 30px!important;
    }
    .MyProfileContul li a{
        font-size: 12px;
    }
    .MyProfileContul li{
        margin-bottom: 7px;
    }
    .MyProfileA a{
        font-size: 12px!important;
    }
}
@media (max-width: 350px) and (min-width: 275px){
    .MyProfileOverAll{
        width: 270px;
    }
    .MyProfileContul li i{
        width: 25px;
        height: 25px;
        font-size: 14px;
    }
    .MyProfileSpan{
        line-height: 26px!important;
    }
    .MyProfileLeft{
        margin-right: 0px!important;
    }
    .MyProfileAccordionoverall{
        margin-bottom: 20px!important;
    }
    .MyProfileImgEdit{
        width: 25px;
        height: 25px;
        bottom: 30px;
        right: 14px;
    }
    .MyProfileImgEdit i{
        font-size: 9px!important;
    }
    .MyProfileImg img{
        width: 60px;
        height: 60px;
    }
    .MyProfileImg{
        margin-right: 10px!important;
    }
    .MyProfileConth5 h5{
        font-size: 13px;
    }
    .MyProfileh6 h6{
        font-size: 13px!important;
        line-height: 0px;
    }
    .MyProfileImgPart{
        padding: 10px 0px 0px 0px;
    }
    .MyProfileInformation li{
        font-size: 9px;
        line-height: 28px;
    }
    .MyProfileAccordionbutton span{
        font-size: 11px!important;
    }
    .MyProfileSpan span{
        font-size: 11px!important;
    }
    .MyProfilePictureSection{
        padding: 15px 12px 5px 12px;
    }
    .MyProfileNameSection{
        margin-bottom: 5px!important;
    }
    .MyProfileContbutton i{
        font-size: 10px;
    }
    .MyProfileContbutton span{
        font-size: 10px;
    }
    .MyProfileContbutton button{
        padding: 0px 7px;
    }
    .MyProfileAccordionUL li a{
        font-size: 12px!important;
        line-height: 27px!important;
    }
    .MyProfileContul li a{
        font-size: 10px;
    }
    .MyProfileContul li{
        margin-bottom: 5px!important;
    }
    .MyProfileA a{
        font-size: 10px!important;
    }
    .MyProfilecard i{
        font-size: 22px;
    }
}
@media (max-width: 275px){
    .MyProfileOverAll{
        width: 270px;
    }
    .MyProfileContul li i{
        width: 25px;
        height: 25px;
        font-size: 14px;
    }
    .MyProfileSpan{
        line-height: 26px!important;
    }
    .MyProfileLeft{
        margin-right: 0px!important;
    }
    .MyProfileAccordionoverall{
        margin-bottom: 20px!important;
    }
    .MyProfileImgEdit{
        width: 25px;
        height: 25px;
        bottom: 30px;
        right: 14px;
    }
    .MyProfileImgEdit i{
        font-size: 9px!important;
    }
    .MyProfileImg img{
        width: 60px;
        height: 60px;
    }
    .MyProfileImg{
        margin-right: 10px!important;
    }
    .MyProfileConth5 h5{
        font-size: 13px;
    }
    .MyProfileh6 h6{
        font-size: 13px;
        line-height: 0px;
    }
    .MyProfileImgPart{
        padding: 10px 0px 0px 0px;
    }
    .MyProfileInformation li{
        font-size: 9px;
        line-height: 28px;
    }
    .MyProfileAccordionbutton span{
        font-size: 11px!important;
    }
    .MyProfileSpan span{
        font-size: 11px!important;
    }
    .MyProfilePictureSection{
        padding: 15px 12px 5px 12px;
    }
    .MyProfileNameSection{
        margin-bottom: 5px!important;
    }
    .MyProfileContbutton i{
        font-size: 10px;
    }
    .MyProfileContbutton span{
        font-size: 10px;
    }
    .MyProfileContbutton button{
        padding: 0px 7px;
    }
    .MyProfileAccordionUL li a{
        font-size: 12px!important;
        line-height: 27px!important;
    }
    .MyProfileContul li a{
        font-size: 10px;
    }
    .MyProfileContul li{
        margin-bottom: 5px!important;
    }
    .MyProfileA a{
        font-size: 10px!important;
    }
    .MyProfilecard i{
        font-size: 22px;
    }
}
.GateKeeperOverall{
    width: 1156px;
    margin: 0 auto;
}
.GateKeeperImage img{
    width: 212px;
    height: 212px;
    object-fit: cover;
    border-radius: 50%;
}
.GateKeeperImage{
    margin-right: 24px;
}
.GateKeeperImgSection{
    margin-bottom: 8px;
}
.GateKeeperImgSectionName h5{
    font-size: 24px;
    line-height: 36px;
    font-weight: 700;
    color: #19191D;
}
.GateKeeperImgSectionName{
    margin-bottom: 24px;
}
.GateKeeperBlockSpan span{
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #463209;
}
.GateKeeperBlockIcon{
    font-size: 16px;
    color: #B88217;
    margin-right: 7px;
}
.GateKeeperBlockIcon i{
    padding: 6px;
    border-radius: 50%;
    background-color: #FFEFD1;
}
.GateKeeperImgSectionIconsBG i{
    background-color: #EBF2FF!important;
    color: #2264D1!important;
}
.GateKeeperImgSectionIcons li i{
    width: 30px;
    height: 30px;
    font-size: 17px;
    background-color: #F1F1F9;
    color: #4A4B57;
    border-radius: 50%;
    margin-right: 7px;
}
.GateKeeperImgSectionIcons li{
    margin-bottom: 12px;
}
.GateKeeperImgSectionIcons li a{
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #19191D;
    cursor: pointer;
}
.GateKeeperNumsBlue{
    border-bottom: 4px solid #2264D1;
}
.GateKeeperUniversesBlue span{
    color: #0C2146!important;
}
.GateKeeperNums{
    padding: 0px 12px;
}
.GateKeeperA{
    margin-right: 8px!important;
}
.GateKeeperA a{
    padding: 1px 7px!important;
    background-color: #EDEDF0!important;
    font-size: 14px!important;
    font-weight: 500!important;
    line-height: 20px!important;
    color: #19191D!important;
    border-radius: 10px!important;
}
.GateKeeperAbg a{
    background-color: #4A4B57!important;
    color: #F7F7FA!important;
}
.GateKeeperUniverses span{
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #4A4B57;
}
.universeButton{
    padding: 0px 15px 5px 15px!important;
    color: #4A4B57!important;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}
.universeButton.active{
    color: #0C2146!important;
    outline: none;
    background: none!important;
    border-bottom: 4px solid #2264D1!important;
    border-radius: 3px!important;
}
.GateKeeperBoxSection{
    padding: 24px;
    background-color: #F7F7FA;
    border-radius: 10px;
}
.GateKeeperBoxSectionName h6{
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    color: #19191D;
}
.GateKeeperBoxSectionSubName span{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #787885;
}
.GateKeeperBoxSectionSubName{
    margin-bottom: 24px;
}
.GateKeeperBoxBorder{
    padding-bottom: 20px;
    border-bottom: 2px solid #E1E1E3;
}
.HomeBrowseContributionsDots i{
    width: 100%;
    display: flex;
    justify-content: center;
    height: 20px;    
    background-color: #E1E1E3;
    font-size: 24px;
    border-radius: 4px;
    color: #5A5B6A;
    margin-bottom: 10px;
}
.HomeBrowseContributionsSmallDots i{
    width: 32px;
    display: flex;
    justify-content: center;
    height: 22px;    
    background-color: #E1E1E3;
    font-size: 24px;
    border-radius: 4px;
    color: #5A5B6A;
}
.GateKeeperBox{
    width: 100%!important;
    background-color: #ECF7ED;
    border: solid 2px #A9D3AB;
    border-radius: 10px;
    padding: 12px 16px;
    box-shadow: -1px 2px #A9D3AB;
}
.GateKeeperBoxPara p {
    color: #2B662E;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 1px;
}
.GateKeeperBoxh6 h6 {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    color: #132C14;
}
.GateKeeperBoxSpan{
    width: auto;
    background-color: #fff;
    border-radius: 25px;
    float: left;
}
.GateKeeperBoxSpan span {
    padding: 4px 10px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    display: flex;
    align-items: center;
}
.GateKeeperBoxSpan i {
    font-size: 20px;
    margin-right: 13px;
}
.GateKeeperthumbsup span {
    color: #4F91FF;
}
.GateKeeperthumbsup i {
    color: #9DC2FF;
}
.GateKeeperuser span {
    color: #EF6292;
}
.GateKeeperuser i {
    color: #EF6292;
}
.GateKeeperrandom span {
    color: #FB982E;
}
.GateKeeperrandom i {
    color: #FDC68B;
}
.GateKeeperBoxTwo{
    padding-top: 22px;
}
.GateKeeperContributionBoxSection{
    padding: 24px 0px;
    background-color: #F7F7FA;
    border-radius: 10px;
}
.GateKeeperContributionBox{
    width: 95%!important;
    background-color: #ECF7ED;
    border: solid 2px #A9D3AB;
    border-radius: 10px;
    padding: 12px 16px;
    box-shadow: -1px 2px #A9D3AB;
    margin-bottom: 15px;
}
.ContributionUniHead span{
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #3A3A44;
}
.ContributionPara p {
    color: #2B662E;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 1px;
}
.ContributionNumber{
    margin-bottom: 12px;
}
.ContributionNumber span{
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #9696A0;
}
.ContributionImage{
    width: auto;
    background-color: #fff;
    border-radius: 25px;
    float: left;
    margin-bottom: 10px;
}
.ContributionImage img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    object-fit: cover;
}
.ContributionImage span {
    padding: 4px 10px;
    color: #65B168;
    display: flex;
    align-items: center;
}
.ContributionGuarding{
    width: auto;
    background-color: #EBF2FF;
    border-radius: 25px;
    float: left;
    margin-bottom: 10px;
}
.ContributionGuarding span {
    padding: 4px 10px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #2264D1;
    display: flex;
    align-items: center;
}
.ContributionGuarding i {
    font-size: 25px;
    color: #9DC2FF;
    margin-right: 6px;
}
.GateKeeperContributionBoxBorder{
    padding: 10px;
    padding-bottom: 20px;
    border-bottom: 2px solid #E1E1E3;
}
.ContributionMaster{
    width: auto;
    background-color: #FEE8E7;
    border-radius: 25px;
    float: left;
    margin-bottom: 10px;
}
.ContributionMaster span {
    padding: 4px 10px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #C8372D;
    display: flex;
    align-items: center;
}
.ContributionMaster i {
    font-size: 20px;
    color: #FAA9A3;
    margin-right: 6px;
}
.GateKeeperIconsSection{
    margin-bottom: 14px;
}
.GateKeeperTwoIcon i{
    padding: 4px 10px;
    background-color: #fff;
    border-radius: 20px;
    font-size: 20px;
    color: #787885;
}
.unviersealert{
    padding-top: 10px;
}

@media (max-width:1300px) and (min-width:1100px) {
    .GateKeeperOverall{
        width: 1000px;
    }
    .GateKeeperImage img{
        width: 175px;
        height: 175px;
    }
}
@media (max-width:1100px) and (min-width:1000px) {
    .GateKeeperOverall{
        width: 900px;
    }
    .GateKeeperImage img{
        width: 175px;
        height: 175px;
    }
}
@media (max-width:1000px) and (min-width:850px) {
    .GateKeeperOverall{
        width: 790px;
    }
    .GateKeeperImage img{
        width: 175px;
        height: 175px;
    }
    .GateKeeperImage{
        margin-right: 20px;
    }
    .GateKeeperImgSectionName{
        margin-bottom: 17px;
    }
}
@media (max-width:850px) and (min-width:767px) {
    .GateKeeperOverall{
        width: 680px;
    }
    .GateKeeperImage img{
        width: 155px;
        height: 155px;
    }
    .GateKeeperImage{
        margin-right: 15px;
    }
    .GateKeeperImgSectionName h5{
        font-size: 22px;
    }
    .GateKeeperImgSectionName{
        margin-bottom: 12px;
    }
    .universeButton{
        padding: 0px 12px 5px 12px!important;
        font-size: 15px;   
    }
}
@media (max-width:767px) and (min-width:650px) {
    .GateKeeperOverall{
        width: 590px;
    }
    .GateKeeperImage img{
        width: 155px;
        height: 155px;
    }
    .GateKeeperImage{
        margin-right: 15px;
    }
    .GateKeeperImgSectionName h5{
        font-size: 20px;
    }
    .GateKeeperBoxh6 h6{
        font-size: 18px;
    }
    .GateKeeperImgSectionName{
        margin-bottom: 10px;
    }
    .GateKeeperBlockSpan span{
        font-size: 16px;
    }
    .GateKeeperBlockIcon{
        font-size: 15px;
        margin-right: 7px;
    }
    .GateKeeperBlockIcon i{
        padding: 6px;
    }
    .GateKeeperBoxSpan span {
        padding: 4px 10px;
        font-size: 12px;
    }
    .GateKeeperBoxSpan i {
        font-size: 18px;
        margin-right: 8px;
    }
    .GateKeeperImgSectionIcons li a{
        font-size: 16px;
    }
    .universeButton{
        padding: 0px 6px 5px 6px!important;
        font-size: 15px;   
    }
    .GateKeeperBoxSectionSubName{
        margin-bottom: 18px;
    }
}
@media (max-width:650px) and (min-width:550px) {
    .GateKeeperOverall{
        width: 500px;
    }
    .GateKeeperImage img{
        width: 140px;
        height: 140px;
    }
    .GateKeeperImage{
        margin-right: 15px;
    }
    .GateKeeperImgSectionName h5{
        font-size: 20px;
    }
    .GateKeeperBoxh6 h6{
        font-size: 18px;
    }
    .GateKeeperImgSectionName{
        margin-bottom: 10px;
    }
    .GateKeeperBlockSpan span{
        font-size: 15px;
    }
    .GateKeeperBlockIcon{
        font-size: 15px;
        margin-right: 7px;
    }
    .GateKeeperBlockIcon i{
        padding: 6px;
    }
    .GateKeeperBoxSpan span {
        padding: 4px 10px;
        font-size: 12px;
    }
    .GateKeeperBoxSpan i {
        font-size: 18px;
        margin-right: 8px;
    }
    .GateKeeperImgSectionIcons li a{
        font-size: 15px;
    }
    .universeButton{
        padding: 0px 15px 5px 15px!important;
        font-size: 14px;   
    }
    .GateKeeperA a{
        padding: 1px 7px!important;
        font-size: 13px!important;
    }
    .GateKeeperBoxSectionSubName{
        margin-bottom: 18px;
    }
}
@media (max-width:550px) and (min-width:450px) {
    .GateKeeperOverall{
        width: 400px;
    }
    .GateKeeperImage img{
        width: 130px;
        height: 130px;
    }
    .GateKeeperImage{
        margin-right: 15px;
    }
    .GateKeeperImgSectionName h5{
        font-size: 20px;
    }
    .GateKeeperBoxh6 h6{
        font-size: 18px;
    }
    .GateKeeperImgSectionName{
        margin-bottom: 10px;
    }
    .GateKeeperBlockSpan span{
        font-size: 13px;
    }
    .GateKeeperImgSectionIcons li a{
        font-size: 13px;
    }
    .GateKeeperBlockIcon{
        font-size: 15px;
        margin-right: 7px;
    }
    .GateKeeperBlockIcon i{
        padding: 6px;
    }
    .GateKeeperBoxSpan span {
        padding: 4px 10px;
        font-size: 12px;
    }
    .GateKeeperBoxSpan i {
        font-size: 18px;
        margin-right: 8px;
    }
    .universeButton{
        padding: 0px 10px 5px 10px!important;
        font-size: 14px;   
    }
    .GateKeeperA a{
        padding: 1px 7px!important;
        font-size: 11px!important;
    }
    .GateKeeperBoxSectionName h6{
        font-size: 18px;
    }
    .GateKeeperBoxSectionSubName span{
        font-size: 15px;
    }  
    .GateKeeperBoxSectionSubName{
        margin-bottom: 15px;
    }  
}
@media (max-width:450px) and (min-width:400px) {
    .GateKeeperOverall{
        width: 360px;
    }
    .GateKeeperImage img{
        width: 100px;
        height: 100px;
    }
    .GateKeeperImage{
        margin-right: 15px;
    }
    .GateKeeperImgSectionName h5{
        font-size: 20px;
    }
    .GateKeeperBoxh6 h6{
        font-size: 18px;
    }
    .GateKeeperImgSectionName{
        margin-bottom: 7px;
    }
    .GateKeeperBlockSpan span{
        font-size: 13px;
    }
    .GateKeeperImgSectionIcons li a{
        font-size: 13px;
    }
    .GateKeeperBlockIcon{
        font-size: 15px;
        margin-right: 7px;
    }
    .GateKeeperBlockIcon i{
        padding: 6px;
    }
    .GateKeeperBoxSpan span {
        padding: 4px 10px;
        font-size: 12px;
    }
    .GateKeeperBoxSpan i {
        font-size: 18px;
        margin-right: 8px;
    }
    .universeButton{
        padding: 0px 8px 0px 8px!important;
        font-size: 12px;   
    }
    .GateKeeperA a{
        padding: 1px 4px!important;
        font-size: 10px!important;
    }
    .GateKeeperA{
        margin-right: 3px!important;
    }
    .GateKeeperBoxSectionName h6{
        font-size: 18px;
    }
    .GateKeeperBoxSectionSubName span{
        font-size: 15px;
    }
    .GateKeeperImgSectionIcons li{
        margin-bottom: 8px;
    }
    .GateKeeperBoxSectionSubName {
        margin-bottom: 9px;
    }
    .GateKeeperBoxSection {
        padding: 15px;
    }
}
@media (max-width:400px) and (min-width:350px) {
    .GateKeeperOverall{
        width: 310px;
    }
    .GateKeeperImage img{
        width: 90px;
        height: 90px;
    }
    .GateKeeperImage{
        margin-right: 15px;
    }
    .GateKeeperImgSectionName h5{
        font-size: 20px;
    }
    .GateKeeperBoxh6 h6{
        font-size: 18px;
    }
    .GateKeeperImgSectionName{
        margin-bottom: 4px;
    }
    .GateKeeperBlockSpan span{
        font-size: 13px;
    }
    .GateKeeperImgSectionIcons li a{
        font-size: 13px;
    }
    .GateKeeperBlockIcon{
        font-size: 15px;
        margin-right: 7px;
    }
    .GateKeeperBlockIcon i{
        padding: 6px;
    }
    .GateKeeperBoxSpan span {
        padding: 4px 10px;
        font-size: 12px;
    }
    .GateKeeperBoxSpan i {
        font-size: 18px;
        margin-right: 8px;
    }
    .universeButton{
        padding: 0px 8px 0px 8px!important;
        font-size: 12px;   
    }
    .GateKeeperA a{
        padding: 1px 4px!important;
        font-size: 10px!important;
    }
    .GateKeeperA{
        margin-right: 3px!important;
    }
    .GateKeeperBoxSectionName h6{
        font-size: 18px;
    }
    .GateKeeperBoxSectionSubName span{
        font-size: 15px;
    }
    .GateKeeperImgSectionIcons li{
        margin-bottom: 8px;
    }
    .GateKeeperBoxSectionSubName {
        margin-bottom: 9px;
    }
    .GateKeeperBoxSection {
        padding: 15px;
    }
}
@media (max-width:350px) and (min-width:315px) {
    .GateKeeperOverall{
        width: 300px;
    }
    .GateKeeperImage img{
        width: 80px;
        height: 80px;
    }
    .GateKeeperImage{
        margin-right: 15px;
    }
    .GateKeeperImgSectionName h5{
        font-size: 20px;
    }
    .GateKeeperBoxh6 h6{
        font-size: 18px;
    }
    .GateKeeperImgSectionName{
        margin-bottom: 4px;
    }
    .GateKeeperBlockSpan span{
        font-size: 13px;
    }
    .GateKeeperImgSectionIcons li a{
        font-size: 13px;
    }
    .GateKeeperBlockIcon{
        font-size: 15px;
        margin-right: 7px;
    }
    .GateKeeperBlockIcon i{
        padding: 6px;
    }
    .GateKeeperBoxSpan span {
        padding: 3px 7px;
        font-size: 10px;
    }
    .GateKeeperBoxSpan i {
        font-size: 15px;
        margin-right: 8px;
    }
    .universeButton{
        padding: 0px 8px 0px 8px!important;
        font-size: 12px;   
    }
    .GateKeeperA a{
        padding: 1px 4px!important;
        font-size: 10px!important;
    }
    .GateKeeperA{
        margin-right: 3px!important;
    }
    .GateKeeperBoxSectionName h6{
        font-size: 18px;
    }
    .GateKeeperBoxSectionSubName span{
        font-size: 15px;
    }
    .GateKeeperImgSectionIcons li{
        margin-bottom: 8px;
    }
    .GateKeeperBoxSectionSubName {
        margin-bottom: 9px;
    }
    .GateKeeperBoxSection {
        padding: 15px;
    }
}
@media (max-width:315px) and (min-width:275px) {
    .GateKeeperOverall{
        width: 260px;
    }
    .GateKeeperImage img{
        width: 80px;
        height: 80px;
    }
    .GateKeeperImage{
        margin-right: 15px;
    }
    .GateKeeperImgSectionName h5{
        font-size: 20px;
    }
    .GateKeeperBoxh6 h6{
        font-size: 18px;
    }
    .GateKeeperImgSectionName{
        margin-bottom: 4px;
    }
    .GateKeeperBlockSpan span{
        font-size: 12px;
    }
    .GateKeeperImgSectionIcons li a{
        font-size: 12px;
    }
    .GateKeeperBlockIcon{
        font-size: 15px;
        margin-right: 7px;
    }
    .GateKeeperBlockIcon i{
        padding: 6px;
    }
    .GateKeeperBoxSpan span {
        padding: 3px 7px;
        font-size: 10px;
    }
    .GateKeeperBoxSpan i {
        font-size: 15px;
        margin-right: 8px;
    }
    .universeButton{
        padding: 0px 3px 0px 3px!important;
        font-size: 11px;   
    }
    .GateKeeperA a{
        padding: 1px 4px!important;
        font-size: 10px!important;
    }
    .GateKeeperA{
        margin-right: 3px!important;
    }
    .GateKeeperBoxSectionName h6{
        font-size: 18px;
    }
    .GateKeeperBoxSectionSubName span{
        font-size: 13px;
    }
    .GateKeeperImgSectionIcons li{
        margin-bottom: 8px;
    }
    .GateKeeperBoxSectionSubName {
        margin-bottom: 9px;
    }
    .GateKeeperBoxSection {
        padding: 15px;
    }
    .GateKeeperBoxPara p{
        font-size: 13px;
    }
}
@media (max-width:275px){
    .GateKeeperOverall{
        width: 260px;
    }
    .GateKeeperImage img{
        width: 70px;
        height: 70px;
    }
    .GateKeeperImage{
        margin-right: 15px;
    }
    .GateKeeperImgSectionName h5{
        font-size: 20px;
    }
    .GateKeeperBoxh6 h6{
        font-size: 18px;
    }
    .GateKeeperImgSectionName{
        margin-bottom: 4px;
    }
    .GateKeeperBlockSpan span{
        font-size: 12px;
    }
    .GateKeeperImgSectionIcons li a{
        font-size: 12px;
    }
    .GateKeeperBlockIcon{
        font-size: 15px;
        margin-right: 7px;
    }
    .GateKeeperBlockIcon i{
        padding: 6px;
    }
    .GateKeeperBoxSpan span {
        padding: 3px 7px;
        font-size: 10px;
    }
    .GateKeeperBoxSpan i {
        font-size: 15px;
        margin-right: 8px;
    }
    .universeButton{
        padding: 0px 3px 0px 3px!important;
        font-size: 11px;   
    }
    .GateKeeperA a{
        padding: 1px 4px!important;
        font-size: 10px!important;
    }
    .GateKeeperA{
        margin-right: 3px!important;
    }
    .GateKeeperBoxSectionName h6{
        font-size: 18px;
    }
    .GateKeeperBoxSectionSubName span{
        font-size: 13px;
    }
    .GateKeeperImgSectionIcons li{
        margin-bottom: 8px;
    }
    .GateKeeperBoxSectionSubName {
        margin-bottom: 9px;
    }
    .GateKeeperBoxSection {
        padding: 15px;
    }
    .GateKeeperBoxPara p{
        font-size: 13px;
    }
}
.buttonalign{
    display: flex;
    justify-content: space-between;
}
.btn {
    color: #fff;
    background-color: #0b5ed7;
}
.right-align{
    float: right;
}


.react-switch-checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
  }
  
  .react-switch-label {
    /* display: flex; */
    margin-right: 5px;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 35px;
    height: 20px;
    background: grey;
    border-radius: 100px;
    position: relative;
    transition: background-color .2s;
  }
  
  .react-switch-label .react-switch-button {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    width: 15px;
    height: 18px;
    border-radius: 45px;
    transition: 0.2s;
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  }
  
  .react-switch-checkbox:checked + .react-switch-label .react-switch-button {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }
  
  .react-switch-label:active .react-switch-button {
    width: 11px;
  }

  input.larger {
    width: 50px;
    height: 50px;
  }

  #ck-button {
    margin:4px;
    background-color:#EFEFEF;
    border-radius:4px;
    border:1px solid #D0D0D0;
    overflow:auto;
    float:left;
}

#ck-button:hover {
    background:red;
}

#ck-button label {
    float:left;
    width:4.0em;
}

#ck-button label span {
    text-align:center;
    padding:3px 0px;
    display:block;
}

#ck-button label input {
    position:absolute;
    top:-20px;
}

#ck-button input:checked + span {
    background-color:#911;
    color:#fff;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }