.Headeroverall{
    width: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    box-shadow: 0px 2px #f2f2f2;
    background-color: #fff;
    border-radius: 12px;
    position: fixed;    
    min-height: 72px;
}
.optionbar span{
    font-size: 14px!important;
}
.optionbar{
    width: 30px!important;
    height: 30px!important;
    margin-left: 30px!important;
    padding: 3px!important;    
}
.Headerleft .navbar-toggler{
    border-color: transparent;
    border: none!important;
}
.optionbar:focus, .optionbar:focus-visible{
    outline: none!important;
    border: none!important;
    box-shadow: none!important;
}
.plluny{
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #F285AA;    
}
.plluny a{
    font-weight: 700;
    font-size: 20px;
    left: 8px;
    top: 2px;
    color: #fff!important;
}
.Headerrightimg img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 20px;
    cursor: pointer;
}
.Headerleft h6{
    font-size: 20px;
    font-weight: 700;
    color: #19191D;
    cursor: pointer;    
}
.Headerrighth6{
    font-size: 16px;
    font-weight: 400;
    margin-right: 12px;
    cursor: pointer;
    color: #19191D;
}
.Headercenter ul li{
    display: flex!important;
    align-items: center!important;
    justify-content: center!important;
}
.Headercenterlibg{    
    border-radius: 3px;
    background-color: #EDEDF0!important;    
}
.active{
    border-radius: 3px;
    background-color: #EDEDF0!important;
}
.Headerbutton{
    cursor: pointer;
}
.Headercenter ul li a{
    font-size: 16px!important;
    font-weight: 400!important;
    padding: 8px 16px!important;
    border-radius: 3px;
    color: #787885!important;
}
.Headercenter ul li{
    cursor: pointer!important;
}
.upgratespan span{
    font-size: 14px;
    font-weight: 700;
    padding: 12px 16px;
    background-color: #4F91FF;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
}
.iconClass{
    position: relative;
  }
  .iconClass span{
    position: absolute;
    top: 0px;
    right: 0px;
    display: block;
  }

@media (max-width: 1111px) and (min-width: 992px){
    .Headercenter ul li a{
        font-size: 14px!important;
    }
    .Headerrighth6 h6{
        font-size: 15px;
    }
    .upgratespan span{
        font-size: 12px;
        padding: 10px 10px;
    }
    .Headerrightimg{
        margin-right: -5px;
    }
}   
@media (max-width: 991px) and (min-width: 350px){
    .Headerrighth6 h6{
        font-size: 14px;
    }
    .Headerrightimg img{
        width: 35px;
        height: 35px;
    }
    .Headerleft h6{
        font-size: 15px;
    }
    .Headerrightimg{
        margin-right: -40px;
    }
}
@media (max-width: 350px) and (min-width: 270px){
    .Headeroverall{
        min-height: 40px;
    }
    .Headerrighth6 h6{
        font-size: 12px;
    }
    .Headerrightimg img{
        width: 25px;
        height: 25px;
    }
    .Headerleft h6{
        font-size: 12px;
    }
    .Headerrightimg{
        margin-right: -40px;
    }
    .Headercenter ul li a{
        font-size: 10px!important;
        padding: 5px 12px!important;
    }
    .upgratespan span{
        font-size: 10px;
        padding: 8px 12px;
    }
    .plluny{
        width: 20px;
        height: 20px; 
    }
    .plluny a{
        font-size: 14px;
        left: 5px;
        top: 1px;
    }
    .optionbar{
        width: 20px!important;
        height: 20px!important;
        margin-left: 30px!important;
        padding: 0px!important;
    }
    .optionbar span{
        font-size: 12px!important;
    }
}
@media (max-width: 270px){
    .Headeroverall{
        min-height: 40px;
    }
    .Headerrighth6 h6{
        font-size: 8px;
        margin-right: -5px;
    }
    .Headerrightimg img{
        width: 20px;
        height: 20px;
    }
    .Headerleft h6{
        font-size: 8px;
    }
    .Headerrightimg{
        margin-right: -40px;
    }
    .Headercenter ul li a{
        font-size: 8px!important;
        padding: 4px 10px!important;
    }
    .upgratespan span{
        font-size: 8px;
        padding: 6px 9px;
    }
    .plluny{
        width: 17px;
        height: 17px;
    }
    .plluny a{
        font-size: 13px;
        left: 4px;
        top: 0px;
    }
    .optionbar{
        width: 20px!important;
        height: 20px!important;
        margin-left: 30px!important;
        padding: 0px!important;
    }
    .optionbar span{
        font-size: 12px!important;
    }
}


















