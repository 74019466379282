.PrivateTabOverall{
    width: 1156px;
    margin: 0 auto;
    margin-top: 100px;
    margin-bottom: 60px;
}
.GateKeeperImage img{
    width: 212px;
    height: 212px;
    object-fit: cover;
    border-radius: 50%;
}
.GateKeeperImage{
    margin-right: 24px;
}
.GateKeeperImgSection{
    margin-bottom: 8px;
}
.GateKeeperImgSectionName h5{
    font-size: 24px;
    line-height: 36px;
    font-weight: 700;
    color: #19191D;
}
.GateKeeperImgSectionName{
    margin-bottom: 24px;
}
.GateKeeperBlockSpan span{
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #463209;
}
.GateKeeperBlockIcon{
    font-size: 16px;
    color: #B88217;
    margin-right: 7px;
}
.GateKeeperBlockIcon i{
    padding: 6px;
    border-radius: 50%;
    background-color: #FFEFD1;
}
.GateKeeperImgSectionIconsBG i{
    background-color: #EBF2FF!important;
    color: #2264D1!important;
}
.GateKeeperImgSectionIcons li i{
    width: 30px;
    height: 30px;
    font-size: 17px;
    background-color: #F1F1F9;
    color: #4A4B57;
    border-radius: 50%;
    margin-right: 7px;
}
.GateKeeperImgSectionIcons li{
    margin-bottom: 12px;
}
.GateKeeperImgSectionIcons li a{
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #19191D;
    cursor: pointer;
}
.GateKeeperNumsBlue{
    border-bottom: 4px solid #2264D1;
}
.GateKeeperUniversesBlue span{
    color: #0C2146!important;
}
.GateKeeperNums{
    padding: 0px 12px;
}
.PrivateTabA{
    margin-right: 8px!important;
}
.PrivateTabA a{
    padding: 1px 7px!important;
    background-color: #EDEDF0!important;
    font-size: 14px!important;
    font-weight: 500!important;
    line-height: 20px!important;
    color: #19191D!important;
    border-radius: 10px!important;
}
.PrivateTabAbg a{
    background-color: #4A4B57!important;
    color: #F7F7FA!important;
}
.GateKeeperUniverses span{
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #4A4B57;
}
.PrivateTabButton{
    padding: 0px 15px 5px 15px!important;
    color: #4A4B57!important;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;

}
.PrivateTabButton.active{
    color: #0C2146!important;
    background: none!important;
    border-bottom: 4px solid #2264D1!important;
    border-radius: 3px!important;
}
.GateKeeperBoxSection{
    padding: 24px;
    background-color: #F7F7FA;
    border-radius: 10px;
}
.GateKeeperBoxSectionName h6{
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    color: #19191D;
}
.GateKeeperBoxSectionSubName span{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #787885;
}
.GateKeeperBoxSectionSubName{
    margin-bottom: 24px;
}
.GateKeeperBox{
    width: 100%!important;
    background-color: #ECF7ED;
    border: solid 2px #A9D3AB;
    border-radius: 10px;
    padding: 12px 16px;
    box-shadow: -1px 2px #A9D3AB;
}
.GateKeeperBoxBorder{
    padding-bottom: 20px;
    border-bottom: 2px solid #E1E1E3;
}
.GateKeeperBoxPara p {
    color: #2B662E;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 1px;
}
.GateKeeperBoxh6 h6 {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    color: #132C14;
}
.GateKeeperBoxSpan{
    width: auto;
    background-color: #fff;
    border-radius: 25px;
    float: left;
}
.GateKeeperBoxSpan span {
    padding: 4px 10px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    display: flex;
    align-items: center;
}
.GateKeeperBoxSpan i {
    font-size: 20px;
    margin-right: 13px;
}
.GateKeeperthumbsup span {
    color: #4F91FF;
}
.GateKeeperthumbsup i {
    color: #9DC2FF;
}
.GateKeeperuser span {
    color: #EF6292;
}
.GateKeeperuser i {
    color: #EF6292;
}
.GateKeeperrandom span {
    color: #FB982E;
}
.GateKeeperrandom i {
    color: #FDC68B;
}
.GateKeeperBoxTwo{
    padding-top: 22px;
}
.PrivateTabTimeUpgradeSection{    
    margin-bottom: 8px;
    padding: 12px 16px;
    background-color: #FEF2F1;
    border-radius: 10px;
    box-shadow: 0px 2px #dcc9c8;
}
.PrivateTabTimeUpgradeLogo{
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #C8372D;
    margin-right: 10px;
    margin-top: 9px;
}
.PrivateTabTimeUpgradeLogo i{
    color: #F7F7FA;
    font-size: 19px;
    top: 3px;
    left: 7px;
}
.PrivateTabTimeUpgradeh5 h5{
    font-size: 24px;
    font-weight: 700;
    line-height: 40px;
    color: #43130F;
}
.PrivateTabTimeUpgradeP p{
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    color: #43130F;
    letter-spacing: 1px;
}
.PrivateTabupgradenowspan span{
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    padding: 12px 16px;
    background-color: #4F91FF;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
}

@media (max-width:1300px) and (min-width:1100px) {
    .PrivateTabOverall{
        width: 1000px;
    }
    .GateKeeperImage img{
        width: 175px;
        height: 175px;
    }
}
@media (max-width:1100px) and (min-width:1000px) {
    .PrivateTabOverall{
        width: 900px;
    }
    .GateKeeperImage img{
        width: 175px;
        height: 175px;
    }
}
@media (max-width:1000px) and (min-width:850px) {
    .PrivateTabOverall{
        width: 790px;
    }
    .GateKeeperImage img{
        width: 175px;
        height: 175px;
    }
    .GateKeeperImage{
        margin-right: 20px;
    }
    .GateKeeperImgSectionName{
        margin-bottom: 17px;
    }
    .PrivateTabButton{
        padding: 0px 10px 5px 10px!important;
        font-size: 15px;    
    }
}
@media (max-width:850px) and (min-width:767px) {
    .PrivateTabOverall{
        width: 680px;
    }
    .GateKeeperImage img{
        width: 155px;
        height: 155px;
    }
    .GateKeeperImage{
        margin-right: 15px;
    }
    .GateKeeperImgSectionName h5{
        font-size: 22px;
    }
    .GateKeeperImgSectionName{
        margin-bottom: 12px;
    }
    .PrivateTabButton{
        padding: 0px 5px 5px 5px!important;
        font-size: 14px;    
    }
    .PrivateTabA{
        margin-right: 3px!important;
    }
    .PrivateTabA a{
        padding: 1px 7px!important;
        font-size: 13px!important;
    }
}
@media (max-width:767px) and (min-width:650px) {
    .PrivateTabOverall{
        width: 590px;
    }
    .GateKeeperImage img{
        width: 155px;
        height: 155px;
    }
    .GateKeeperImage{
        margin-right: 15px;
    }
    .GateKeeperImgSectionName h5{
        font-size: 20px;
    }
    .GateKeeperBoxh6 h6{
        font-size: 18px;
    }
    .GateKeeperImgSectionName{
        margin-bottom: 10px;
    }
    .GateKeeperBlockSpan span{
        font-size: 16px;
    }
    .GateKeeperBlockIcon{
        font-size: 15px;
        margin-right: 7px;
    }
    .GateKeeperBlockIcon i{
        padding: 6px;
    }
    .GateKeeperBoxSpan span {
        padding: 4px 10px;
        font-size: 12px;
    }
    .GateKeeperBoxSpan i {
        font-size: 18px;
        margin-right: 8px;
    }
    .GateKeeperImgSectionIcons li a{
        font-size: 16px;
    }
    .PrivateTabButton{
        padding: 0px 6px 5px 6px!important;
        font-size: 15px;   
    }
    .GateKeeperBoxSectionSubName{
        margin-bottom: 18px;
    }
}
@media (max-width:650px) and (min-width:550px) {
    .PrivateTabOverall{
        width: 500px;
    }
    .GateKeeperImage img{
        width: 140px;
        height: 140px;
    }
    .GateKeeperImage{
        margin-right: 15px;
    }
    .GateKeeperImgSectionName h5{
        font-size: 20px;
    }
    .GateKeeperBoxh6 h6{
        font-size: 18px;
    }
    .GateKeeperImgSectionName{
        margin-bottom: 10px;
    }
    .GateKeeperBlockSpan span{
        font-size: 15px;
    }
    .GateKeeperBlockIcon{
        font-size: 15px;
        margin-right: 7px;
    }
    .GateKeeperBlockIcon i{
        padding: 6px;
    }
    .GateKeeperBoxSpan span {
        padding: 4px 10px;
        font-size: 12px;
    }
    .GateKeeperBoxSpan i {
        font-size: 18px;
        margin-right: 8px;
    }
    .GateKeeperImgSectionIcons li a{
        font-size: 15px;
    }
    .PrivateTabButton{
        padding: 0px 15px 5px 15px!important;
        font-size: 14px;   
    }
    .PrivateTabA a{
        padding: 1px 7px!important;
        font-size: 13px!important;
    }
    .GateKeeperBoxSectionSubName{
        margin-bottom: 18px;
    }
}
@media (max-width:550px) and (min-width:450px) {
    .PrivateTabOverall{
        width: 400px;
    }
    .GateKeeperImage img{
        width: 130px;
        height: 130px;
    }
    .GateKeeperImage{
        margin-right: 15px;
    }
    .GateKeeperImgSectionName h5{
        font-size: 20px;
    }
    .GateKeeperBoxh6 h6{
        font-size: 18px;
    }
    .GateKeeperImgSectionName{
        margin-bottom: 10px;
    }
    .GateKeeperBlockSpan span{
        font-size: 13px;
    }
    .GateKeeperImgSectionIcons li a{
        font-size: 13px;
    }
    .GateKeeperBlockIcon{
        font-size: 15px;
        margin-right: 7px;
    }
    .GateKeeperBlockIcon i{
        padding: 6px;
    }
    .GateKeeperBoxSpan span {
        padding: 4px 10px;
        font-size: 12px;
    }
    .GateKeeperBoxSpan i {
        font-size: 18px;
        margin-right: 8px;
    }
    .PrivateTabButton{
        padding: 0px 10px 5px 10px!important;
        font-size: 14px;   
    }
    .PrivateTabA a{
        padding: 1px 7px!important;
        font-size: 11px!important;
    }
    .GateKeeperBoxSectionName h6{
        font-size: 18px;
    }
    .GateKeeperBoxSectionSubName span{
        font-size: 15px;
    }   
    .GateKeeperBoxSectionSubName{
        margin-bottom: 15px;
    }
    .PrivateTabTimeUpgradeh5 h5{
        font-size: 22px;
    }    
}
@media (max-width:450px) and (min-width:400px) {
    .PrivateTabOverall{
        width: 360px;
    }
    .GateKeeperImage img{
        width: 100px;
        height: 100px;
    }
    .GateKeeperImage{
        margin-right: 15px;
    }
    .GateKeeperImgSectionName h5{
        font-size: 20px;
    }
    .GateKeeperBoxh6 h6{
        font-size: 18px;
    }
    .GateKeeperImgSectionName{
        margin-bottom: 7px;
    }
    .GateKeeperBlockSpan span{
        font-size: 13px;
    }
    .GateKeeperImgSectionIcons li a{
        font-size: 13px;
    }
    .GateKeeperBlockIcon{
        font-size: 15px;
        margin-right: 7px;
    }
    .GateKeeperBlockIcon i{
        padding: 6px;
    }
    .GateKeeperBoxSpan span {
        padding: 4px 10px;
        font-size: 12px;
    }
    .GateKeeperBoxSpan i {
        font-size: 18px;
        margin-right: 8px;
    }
    .PrivateTabButton{
        padding: 0px 8px 0px 8px!important;
        font-size: 12px;   
    }
    .PrivateTabA a{
        padding: 1px 4px!important;
        font-size: 10px!important;
    }
    .PrivateTabA{
        margin-right: 3px!important;
    }
    .GateKeeperBoxSectionName h6{
        font-size: 18px;
    }
    .GateKeeperBoxSectionSubName span{
        font-size: 15px;
    }
    .GateKeeperImgSectionIcons li{
        margin-bottom: 8px;
    }
    .GateKeeperBoxSectionSubName {
        margin-bottom: 9px;
    }
    .GateKeeperBoxSection {
        padding: 15px;
    }
    .PrivateTabTimeUpgradeh5 h5{
        font-size: 21px;
    }
    .PrivateTabTimeUpgradeP p{
        font-size: 15px;
    }
}
@media (max-width:400px) and (min-width:350px) {
    .PrivateTabOverall{
        width: 310px;
    }
    .GateKeeperImage img{
        width: 90px;
        height: 90px;
    }
    .GateKeeperImage{
        margin-right: 15px;
    }
    .GateKeeperImgSectionName h5{
        font-size: 20px;
    }
    .GateKeeperBoxh6 h6{
        font-size: 18px;
    }
    .GateKeeperImgSectionName{
        margin-bottom: 4px;
    }
    .GateKeeperBlockSpan span{
        font-size: 13px;
    }
    .GateKeeperImgSectionIcons li a{
        font-size: 13px;
    }
    .GateKeeperBlockIcon{
        font-size: 15px;
        margin-right: 7px;
    }
    .GateKeeperBlockIcon i{
        padding: 6px;
    }
    .GateKeeperBoxSpan span {
        padding: 4px 10px;
        font-size: 12px;
    }
    .GateKeeperBoxSpan i {
        font-size: 18px;
        margin-right: 8px;
    }
    .PrivateTabButton{
        padding: 0px 8px 0px 8px!important;
        font-size: 12px;   
    }
    .PrivateTabA a{
        padding: 1px 4px!important;
        font-size: 10px!important;
    }
    .PrivateTabA{
        margin-right: 3px!important;
    }
    .GateKeeperBoxSectionName h6{
        font-size: 18px;
    }
    .GateKeeperBoxSectionSubName span{
        font-size: 15px;
    }
    .GateKeeperImgSectionIcons li{
        margin-bottom: 8px;
    }
    .GateKeeperBoxSectionSubName {
        margin-bottom: 9px;
    }
    .GateKeeperBoxSection {
        padding: 15px;
    }
    .PrivateTabTimeUpgradeSection{    
        margin-bottom: 8px;
        padding: 12px 16px;
    }
    .PrivateTabTimeUpgradeLogo{
        width: 24px;
        height: 24px;
        margin-right: 10px;
        margin-top: 9px;
    }
    .PrivateTabTimeUpgradeLogo i{
        font-size: 19px;
        top: 3px;
        left: 7px;
    }
    .PrivateTabTimeUpgradeh5 h5{
        font-size: 21px;
    }
    .PrivateTabTimeUpgradeP p{
        font-size: 14px;
    }
    .PrivateTabupgradenowspan span{
        font-size: 14px;
        padding: 12px 16px;
    } 
}
@media (max-width:350px) and (min-width:315px) {
    .PrivateTabOverall{
        width: 300px;
    }
    .GateKeeperImage img{
        width: 80px;
        height: 80px;
    }
    .GateKeeperImage{
        margin-right: 15px;
    }
    .GateKeeperImgSectionName h5{
        font-size: 20px;
    }
    .GateKeeperBoxh6 h6{
        font-size: 18px;
    }
    .GateKeeperImgSectionName{
        margin-bottom: 4px;
    }
    .GateKeeperBlockSpan span{
        font-size: 13px;
    }
    .GateKeeperImgSectionIcons li a{
        font-size: 13px;
    }
    .GateKeeperBlockIcon{
        font-size: 15px;
        margin-right: 7px;
    }
    .GateKeeperBlockIcon i{
        padding: 6px;
    }
    .GateKeeperBoxSpan span {
        padding: 3px 7px;
        font-size: 10px;
    }
    .GateKeeperBoxSpan i {
        font-size: 15px;
        margin-right: 8px;
    }
    .PrivateTabButton{
        padding: 0px 8px 0px 8px!important;
        font-size: 12px;   
    }
    .PrivateTabA a{
        padding: 1px 4px!important;
        font-size: 10px!important;
    }
    .PrivateTabA{
        margin-right: 3px!important;
    }
    .GateKeeperBoxSectionName h6{
        font-size: 18px;
    }
    .GateKeeperBoxSectionSubName span{
        font-size: 15px;
    }
    .GateKeeperImgSectionIcons li{
        margin-bottom: 8px;
    }
    .GateKeeperBoxSectionSubName {
        margin-bottom: 9px;
    }
    .GateKeeperBoxSection {
        padding: 15px;
    }
    .PrivateTabTimeUpgradeSection{    
        margin-bottom: 8px;
        padding: 8px 8px;
    }
    .PrivateTabTimeUpgradeLogo{
        width: 24px;
        height: 24px;
        margin-right: 10px;
        margin-top: 9px;
    }
    .PrivateTabTimeUpgradeLogo i{
        font-size: 19px;
        top: 3px;
        left: 7px;
    }
    .PrivateTabTimeUpgradeh5 h5{
        font-size: 21px;
    }
    .PrivateTabTimeUpgradeP p{
        font-size: 14px;
    }
    .PrivateTabupgradenowspan span{
        font-size: 14px;
        padding: 12px 16px;
    } 
}
@media (max-width:315px) and (min-width:275px) {
    .PrivateTabOverall{
        width: 260px;
    }
    .GateKeeperImage img{
        width: 80px;
        height: 80px;
    }
    .GateKeeperImage{
        margin-right: 15px;
    }
    .GateKeeperImgSectionName h5{
        font-size: 20px;
    }
    .GateKeeperBoxh6 h6{
        font-size: 18px;
    }
    .GateKeeperImgSectionName{
        margin-bottom: 4px;
    }
    .GateKeeperBlockSpan span{
        font-size: 12px;
    }
    .GateKeeperImgSectionIcons li a{
        font-size: 12px;
    }
    .GateKeeperBlockIcon{
        font-size: 15px;
        margin-right: 7px;
    }
    .GateKeeperBlockIcon i{
        padding: 6px;
    }
    .GateKeeperBoxSpan span {
        padding: 3px 7px;
        font-size: 10px;
    }
    .GateKeeperBoxSpan i {
        font-size: 15px;
        margin-right: 8px;
    }
    .PrivateTabButton{
        padding: 0px 3px 0px 3px!important;
        font-size: 11px;   
    }
    .PrivateTabA a{
        padding: 1px 4px!important;
        font-size: 10px!important;
    }
    .PrivateTabA{
        margin-right: 3px!important;
    }
    .GateKeeperBoxSectionName h6{
        font-size: 18px;
    }
    .GateKeeperBoxSectionSubName span{
        font-size: 13px;
    }
    .GateKeeperImgSectionIcons li{
        margin-bottom: 8px;
    }
    .GateKeeperBoxSectionSubName {
        margin-bottom: 9px;
    }
    .GateKeeperBoxSection {
        padding: 15px;
    }
    .GateKeeperBoxPara p{
        font-size: 13px;
    }
    .PrivateTabTimeUpgradeSection{    
        margin-bottom: 8px;
        padding: 6px 6px;
    }
    .PrivateTabTimeUpgradeh5 h5{
        font-size: 19px;
    }
    .PrivateTabTimeUpgradeP p{
        font-size: 13px;
    }
    .PrivateTabupgradenowspan span{
        font-size: 14px;
        padding: 12px 16px;
    } 
}
@media (max-width:275px){
    .PrivateTabOverall{
        width: 260px;
    }
    .GateKeeperImage img{
        width: 70px;
        height: 70px;
    }
    .GateKeeperImage{
        margin-right: 15px;
    }
    .GateKeeperImgSectionName h5{
        font-size: 20px;
    }
    .GateKeeperBoxh6 h6{
        font-size: 18px;
    }
    .GateKeeperImgSectionName{
        margin-bottom: 4px;
    }
    .GateKeeperBlockSpan span{
        font-size: 12px;
    }
    .GateKeeperImgSectionIcons li a{
        font-size: 12px;
    }
    .GateKeeperBlockIcon{
        font-size: 15px;
        margin-right: 7px;
    }
    .GateKeeperBlockIcon i{
        padding: 6px;
    }
    .GateKeeperBoxSpan span {
        padding: 3px 7px;
        font-size: 10px;
    }
    .GateKeeperBoxSpan i {
        font-size: 15px;
        margin-right: 8px;
    }
    .PrivateTabButton{
        padding: 0px 3px 0px 3px!important;
        font-size: 11px;   
    }
    .PrivateTabA a{
        padding: 1px 4px!important;
        font-size: 10px!important;
    }
    .PrivateTabA{
        margin-right: 3px!important;
    }
    .GateKeeperBoxSectionName h6{
        font-size: 18px;
    }
    .GateKeeperBoxSectionSubName span{
        font-size: 13px;
    }
    .GateKeeperImgSectionIcons li{
        margin-bottom: 8px;
    }
    .GateKeeperBoxSectionSubName {
        margin-bottom: 9px;
    }
    .GateKeeperBoxSection {
        padding: 15px;
    }
    .GateKeeperBoxPara p{
        font-size: 13px;
    }
    .PrivateTabTimeUpgradeSection{    
        margin-bottom: 8px;
        padding: 6px 6px;
    }
    .PrivateTabTimeUpgradeh5 h5{
        font-size: 19px;
    }
    .PrivateTabTimeUpgradeP p{
        font-size: 13px;
    }
    .PrivateTabupgradenowspan span{
        font-size: 14px;
        padding: 12px 16px;
    } 
}

