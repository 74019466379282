.loginleft{
    background-color: #F0F6FF;    
}
.loginImg img{
    width: 373px;
    height: 325px;
    object-fit: cover;
    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
}
.loginEllipse{
    width: 402px;
    height: 486px;
    border-radius: 82% 37% 66% 63%;
    background-color: #fff;
    rotate: -90deg;
}
.loginInnerWrapper{
    height: calc(100vh);
}
.logincont{
    height: 100%;
    width: 75%;
    margin: 0 auto;
}
.logincont h5{
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #292A31;
    margin-bottom: 49px; 
}
.logincont, .logincontlabel{
    flex-direction: column;
}
.logincontlabel ::placeholder{
    font-size: 14px;
    font-weight: 500;
    color: #787885;
}
.logincontlabel label{
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 14px;
}
.logincontlabel input{
    width: 100%;
    height: 64px;    
    border: 1px solid #D2D2D6;
    border-radius: 10px;
    margin-bottom: 32px;
    font-size: 16px;
    font-weight: 500;
}
.pwinput input{
    margin-bottom: 20px;
}
.login{
    border: none;
    width: 100%;
    height: 64px;
    background-color: #2979FF;
    color: #fff;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 24px;
}
.pointerclass, .pointerclass input{
    cursor: pointer;
}
.loginForgot{
    margin-bottom: 20px;
}
.loginRegister{
    font-size: 14px;
    font-weight: 500;
}
.loginRegister span{
    color: #3A3A44;
}
.loginRegister a{
    color: #2979FF;
    border-bottom: solid 1px #2979FF;
}

@media (max-width: 1250px) and (min-width: 1025px){
    .loginImg img{
        width: 280px;
        height: 230px;
    }
    .loginEllipse{
        width: 315px;
        height: 325px;
    }
    .phonenospan h6{
        font-size: 16px;
    }
}
@media (max-width: 1025px) and (min-width: 767px){
    .loginImg img{
        width: 280px;
        height: 230px;
    }
    .loginEllipse{
        width: 315px;
        height: 325px;
    }
}
@media (max-width: 767px) and (min-width: 500px){
    .logincont h5{
        margin-bottom: 18px; 
    }
    .logincontlabel input{
        height: 45px;
        margin-bottom: 13px;
    }
    .login{
        height: 45px;
        font-size: 14px;
        margin-bottom: 14px;
    }
}
@media (max-width: 499px){
    .logincont h5{
        font-size: 19px;
        line-height: 30px;
        margin-bottom: 20px; 
    }
    .logincontlabel input{
        height: 40px;
        font-size: 14px;
        margin-bottom: 12px;
    }
    .login{
        height: 40px;
        font-size: 13px;
        margin-bottom: 14px;
    }
    .logincontlabel label{
        font-size: 14px;
        line-height: 12px;
        margin-bottom: 10px;
    }
    .logincontlabel ::placeholder{
        font-size: 12px;
    }
    .loginRegister{
        font-size: 11px;
    } 
    .loginForgot{
        margin-bottom: 12px;
    }   
}

