.UpgradePlanOverall{
    width: 1156px;
    margin: 0 auto;
    padding-top: 140px;
}
.switch {
    display: inline-block;
    height: 12px;
    position: relative;
    width: 30px;
}  
.switch input {
    display:none;
}  
.slider {
    background-color: #9DC2FF;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: .4s;
}  
.slider:before {
    background-color: #2264D1;
    bottom: -4px;
    content: "";
    width: 20px;
    height: 20px;
    left: -6px;
    position: absolute;
    transition: .4s;    
}  
input:checked + .slider {
    background-color: #9DC2FF;
}  
input:checked + .slider:before {
    transform: translateX(26px);
}  
.slider.round {
    border-radius: 34px;
}  
.slider.round:before {
    border-radius: 50%;
}  
.UpgradePlanDoggleSection{
    margin-bottom: 24px;
}  
.UpgradePlanh6 h6{
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    color: #19191D;
}
.UpgradePlanMomnthly span{
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #0C2146;
    margin-right: 18px;
}
.UpgradePlanYearly span{
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #0C2146;
}
.UpgradePlanDoggle{
    margin-right: 18px;
}
.UpgradePlanBasicSection{
    width: 560px;
    padding: 30px 40px;
    border: 1px solid #E1E1E3;
    border-radius: 10px;
}
.UpgradePlanBasic span{
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    color: #19191D;
}
.UpgradePlanFree span{
    font-size: 44px;
    font-weight: 600;
    line-height: 56px;
    color: #19191D;    
}
.UpgradePlanFree{
    margin-bottom: 51px;
}
.UpgradePlanFreeh6 h6{
    font-size: 18px;
    padding-top: 30px;
    font-weight: 400;
    line-height: 27px;
    margin-left: 8px;
    color: #19191D;
    align-items: center;
}
.UpgradePlanCompanySection{
    margin-bottom: 40px;
}
.UpgradePlanCompany{
    margin-bottom: 16px;
}
.UpgradePlanCompany span{
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #19191D;    
}
.UpgradePlanCompany i{
    padding: 7px;
    font-size: 14px;
    margin-right: 20px;
    border-radius: 50%;
    color: #3A3A44;
    cursor: pointer;
    background-color: #EDEDF0;
}
.UpgradePlanCompanyBG i{
    color: #2264D1;
    background-color: #EBF2FF;
}
.UpgradePlanCurrentButton span{
    font-size: 14px;
    width: 100%;
    height: 50px;
    border-radius: 5px;
    background-color: #EDEDF0;
    font-weight: 700;
    line-height: 16px;
    color: #9696A0; 
    cursor: pointer;
}
.UpgradePlanPremiumButton span{
    font-size: 14px;
    width: 100%;
    height: 50px;
    border-radius: 5px;
    background-color: #2979FF;
    font-weight: 700;
    line-height: 16px;
    color: #F5F8FF; 
    cursor: pointer;
}

@media(max-width: 1300px ) and (min-width: 1100px){
    .UpgradePlanOverall{
        width: 1000px;
    }
    .UpgradePlanBasicSection{
        width: 480px;
    }
}
@media(max-width: 1100px ) and (min-width: 900px){
    .UpgradePlanOverall{
        width: 800px;
    }
    .UpgradePlanBasicSection{
        width: 385px;
    }
}
@media(max-width: 900px ) and (min-width: 768px){
    .UpgradePlanOverall{
        width: 700px;
    }
    .UpgradePlanBasicSection{
        width: 335px;
        padding: 30px 30px;
    }
}
@media(max-width: 767px ) and (min-width: 600px){
    .UpgradePlanOverall{
        width: 450px;
    }
    .UpgradePlanBasicSection{
        width: 450px;
        padding: 30px 30px;
    }
}
@media(max-width: 600px ) and (min-width: 400px){
    .UpgradePlanOverall{
        width: 350px;
    }
    .UpgradePlanBasicSection{
        width: 350px;
        padding: 30px 30px;
    }
}
@media(max-width: 400px) and (min-width: 350px){
    .UpgradePlanOverall{
        width: 280px;
    }
    .UpgradePlanBasicSection{
        width: 280px;
        padding: 20px 20px;
    }
    .UpgradePlanFree{
        margin-bottom: 25px;
    }
    .UpgradePlanFree span{
        font-size: 40px;
    }
    .UpgradePlanCompanySection {
        margin-bottom: 25px;
    }
}
@media(max-width: 350px) and (min-width: 275px){
    .UpgradePlanOverall{
        width: 240px;
    }
    .UpgradePlanBasicSection{
        width: 240px;
        padding: 20px 20px;
    }
    .UpgradePlanFree{
        margin-bottom: 25px;
    }
    .UpgradePlanFree span{
        font-size: 35px;
    }
    .UpgradePlanCompany span{
        font-size: 12px;
    }
    .UpgradePlanCompany i{
        padding: 5px;
        font-size: 13px;
        margin-right: 13px;
    }
    .UpgradePlanCompanySection {
        margin-bottom: 25px;
    }
    .UpgradePlanCurrentButton span{
        font-size: 13px;
        height: 45px;
    }
    .UpgradePlanPremiumButton span{
        font-size: 13px;
        height: 45px;
    }
}
@media(max-width: 275px){
    .UpgradePlanOverall{
        width: 240px;
    }
    .UpgradePlanBasicSection{
        width: 240px;
        padding: 20px 20px;
    }
    .UpgradePlanFree{
        margin-bottom: 25px;
    }
    .UpgradePlanFree span{
        font-size: 35px;
    }
    .UpgradePlanCompany span{
        font-size: 12px;
    }
    .UpgradePlanCompany i{
        padding: 5px;
        font-size: 13px;
        margin-right: 13px;
    }
    .UpgradePlanCompanySection {
        margin-bottom: 25px;
    }
    .UpgradePlanCurrentButton span{
        font-size: 13px;
        height: 45px;
    }
    .UpgradePlanPremiumButton span{
        font-size: 13px;
        height: 45px;
    }
}

