.sidebarParent {
    /* width: 100%;
    z-index: 999;
    padding-bottom: 10px; */
    /* position: absolute; */
  /* height: 50vh; */
    /* background-color: rgba(0, 0, 0, 0.5); */

    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(1px);
  }
  
  .header {
    padding: 10px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #ededf0;
  }
  
  .heading {
    font-weight: 700;
    font-size: 20px;
  }
   .closeBtn {
    background: #ededf0;
    font-weight: 500;
    font-size: 15px;
    border-radius: 100%;
    padding: 5px 10px;
  }
 .clearAllBtnWrapper {
    width: 100%;
    padding: 20px 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
 .clearAllBtn {
    background: transparent;
    color: #46a6f7;
    outline: none;
    border: none;
  }
  
    .radioBtnsWrapper {
    /* padding: 20px 20px; */
  }
  .radioBtn {
    margin-right: 10px;
  }
  .radioBtnSelected {
    border-radius: 4px;
    background: #ededf0;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  .radioBtnCommon {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  .mainContentSplitter {
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background: #fff;
    width: 50%;
    /* box-shadow: 0 0 10px #ccc; */
    margin: 15% auto;
    /* height: 100%; */
    /* margin-bottom: 10px; */
    padding-bottom: 35px;
    /* float: right; */
  }
  
  .btnsFooter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px 20px;
  }
  .body-locked {
    overflow: hidden;
  }
  .applyBtn{
  padding:10px 20px;
  background:#46a6f7;
  color:white;
  }
  .cancelBtn{
  padding:10px 20px;
  border: 1px solid #B4B4BB;
  margin-right:10px
  }
  .cancelBtn:hover{
    border: 1px solid #79797d;
  
  }
  .applyBtn:hover{
  background:#2d6b9e;
  }
  .alignicons{
    padding: 5px;
    float: left;
  }

  .align{
    text-align: center;
    margin: auto;
    width: 42%;
    /* border: 3px solid green; */
    padding: 10px;
    margin: 0 auto;
  }
  .container {
    background-color: #ffffff;
    padding: 10px;
    border-radius: 10px;
    /* margin: 30px 0; */
    text-align: center;
  }

  .containerinput {
    font-size: 14px;
    padding: 10px;
    border-radius: 5px;
    width: 80%;
    border: 1px solid #000000;
  }
  .containerbutton {
    font-size: 18px;
    padding: 10px 20px;
    background-color: #577eff;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    margin-left: 10px;
  }

.center{
    text-align: center;
  }
  @media all and (max-width: 700px) {
    .mainContentSplitter {
      width: 80%;
    }
  }
   