.ShareDocumentOverall{
    width: 100%;
    margin: 0 auto;        
    height: 100vh;
    background-color: rgba(25,25,29,0.4);
}
.ShareDocumentSection{
    width: 520px;
    padding: 24px 20px;
    background-color: #fff;
    border-radius: 15px;
}
.ShareSpan{
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    color: #19191D;
}
.ShareIcons i{
    font-size: 20px;
    color: #4A4B57;
}
.SharequestionIcon{
    margin-right: 16px;
}
.ShareDocumentHeader{
    margin-bottom: 18px;
}
.ShareDocumentInput{
    margin-bottom: 24px;
}
.ShareDocumentInput input{
    width: 100%;
    height: 50px;
    font-size: 16px;
    border: 1px solid #B4B4BB;
    border-radius: 5px;
    padding: 0px 15px;
}
.ShareDocumentInput input::placeholder{
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #9696A0;
}
.ShareDocumentImage, .ShareDocumentlock{    
    margin-right: 16px;
}
.ShareDocumentImage img{
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
}
.ShareDocumentlock i{
    width: 40px;
    height: 40px;
    font-size: 25px;
    color: #3A3A44;
    background-color: #E1E1E3;
    border-radius: 50%;
}
.ShareDocumentPeople{
    margin-bottom: 16px;
}
.ShareDocumentPeople span{
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: #19191D;
}
.ShareDocumentName span{
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: #19191D;
}
.ShareDocumentMail span{
    font-size: 12px;
    line-height: 15px;
    font-weight: 400;
    color: #787885;
}
.ShareDocumentOwner span{
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #19191D;
}
.ShareDocumentImgSection{
    margin-bottom: 35px;
}
.ShareDocumentCopy{
    padding: 13px 16px;
    border: 1px solid #B4B4BB;
    border-radius: 5px;    
    color: #2979FF;
}
.ShareDocumentCopy i{
    font-size: 16px;
    line-height: 16px;
    margin-right: 10px;
}
.ShareDocumentCopy span{
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
}
.ShareDocumentDone{
    padding: 13px 16px;
    background-color: #2979FF;
    border-radius: 5px;    
    color: #F5F8FF;
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
}

@media (max-width:900px) and (min-width:750px){
    .ShareDocumentSection{
        width: 480px;
        padding: 24px 20px;
        background-color: #fff;
        border-radius: 15px;
    }
}
@media (max-width:750px) and (min-width:600px){
    .ShareDocumentSection{
        width: 450px;
        padding: 24px 20px;
        background-color: #fff;
        border-radius: 15px;
    }
}
@media (max-width:600px) and (min-width:500px){
    .ShareDocumentSection{
        width: 400px;
        padding: 24px 20px;
        background-color: #fff;
        border-radius: 15px;
    }
}
@media (max-width:500px) and (min-width:400px){
    .ShareDocumentSection{
        width: 360px;
        padding: 24px 20px;
        background-color: #fff;
        border-radius: 15px;
    }
    .ShareSpan{
        font-size: 18px;
    }
    .ShareDocumentPeople{
        margin-bottom: 5px;
    }
    .ShareDocumentImgSection {
        margin-bottom: 23px;
    }
    .ShareDocumentHeader{
        margin-bottom: 13px;
    }
    .ShareDocumentInput{
        margin-bottom: 15px;
    }    
    .ShareDocumentPeople span{
        font-size: 15px;
    }
    .ShareDocumentName span{
        font-size: 13px;
    }
    .ShareDocumentOwner span{
        font-size: 13px;
    }
    .ShareDocumentMail span{
        font-size: 11px;
    }
    .ShareDocumentInput input{
        height: 40px;
        font-size: 16px;
    }
    .ShareDocumentInput input::placeholder{
        font-size: 14px;
    }
    .ShareDocumentImage, .ShareDocumentlock{    
        margin-right: 20px;
    }
    .ShareDocumentCopy{
        padding: 10px 14px;
    }
    .ShareDocumentCopy i{
        font-size: 15px;
        margin-right: 10px;
    }
    .ShareDocumentCopy span{
        font-size: 12px;
    }
    .ShareDocumentDone{
        padding: 10px 14px;
        font-size: 12px;
    }
    
}
@media (max-width:400px) and (min-width:350px){
    .ShareDocumentSection{
        width: 300px;
        padding: 15px 10px;
        background-color: #fff;
        border-radius: 15px;
    }
    .ShareDocumentImage img{
        width: 35px;
        height: 35px;
    }
    .ShareDocumentlock i{
        width: 35px;
        height: 35px;
    }
    .ShareIcons i{
        font-size: 17px;
    }
    .SharequestionIcon {
        margin-right: 8px;
    }
    .ShareSpan{
        font-size: 13px;
    }
    .ShareDocumentPeople{
        margin-bottom: 5px;
    }
    .ShareDocumentImgSection {
        margin-bottom: 15px;
    }
    .ShareDocumentPeople span{
        font-size: 13px;
    }
    .ShareDocumentName span{
        font-size: 12px;
    }
    .ShareDocumentOwner span{
        font-size: 12px;
    }
    .ShareDocumentMail span{
        font-size: 10px;
    }
    .ShareDocumentImage, .ShareDocumentlock{    
        margin-right: 20px;
    }
    .ShareDocumentCopy{
        padding: 8px 14px;
    }
    .ShareDocumentCopy i{
        font-size: 14px;
        margin-right: 10px;
    }
    .ShareDocumentCopy span{
        font-size: 11px;
    }
    .ShareDocumentDone{
        padding: 8px 14px;
        font-size: 11px;
    }
    .ShareDocumentHeader{
        margin-bottom: 8px;
    }
    .ShareDocumentInput{
        margin-bottom: 15px;
    }
    .ShareDocumentInput input{
        height: 40px;
        font-size: 14px;
        padding: 0px 15px;
    }
    .ShareDocumentInput input::placeholder{
        font-size: 12px;
        line-height: 20px;
    }
    
}
@media (max-width:350px) and (min-width:300px){
    .ShareDocumentSection{
        width: 290px;
        padding: 15px 10px;
        background-color: #fff;
        border-radius: 15px;
    }
    .ShareDocumentImage img{
        width: 35px;
        height: 35px;
    }
    .ShareDocumentlock i{
        width: 35px;
        height: 35px;
    }
    .ShareIcons i{
        font-size: 17px;
    }
    .SharequestionIcon {
        margin-right: 8px;
    }
    .ShareSpan{
        font-size: 13px;
    }
    .ShareDocumentPeople{
        margin-bottom: 3px;
    }
    .ShareDocumentImgSection {
        margin-bottom: 15px;
    }
    .ShareDocumentPeople span{
        font-size: 13px;
    }
    .ShareDocumentName span{
        font-size: 12px;
    }
    .ShareDocumentOwner span{
        font-size: 12px;
    }
    .ShareDocumentMail span{
        font-size: 10px;
    }
    .ShareDocumentImage, .ShareDocumentlock{    
        margin-right: 20px;
    }
    .ShareDocumentCopy{
        padding: 8px 12px;
    }
    .ShareDocumentCopy i{
        font-size: 15px;
        margin-right: 8px;
    }
    .ShareDocumentCopy span{
        font-size: 11px;
    }
    .ShareDocumentDone{
        padding: 8px 12px;
        font-size: 11px;
    }
    .ShareDocumentHeader{
        margin-bottom: 8px;
    }
    .ShareDocumentInput{
        margin-bottom: 15px;
    }
    .ShareDocumentInput input{
        height: 40px;
        font-size: 14px;
        padding: 0px 15px;
    }
    .ShareDocumentInput input::placeholder{
        font-size: 12px;
        line-height: 20px;
    }
    
}
@media (max-width:300px) and (min-width:275px){
    .ShareDocumentSection{
        width: 270px;
        padding: 15px 10px;
        background-color: #fff;
        border-radius: 15px;
    }
    .ShareDocumentImage img{
        width: 35px;
        height: 35px;
    }
    .ShareDocumentlock i{
        width: 35px;
        height: 35px;
    }
    .ShareIcons i{
        font-size: 17px;
    }
    .SharequestionIcon {
        margin-right: 8px;
    }
    .ShareSpan{
        font-size: 13px;
    }
    .ShareDocumentPeople{
        margin-bottom: 0px;
    }
    .ShareDocumentImgSection {
        margin-bottom: 12px;
    }
    .ShareDocumentPeople span{
        font-size: 13px;
    }
    .ShareDocumentName span{
        font-size: 12px;
    }
    .ShareDocumentOwner span{
        font-size: 12px;
    }
    .ShareDocumentMail span{
        font-size: 10px;
    }
    .ShareDocumentImage, .ShareDocumentlock{    
        margin-right: 20px;
    }
    .ShareDocumentCopy{
        padding: 6px 10px;
    }
    .ShareDocumentCopy i{
        font-size: 12px;
        margin-right: 8px;
    }
    .ShareDocumentCopy span{
        font-size: 10px;
    }
    .ShareDocumentDone{
        padding: 6px 10px;
        font-size: 10px;
    }
    .ShareDocumentHeader{
        margin-bottom: 8px;
    }
    .ShareDocumentInput{
        margin-bottom: 15px;
    }
    .ShareDocumentInput input{
        height: 40px;
        font-size: 14px;
        padding: 0px 15px;
    }
    .ShareDocumentInput input::placeholder{
        font-size: 12px;
        line-height: 20px;
    }
    
}
@media (max-width:275px){
    .ShareDocumentSection{
        width: 270px;
        padding: 15px 10px;
        background-color: #fff;
        border-radius: 15px;
    }
    .ShareDocumentImage img{
        width: 35px;
        height: 35px;
    }
    .ShareDocumentlock i{
        width: 35px;
        height: 35px;
    }
    .ShareIcons i{
        font-size: 17px;
    }
    .SharequestionIcon {
        margin-right: 8px;
    }
    .ShareSpan{
        font-size: 13px;
    }
    .ShareDocumentPeople{
        margin-bottom: 0px;
    }
    .ShareDocumentImgSection {
        margin-bottom: 12px;
    }
    .ShareDocumentPeople span{
        font-size: 13px;
    }
    .ShareDocumentName span{
        font-size: 12px;
    }
    .ShareDocumentOwner span{
        font-size: 12px;
    }
    .ShareDocumentMail span{
        font-size: 10px;
    }
    .ShareDocumentImage, .ShareDocumentlock{    
        margin-right: 20px;
    }
    .ShareDocumentCopy{
        padding: 6px 10px;
    }
    .ShareDocumentCopy i{
        font-size: 12px;
        margin-right: 8px;
    }
    .ShareDocumentCopy span{
        font-size: 10px;
    }
    .ShareDocumentDone{
        padding: 6px 10px;
        font-size: 10px;
    }
    .ShareDocumentHeader{
        margin-bottom: 8px;
    }
    .ShareDocumentInput{
        margin-bottom: 15px;
    }
    .ShareDocumentInput input{
        height: 40px;
        font-size: 14px;
        padding: 0px 15px;
    }
    .ShareDocumentInput input::placeholder{
        font-size: 12px;
        line-height: 20px;
    }
    
}


