.HomeBrowseOverall{
    width: 1156px;
    margin: 0 auto;
    margin-bottom: 80px;
}
.HomeBrowseHeaderSection{
    padding: 10px 0;
}
.BrowseHeaderH5 h5 {
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    color: #19191D;
}
.BrowseHeaderButton button:focus-visible{
    outline: none;
}
.BrowseHeaderButton button i {
    font-size: 20px;
    padding: 7px 10px;
    border-radius: 5px;
    color: #B4B4BB;
    background-color: #EDEDF0;
}
.selectedIndexColor{
    background-color: #e5d4d4 !important;
    color: #ffffff !important;
    cursor: pointer;
    box-shadow: 0px 1px 3px; 
    transform: scale(1.2);
}
.selectedIndexColor:hover {
    transform: scale(1.2); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
    
}
.nonSelectedIndexColor{
    /* background-image: url("../../logo.svg"); */
    background-color: #f2f2f2 !important;
    color: #f2f2f2 !important;
    cursor: pointer;
}
.nonSelectedIndexColor:hover {
    transform: scale(1.2); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
    box-shadow: 0px 1px 3px;  
}
.HomeBrowsePaginationBoxSection{
    margin-bottom: 25px;
}
.HomeBrowsePaginationBox{
    width: 100%!important;
    background-color: #ECF7ED;
    border: solid 2px #A9D3AB;
    border-radius: 10px;
    padding: 12px 10px;
    box-shadow: -1px 2px #A9D3AB;
    margin-bottom: 8px;
}
.HomeBrowsePaginationBoxPara p {
    color: #2B662E;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 1px;
}
.HomeBrowsePaginationBoxh6 h6 {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    color: #132C14;
}
.HomeBrowsePaginationBoxh6{
    margin-bottom: 14px;
    float: left;
}
.HomeBrowseBox{
    width: 100%!important;
    background-color: #ECF7ED;
    border: solid 2px #A9D3AB;
    border-radius: 10px;
    padding: 12px 10px;
    box-shadow: -1px 2px #A9D3AB;
    margin-bottom: 25px;
}
.HomeBrowseBoxPara p {
    color: #2B662E;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 1px;
}
.HomeBrowseBoxh6 h6 {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    color: #132C14;
}
.HomeBrowseBoxh6{
    margin-bottom: 14px;
    float: left;
}
.HomeBrowseImageSection{
    margin-bottom: 12px;
}
.HomeBrowseImage{
    width: auto;
    background-color: #fff;
    border-radius: 25px;
    float: left;
}
.HomeBrowseImage img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    object-fit: cover;
}
.HomeBrowseImage span {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding: 4px 10px;
    color: #65B168;
    display: flex;
    align-items: center;
}
.HomeBrowseIconsSection{
    margin-bottom: 14px;
}
.HomeBrowseIconsSpan{
    width: auto;
    background-color: #fff;
    border-radius: 25px;
    float: left;
}
.HomeBrowseIconsSpan span {
    padding: 4px 10px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    display: flex;
    align-items: center;
}
.HomeBrowseIconsSpan i {
    font-size: 20px;
    margin-right: 13px;
}
.HomeBrowsethumbsup span {
    color: #4F91FF;
}
.HomeBrowsethumbsup i {
    color: #9DC2FF;
}
.HomeBrowseuser span {
    color: #EF6292;
}
.HomeBrowseuser i {
    color: #EF6292;
}
.HomeBrowserandom span {
    color: #FB982E;
}
.HomeBrowserandom i {
    color: #FDC68B;
}
.HomeBrowseBoxUniHead{
    margin-bottom: 8px;
}
.HomeBrowseBoxUniHeadDots i{
    width: 32px;
    height: 22px;    
    display: flex;
    justify-content: center;
    background-color: #E1E1E3;
    font-size: 24px;
    border-radius: 4px;
    color: #5A5B6A;
}
.HomeBrowseBoxUniHead span{
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: #3A3A44;
}
.HomeBrowseNumber{
    margin-bottom: 12px;
}
.HomeBrowseNumber span{
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #9696A0;
}
.HomeBrowseMaster{
    width: auto;
    background-color: #FEE8E7;
    border-radius: 25px;
    float: left;
}
.HomeBrowseMaster span {
    padding: 4px 10px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #C8372D;
    display: flex;
    align-items: center;
}
.HomeBrowseMaster i {
    font-size: 20px;
    color: #FAA9A3;
    margin-right: 6px;
}
.HomeBrowseIconsTwoIcon i{
    padding: 4px 10px;
    background-color: #fff;
    border-radius: 20px;
    font-size: 20px;
    color: #787885;
}
.HomeBrowseGuardian{
    width: auto;
    background-color: #EBF2FF;
    border-radius: 25px;
    float: left;
}
.HomeBrowseGuardian span {
    padding: 4px 10px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #2264D1;
    display: flex;
    align-items: center;
}
.HomeBrowseGuardian i {
    font-size: 25px;
    color: #9DC2FF;
    margin-right: 6px;
}
.HomeBrowseInputBox{
    width: 100%!important;
    background-color: #EDEDF0;
    border: solid 2px #9696A0;
    border-radius: 10px;
    padding: 12px 10px;
    box-shadow: -1px 2px #9696A0;
}
.PostHeader span{
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #000;
}
.PostButton{
    height: 40px;
}
.PostButton button{
    font-size: 14px;
    padding: 0px 8px;
    line-height: 16px;
    font-weight: 700;   
}
.PostButtonGreen{
    color: #132C14;    
    border-radius: 5px;
    background-color: #ECF7ED;    
    border: 2px solid #65B168;
    margin-right: 5px;
}
.PostButtonPink{
    color: #43130F;
    background-color: #FEE8E7;    
    border: 2px solid #F6655A;    
    border-radius: 5px;
}
.NextPost span{
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #3A3A44;
    margin-right: 16px;
}
.HomeBrowseDropdown{
    width: 200px;
    height: 40px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #19191D!important;
    background-color: #fff;
    border: 1px solid #B4B4BB;
    text-align: left;
}
.HomeBrowseDropdown:hover{
    color: #19191D;
    background-color: #fff;
    border-color: #B4B4BB;
}
.HomeBrowseDropdown:focus{
    background-color: #fff;
    border-color: #B4B4BB;
    box-shadow: none!important;
}
.HomeBrowseDropdown::after{
    background: none;
    outline: none;
    position: relative;
    left: 51%;
    color: #B4B4BB;
    border-top: 0.4em solid;
    border-right: 0.4em solid transparent;
    border-bottom: 0;
    border-left: 0.4em solid transparent;
}
.HomeBrowseDropdown i{
    color: #B4B4BB;
    font-size: 20px;
}
.HomeBrowseDropdownUL{
    min-width: 200px;
}
.HomeBrowseDropdownUL li{
    padding: 0px 10px;
}
.HomeBrowseTextarea{
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 5px;
    outline: none;
    resize: none;
    border: none;
    box-shadow: 0px 2px #9696a079;
}
.HomeBrowseTextarea:focus{
    box-shadow: 0px 2px #9696a079;
    border: none;
}
.HomeBrowseTextareaPart textarea::placeholder{
    font-size: 16px!important;
    color: #9696A0;
    font-weight: 400;
    line-height: 24px;
}
.HomeBrowseTextareaPart h6{
    font-size: 12px; 
    color: #787885;
    text-align: end;
}
.HomeBrowsePagination li a{
    width: 43px;
    height: 40px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #EDEDF0;
    color: #787885!important;
    margin-right: 16px;
    border: none!important;
    border-radius: 4px!important;
}
.HomeBrowsePagination li a:focus{
    box-shadow: none;
    background-color: #787885;
    color: #FFFFFF!important;
}
.HomeBrowsePagination i{
    color: #ECF7ED;
    font-size: 18px;
    width: 43px;
    height: 40px;
    border-radius: 4px!important;
    background-color: #65B168;
}
.dots{
    padding: 2px;
    padding-right: 20px;
    /* color: red; */
    fill: darkgray;
}

.btn{
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    /* padding: 15px 32px; */
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
  
}

.btn-algn{
    padding-top: 20px;
    text-align: center;
}

@media (max-width: 1250px) and (min-width: 1100px) {
    .HomeBrowseOverall{
        width: 1030px;
    }
    .HomeBrowseBox{
        margin-bottom: 15px;
   }
   .HomeBrowsePaginationBoxSection {
        margin-bottom: 15px;
   }
}
@media (max-width: 1100px) and (min-width: 992px) {
    .HomeBrowseOverall{
        width: 920px;
    }
    .HomeBrowseImage span {
        font-size: 13px;
        padding: 4px 10px;
    }
    .HomeBrowseImage img {
        width: 22px;
        height: 22px;
    }
    .HomeBrowseMaster span {
        padding: 4px 10px;
        font-size: 13px;
    }
    .HomeBrowseMaster i {
        font-size: 17px;
        margin-right: 6px;
    }
    .HomeBrowseIconsSpan span {
        padding: 4px 10px;
        font-size: 13px;
    }
    .HomeBrowseIconsSpan i {
        font-size: 18px;
        margin-right: 10px;
    }
    .HomeBrowseGuardian span {
        padding: 4px 10px;
        font-size: 13px;
    }
    .HomeBrowseGuardian i {
        font-size: 21px;
        margin-right: 6px;
    }
    .HomeBrowsePaginationBoxPara p{
        font-size: 15px;
    }
    .HomeBrowseBox{
        margin-bottom: 15px;
   }
   .HomeBrowsePaginationBoxSection {
        margin-bottom: 10px;
   }
}
@media (max-width: 992px) and (min-width: 850px) {
    .HomeBrowseOverall{
        width: 800px;
    }
    .HomeBrowseHeaderSection{
        padding: 5px 0;
    }
    .HomeBrowseImage span {
        font-size: 14px;
        padding: 4px 10px;
    }
    .HomeBrowseImage img {
        width: 22px;
        height: 22px;
    }
    .HomeBrowseNumber{
        margin-bottom: 7px;
    }
    .HomeBrowseImageSection{
        margin-bottom: 10px;
    }
    .HomeBrowseIconsSection {
        margin-bottom: 10px;
    }
    .HomeBrowseMaster span {
        padding: 4px 10px;
        font-size: 14px;
    }
    .HomeBrowseMaster i {
        font-size: 18px;
        margin-right: 6px;
    }
    .HomeBrowseIconsSpan span {
        padding: 4px 10px;
        font-size: 13px;
    }
    .HomeBrowseIconsSpan i {
        font-size: 18px;
        margin-right: 10px;
    }
    .HomeBrowseIconsTwoIcon i{
        font-size: 18px;
    }
    .HomeBrowseGuardian span {
        padding: 4px 10px;
        font-size: 14px;
    }
    .HomeBrowseGuardian i {
        font-size: 21px;
        margin-right: 6px;
    }
    .HomeBrowsePaginationBoxPara p{
        font-size: 15px;
    }
    .HomeBrowseBoxPara p{
        font-size: 15px;
    }
   .HomeBrowseBoxh6 {
        margin-bottom: 7px;
   }
   .HomeBrowseBox{
        margin-bottom: 15px;
   }
   .HomeBrowsePaginationBoxSection {
        margin-bottom: 10px;
   }
}
@media (max-width: 850px) and (min-width: 750px) {
    .HomeBrowseOverall{
        width: 700px;
    }
    .HomeBrowseHeaderSection{
        padding: 5px 0;
    }
    .HomeBrowseImage span {
        font-size: 14px;
        padding: 4px 10px;
    }
    .HomeBrowseImage img {
        width: 22px;
        height: 22px;
    }
    .HomeBrowseNumber{
        margin-bottom: 7px;
    }
    .HomeBrowseImageSection{
        margin-bottom: 10px;
    }
    .HomeBrowseIconsSection {
        margin-bottom: 10px;
    }
    .HomeBrowseMaster span {
        padding: 4px 10px;
        font-size: 14px;
    }
    .HomeBrowseMaster i {
        font-size: 18px;
        margin-right: 6px;
    }
    .HomeBrowseIconsSpan span {
        padding: 4px 10px;
        font-size: 13px;
    }
    .HomeBrowseIconsSpan i {
        font-size: 18px;
        margin-right: 10px;
    }
    .HomeBrowseIconsTwoIcon i{
        font-size: 18px;
    }
    .HomeBrowseGuardian span {
        padding: 4px 10px;
        font-size: 14px;
    }
    .HomeBrowseGuardian i {
        font-size: 21px;
        margin-right: 6px;
    }
    .HomeBrowsePaginationBoxPara p{
        font-size: 15px;
    }
    .HomeBrowseBoxPara p{
        font-size: 15px;
    }
   .HomeBrowseBoxh6 {
        margin-bottom: 7px;
   }
   .HomeBrowseBox{
        margin-bottom: 10px;
   }
   .HomeBrowsePaginationBoxSection {
        margin-bottom: 10px;
   }
   .HomeBrowsePagination li a{
    width: 38px;
    height: 38px;
    font-size: 14px;
    margin-right: 8px;
    }
    .HomeBrowsePagination i{
        font-size: 15px;
        width: 38px;
        height: 38px;
    }
}
@media (max-width: 750px) and (min-width: 650px) {
    .HomeBrowseOverall{
        width: 620px;
    }
    .HomeBrowseHeaderSection{
        padding: 3px 0;
    }
    .BrowseHeaderH5 h5 {
        font-size: 22px;
    }
    .HomeBrowseImage span {
        font-size: 14px;
        padding: 4px 10px;
    }
    .HomeBrowseImage img {
        width: 22px;
        height: 22px;
    }
    .HomeBrowseNumber{
        margin-bottom: 7px;
    }
    .HomeBrowseImageSection{
        margin-bottom: 10px;
    }
    .HomeBrowseIconsSection {
        margin-bottom: 10px;
    }
    .HomeBrowseMaster span {
        padding: 4px 10px;
        font-size: 14px;
    }
    .HomeBrowseMaster i {
        font-size: 18px;
        margin-right: 6px;
    }
    .HomeBrowseIconsSpan span {
        padding: 4px 10px;
        font-size: 13px;
    }
    .HomeBrowseIconsSpan i {
        font-size: 18px;
        margin-right: 10px;
    }
    .HomeBrowseIconsTwoIcon i{
        font-size: 18px;
    }
    .HomeBrowseGuardian span {
        padding: 4px 10px;
        font-size: 14px;
    }
    .HomeBrowseGuardian i {
        font-size: 21px;
        margin-right: 6px;
    }
    .HomeBrowsePaginationBoxPara p{
        font-size: 15px;
    }
    .HomeBrowseBoxPara p{
        font-size: 15px;
    }
   .HomeBrowseBoxh6 {
        margin-bottom: 7px;
   }
   .HomeBrowseBox{
        margin-bottom: 10px;
   }
   .HomeBrowsePaginationBoxSection {
        margin-bottom: 10px;
   }
   .BrowseHeaderButton button i {
    font-size: 18px;
    padding: 7px 10px;
    }
    .HomeBrowsePagination li a{
        width: 35px;
        height: 35px;
        font-size: 13px;
        margin-right: 8px;
    }
    .HomeBrowsePagination i{
        font-size: 15px;
        width: 35px;
        height: 35px;
    }
}
@media (max-width: 650px) and (min-width: 550px) {
    .HomeBrowseOverall{
        width: 520px;
    }
    .HomeBrowseHeaderSection{
        padding: 3px 0;
    }
    .BrowseHeaderH5 h5 {
        font-size: 20px;
    }
    .HomeBrowseImage span {
        font-size: 14px;
        padding: 4px 10px;
    }
    .HomeBrowseImage img {
        width: 22px;
        height: 22px;
    }
    .HomeBrowseNumber{
        margin-bottom: 7px;
    }
    .HomeBrowseImageSection{
        margin-bottom: 10px;
    }
    .HomeBrowseIconsSection {
        margin-bottom: 10px;
    }
    .HomeBrowseMaster span {
        padding: 4px 10px;
        font-size: 14px;
    }
    .HomeBrowseMaster i {
        font-size: 18px;
        margin-right: 6px;
    }
    .HomeBrowseIconsSpan span {
        padding: 4px 10px;
        font-size: 13px;
    }
    .HomeBrowseIconsSpan i {
        font-size: 18px;
        margin-right: 10px;
    }
    .HomeBrowseIconsTwoIcon i{
        font-size: 18px;
    }
    .HomeBrowseGuardian span {
        padding: 4px 10px;
        font-size: 14px;
    }
    .HomeBrowseGuardian i {
        font-size: 21px;
        margin-right: 6px;
    }
    .HomeBrowsePaginationBoxPara p{
        font-size: 15px;
    }
    .HomeBrowseBoxPara p{
        font-size: 15px;
    }
   .HomeBrowseBoxh6 {
        margin-bottom: 7px;
   }
   .HomeBrowseBox{
        margin-bottom: 10px;
   }
   .HomeBrowsePaginationBoxSection {
        margin-bottom: 10px;
   }
   .BrowseHeaderButton button i {
    font-size: 18px;
    padding: 7px 10px;
    }
    .HomeBrowsePagination li a{
        width: 35px;
        height: 35px;
        font-size: 13px;
        margin-right: 8px;
    }
    .HomeBrowsePagination i{
        font-size: 15px;
        width: 35px;
        height: 35px;
    }
}
@media (max-width: 550px) and (min-width: 450px) {
    .HomeBrowseOverall{
        width: 430px;
    }
    .HomeBrowseHeaderSection{
        padding: 2px 0;
    }
    .HomeBrowseBoxh6 h6{
        font-size: 18px;
    }
    .BrowseHeaderH5 h5 {
        font-size: 19px;
    }
    .HomeBrowseImage span {
        font-size: 14px;
        padding: 4px 10px;
    }
    .HomeBrowseImage img {
        width: 22px;
        height: 22px;
    }
    .HomeBrowseNumber{
        margin-bottom: 7px;
    }
    .HomeBrowseImageSection{
        margin-bottom: 10px;
    }
    .HomeBrowseIconsSection {
        margin-bottom: 10px;
    }
    .HomeBrowseMaster span {
        padding: 4px 10px;
        font-size: 14px;
    }
    .HomeBrowseMaster i {
        font-size: 18px;
        margin-right: 6px;
    }
    .HomeBrowseIconsSpan span {
        padding: 4px 10px;
        font-size: 13px;
    }
    .HomeBrowseIconsSpan i {
        font-size: 18px;
        margin-right: 10px;
    }
    .HomeBrowseIconsTwoIcon i{
        font-size: 18px;
    }
    .HomeBrowseGuardian span {
        padding: 4px 10px;
        font-size: 14px;
    }
    .HomeBrowseGuardian i {
        font-size: 21px;
        margin-right: 6px;
    }
    .HomeBrowsePaginationBoxPara p{
        font-size: 15px;
    }
    .HomeBrowseBoxPara p{
        font-size: 15px;
    }
   .HomeBrowseBoxh6 {
        margin-bottom: 7px;
   }
   .HomeBrowseBox{
        margin-bottom: 10px;
   }
   .HomeBrowsePaginationBoxSection {
        margin-bottom: 10px;
   }
   .BrowseHeaderButton button i {
    font-size: 16px;
    padding: 7px 10px;
    }
    .HomeBrowsePagination li a{
        width: 30px;
        height: 30px;
        font-size: 13px;
        margin-right: 8px;
    }
    .HomeBrowsePagination i{
        font-size: 15px;
        width: 30px;
        height: 30px;
    }
    .NextPost span{
        font-size: 13px;
        margin-right: 6px;
    }
    .HomeBrowseTextarea{
        font-size: 14px;
    }
    .HomeBrowseTextareaPart textarea::placeholder{
        font-size: 14px!important;
    }
    .HomeBrowseDropdown{
        width: 170px;
        height: 35px;
        font-size: 13px;
        padding: 6px 6px!important;
    }
    .PostButton{
        height: 35px;
    }
    .HomeBrowseDropdown i{
        font-size: 16px;
    }
    .HomeBrowseDropdownUL{
        min-width: 170px;
    }
    .HomeBrowseDropdownUL.show{
        padding: 5px 0;
    }
    .HomeBrowseDropdownUL a{
        font-size: 13px;
    }
    .PostButton button{
        font-size: 12px;
    }
}
@media (max-width: 450px) and (min-width: 400px) {
    .HomeBrowseOverall{
        width: 380px;
    }
    .HomeBrowseHeaderSection{
        padding: 2px 0;
    }
    .HomeBrowseBoxh6 h6{
        font-size: 18px;
    }
    .BrowseHeaderH5 h5 {
        font-size: 19px;
    }
    .HomeBrowseImage span {
        font-size: 14px;
        padding: 4px 10px;
    }
    .HomeBrowseImage img {
        width: 22px;
        height: 22px;
    }
    .HomeBrowseNumber{
        margin-bottom: 7px;
    }
    .HomeBrowseImageSection{
        margin-bottom: 10px;
    }
    .HomeBrowseIconsSection {
        margin-bottom: 10px;
    }
    .HomeBrowseMaster span {
        padding: 4px 10px;
        font-size: 14px;
    }
    .HomeBrowseMaster i {
        font-size: 18px;
        margin-right: 6px;
    }
    .HomeBrowseIconsSpan span {
        padding: 4px 10px;
        font-size: 13px;
    }
    .HomeBrowseIconsSpan i {
        font-size: 18px;
        margin-right: 10px;
    }
    .HomeBrowseIconsTwoIcon i{
        font-size: 18px;
    }
    .HomeBrowseGuardian span {
        padding: 4px 10px;
        font-size: 14px;
    }
    .HomeBrowseGuardian i {
        font-size: 21px;
        margin-right: 6px;
    }
    .HomeBrowsePaginationBoxPara p{
        font-size: 14px;
    }
    .HomeBrowseBoxPara p{
        font-size: 14px;
    }
   .HomeBrowseBoxh6 {
        margin-bottom: 7px;
   }
   .HomeBrowseBox{
        margin-bottom: 10px;
   }
   .HomeBrowsePaginationBoxSection {
        margin-bottom: 10px;
   }
   .BrowseHeaderButton button i {
    font-size: 16px;
    padding: 7px 10px;
    }
    .HomeBrowsePagination li a{
        width: 26px;
        height: 26px;
        font-size: 13px;
        margin-right: 5px;
    }
    .HomeBrowsePagination i{
        font-size: 13px;
        width: 26px;
        height: 26px;
    }
    .NextPost span{
        font-size: 13px;
        margin-right: 6px;
    }
    .HomeBrowseTextarea{
        font-size: 14px;
    }
    .HomeBrowseTextareaPart textarea::placeholder{
        font-size: 14px!important;
    }
    .HomeBrowseDropdown{
        width: 170px;
        height: 30px;
        font-size: 14px;
        padding: 3px 6px!important;
    }
    .PostButton button{
        font-size: 10px;
    }
    .PostButton{
        height: 30px;
    }
    .HomeBrowseDropdown i{
        font-size: 16px;
    }
    .HomeBrowseDropdownUL{
        min-width: 170px;
    }
    .HomeBrowseDropdownUL.show{
        padding: 5px 0;
    }
    .HomeBrowseDropdownUL a{
        font-size: 13px;
    }
}
@media (max-width: 400px) and (min-width: 350px) {
    .HomeBrowseOverall{
        width: 330px;
    }
    .HomeBrowseHeaderSection{
        padding: 0px 0;
    }
    .HomeBrowseBoxh6 h6{
        font-size: 16px;
        line-height: 26px;
    }
    .BrowseHeaderH5 h5 {
        font-size: 16px;
    }
    .HomeBrowseImage span {
        font-size: 13px;
        padding: 4px 10px;
    }
    .HomeBrowseImage img {
        width: 20px;
        height: 20px;
    }
    .HomeBrowseNumber{
        margin-bottom: 7px;
    }
    .HomeBrowseImageSection{
        margin-bottom: 10px;
    }
    .HomeBrowseIconsSection {
        margin-bottom: 10px;
    }
    .HomeBrowseNumber span{
        font-size: 15px;
    }
    .HomeBrowseMaster span {
        padding: 4px 10px;
        font-size: 13px;
    }
    .HomeBrowseMaster i {
        font-size: 18px;
        margin-right: 6px;
    }
    .HomeBrowseIconsSpan span {
        padding: 4px 10px;
        font-size: 13px;
    }
    .HomeBrowseIconsSpan i {
        font-size: 18px;
        margin-right: 10px;
    }
    .HomeBrowseIconsTwoIcon i{
        font-size: 18px;
    }
    .HomeBrowseGuardian span {
        padding: 4px 10px;
        font-size: 13px;
    }
    .HomeBrowseGuardian i {
        font-size: 21px;
        margin-right: 6px;
    }
    .HomeBrowsePaginationBoxPara p{
        font-size: 14px;
    }
    .HomeBrowseBoxPara p{
        font-size: 14px;
    }
   .HomeBrowseBoxh6 {
        margin-bottom: 4px;
   }
   .HomeBrowseBox{
        margin-bottom: 10px;
   }
   .HomeBrowsePaginationBoxSection {
        margin-bottom: 10px;
   }
   .BrowseHeaderButton button i {
    font-size: 14px;
    padding: 5px 8px;
    }
    .HomeBrowsePagination li a{
        width: 26px;
        height: 26px;
        font-size: 13px;
        margin-right: 5px;
    }
    .HomeBrowsePagination i{
        font-size: 13px;
        width: 26px;
        height: 26px;
    }
    .NextPost span{
        font-size: 12px;
        margin-right: 6px;
    }
    .HomeBrowseTextarea{
        font-size: 13px;
        padding: 6px 6px;
    }
    .HomeBrowseTextareaPart textarea::placeholder{
        font-size: 13px!important;
    }
    .HomeBrowseDropdown{
        width: 140px;
        height: 30px;
        font-size: 11px;
        padding: 3px 6px!important;
    }
    .HomeBrowseDropdown i{
        font-size: 14px;
    }
    .HomeBrowseDropdownUL{
        min-width: 140px;
    }
    .HomeBrowseDropdownUL li{
        padding: 0px 6px;
    }
    .HomeBrowseDropdownUL.show{
        padding: 3px 0;
    }
    .HomeBrowseDropdownUL a{
        font-size: 13px;
    }
    .PostButton button{
        font-size: 10px;
        padding: 0px 5px;
    }
    .PostButton{
        height: 30px;
    }
    .PostHeader span{
        font-size: 14px;
    }
}
@media (max-width: 350px) and (min-width: 300px) {
    .HomeBrowseOverall{
        width: 280px;
    }
    .HomeBrowseHeaderSection{
        padding: 0px 0;
    }
    .HomeBrowseBoxh6{
        margin-bottom: 4px;
    }
    .HomeBrowseBoxh6 h6{
        font-size: 16px;
        line-height: 26px;
    }
    .BrowseHeaderH5 h5 {
        font-size: 15px;
    }
    .HomeBrowseImage span {
        font-size: 13px;
        padding: 4px 10px;
    }
    .HomeBrowseImage img {
        width: 20px;
        height: 20px;
    }
    .HomeBrowseNumber{
        margin-bottom: 7px;
    }
    .HomeBrowseImageSection{
        margin-bottom: 10px;
    }
    .HomeBrowseIconsSection {
        margin-bottom: 10px;
    }
    .HomeBrowseNumber span{
        font-size: 15px;
    }
    .HomeBrowseMaster span {
        padding: 4px 10px;
        font-size: 13px;
    }
    .HomeBrowseMaster i {
        font-size: 18px;
        margin-right: 6px;
    }
    .HomeBrowseIconsSpan span {
        padding: 4px 10px;
        font-size: 13px;
    }
    .HomeBrowseIconsSpan i {
        font-size: 18px;
        margin-right: 10px;
    }
    .HomeBrowseIconsTwoIcon i{
        font-size: 18px;
    }
    .HomeBrowseGuardian span {
        padding: 4px 10px;
        font-size: 13px;
    }
    .HomeBrowseGuardian i {
        font-size: 21px;
        margin-right: 6px;
    }
    .HomeBrowsePaginationBoxPara p{
        font-size: 14px;
    }
    .HomeBrowseBoxPara p{
        font-size: 14px;
    }
   .HomeBrowseBox{
        margin-bottom: 10px;
   }
   .HomeBrowsePaginationBoxSection {
        margin-bottom: 7px;
   }
   .BrowseHeaderButton button i {
    font-size: 14px;
    padding: 5px 8px;
    }
    .HomeBrowsePagination li a{
        width: 23px;
        height: 23px;
        font-size: 13px;
        margin-right: 4px;
    }
    .HomeBrowsePagination i{
        font-size: 13px;
        width: 23px;
        height: 23px;
    }
    .NextPost span{
        font-size: 11px;
        margin-right: 3px;
    }
    .HomeBrowseInputBox {
        padding: 7px 7px;
    }
    .HomeBrowseTextarea{
        font-size: 13px;
        padding: 6px 6px;
    }
    .HomeBrowseTextareaPart textarea::placeholder{
        font-size: 13px!important;
    }
    .HomeBrowseDropdown{
        width: 110px;
        height: 25px;
        font-size: 10px;
        padding: 1px 3px!important;
    }
    .HomeBrowseDropdown i{
        font-size: 12px;
        margin-right: 4px!important;
    }
    .HomeBrowseDropdownUL{
        min-width: 110px;
    }
    .HomeBrowseDropdownUL li{
        padding: 0px 6px;
    }
    .HomeBrowseDropdownUL.show{
        padding: 3px 0;
    }
    .HomeBrowseDropdownUL a{
        font-size: 12px;
    }
    .PostButton button{
        font-size: 8px;
        padding: 0px 5px;
    }
    .PostButton{
        height: 25px;
    }
    .PostHeader span{
        font-size: 13px;
    }
}
@media (max-width: 300px) and (min-width: 275px) {
    .HomeBrowseOverall{
        width: 260px;
    }
    .HomeBrowseHeaderSection{
        padding: 0px 0;
    }
    .HomeBrowseBoxh6 h6{
        font-size: 15px;
        line-height: 22px;
    }
    .HomeBrowseBoxUniHead {
        margin-bottom: 4px;
    }
    .BrowseHeaderH5 h5 {
        font-size: 15px;
    }
    .HomeBrowseImage span {
        font-size: 12px;
        padding: 4px 10px;
    }
    .HomeBrowseImage img {
        width: 20px;
        height: 20px;
    }
    .HomeBrowseNumber{
        margin-bottom: 5px;
    }
    .HomeBrowseImageSection{
        margin-bottom: 7px;
    }
    .HomeBrowseIconsSection {
        margin-bottom: 7px;
    }
    .HomeBrowseNumber span{
        font-size: 14px;
    }
    .HomeBrowseMaster span {
        padding: 4px 10px;
        font-size: 12px;
    }
    .HomeBrowseMaster i {
        font-size: 17px;
        margin-right: 6px;
    }
    .HomeBrowseIconsSpan span {
        padding: 4px 10px;
        font-size: 11px;
    }
    .HomeBrowseIconsSpan i {
        font-size: 17px;
        margin-right: 10px;
    }
    .HomeBrowseIconsTwoIcon i{
        font-size: 17px;
    }
    .HomeBrowseGuardian span {
        padding: 4px 10px;
        font-size: 12px;
    }
    .HomeBrowseGuardian i {
        font-size: 20px;
        margin-right: 6px;
    }
    .HomeBrowsePaginationBoxPara p{
        font-size: 13px;
    }
    .HomeBrowseBoxPara p{
        font-size: 13px;
    }
   .HomeBrowseBoxh6 {
        margin-bottom: 3px;
   }
   .HomeBrowseBox{
        margin-bottom: 10px;
   }
   .HomeBrowsePaginationBoxSection {
        margin-bottom: 7px;
   }
   .BrowseHeaderButton button i {
    font-size: 14px;
    padding: 5px 8px;
    }
    .HomeBrowsePagination li a{
        width: 20px;
        height: 20px;
        font-size: 11px;
        margin-right: 3px;
    }
    .HomeBrowsePagination i{
        font-size: 11px;
        width: 20px;
        height: 20px;
    }
    .NextPost span{
        font-size: 10px;
        margin-right: 3px;
    }
    .HomeBrowseInputBox {
        padding: 7px 7px;
    }
    .HomeBrowseTextarea{
        font-size: 13px;
        padding: 6px 6px;
    }
    .HomeBrowseTextareaPart textarea::placeholder{
        font-size: 13px!important;
    }
    .HomeBrowseDropdown{
        width: 110px;
        height: 22px;
        font-size: 10px;
        padding: 1px 3px!important;
    }
    .HomeBrowseDropdown i{
        font-size: 12px;
        margin-right: 4px!important;
    }
    .HomeBrowseDropdownUL{
        min-width: 110px;
    }
    .HomeBrowseDropdownUL li{
        padding: 0px 6px;
    }
    .HomeBrowseDropdownUL.show{
        padding: 3px 0;
    }
    .HomeBrowseDropdownUL a{
        font-size: 12px;
    }
    .PostButton button{
        font-size: 8px;
        padding: 0px 5px;
    }
    .PostButton{
        height: 22px;
    }
    .PostHeader span{
        font-size: 13px;
    }
}
@media (max-width: 275px) {
    .HomeBrowseOverall{
        width: 260px;
    }
    .HomeBrowseHeaderSection{
        padding: 0px 0;
    }
    .HomeBrowseBoxh6 h6{
        font-size: 15px;
        line-height: 22px;
    }
    .HomeBrowseBoxUniHead {
        margin-bottom: 4px;
    }
    .BrowseHeaderH5 h5 {
        font-size: 15px;
    }
    .HomeBrowseImage span {
        font-size: 12px;
        padding: 4px 10px;
    }
    .HomeBrowseImage img {
        width: 20px;
        height: 20px;
    }
    .HomeBrowseNumber{
        margin-bottom: 5px;
    }
    .HomeBrowseImageSection{
        margin-bottom: 7px;
    }
    .HomeBrowseIconsSection {
        margin-bottom: 7px;
    }
    .HomeBrowseNumber span{
        font-size: 14px;
    }
    .HomeBrowseMaster span {
        padding: 4px 10px;
        font-size: 12px;
    }
    .HomeBrowseMaster i {
        font-size: 17px;
        margin-right: 6px;
    }
    .HomeBrowseIconsSpan span {
        padding: 4px 10px;
        font-size: 11px;
    }
    .HomeBrowseIconsSpan i {
        font-size: 17px;
        margin-right: 10px;
    }
    .HomeBrowseIconsTwoIcon i{
        font-size: 17px;
    }
    .HomeBrowseGuardian span {
        padding: 4px 10px;
        font-size: 12px;
    }
    .HomeBrowseGuardian i {
        font-size: 20px;
        margin-right: 6px;
    }
    .HomeBrowsePaginationBoxPara p{
        font-size: 13px;
    }
    .HomeBrowseBoxPara p{
        font-size: 13px;
    }
   .HomeBrowseBoxh6 {
        margin-bottom: 3px;
   }
   .HomeBrowseBox{
        margin-bottom: 10px;
   }
   .HomeBrowsePaginationBoxSection {
        margin-bottom: 7px;
   }
   .BrowseHeaderButton button i {
    font-size: 14px;
    padding: 5px 8px;
    }
    .HomeBrowsePagination li a{
        width: 20px;
        height: 20px;
        font-size: 11px;
        margin-right: 3px;
    }
    .HomeBrowsePagination i{
        font-size: 11px;
        width: 20px;
        height: 20px;
    }
    .NextPost span{
        font-size: 10px;
        margin-right: 3px;
    }
    .HomeBrowseInputBox {
        padding: 7px 7px;
    }
    .HomeBrowseTextarea{
        font-size: 13px;
        padding: 6px 6px;
    }
    .HomeBrowseTextareaPart textarea::placeholder{
        font-size: 13px!important;
    }
    .HomeBrowseDropdown{
        width: 110px;
        height: 22px;
        font-size: 10px;
        padding: 1px 3px!important;
    }
    .HomeBrowseDropdown i{
        font-size: 12px;
        margin-right: 4px!important;
    }
    .HomeBrowseDropdownUL{
        min-width: 110px;
    }
    .HomeBrowseDropdownUL li{
        padding: 0px 6px;
    }
    .HomeBrowseDropdownUL.show{
        padding: 3px 0;
    }
    .HomeBrowseDropdownUL a{
        font-size: 12px;
    }
    .PostButton button{
        font-size: 8px;
        padding: 0px 5px;
    }
    .PostButton{
        height: 22px;
    }
    .PostHeader span{
        font-size: 13px;
    }
}

