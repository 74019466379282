.paidOverall{
    width: 100%;
    height: 100vh;
    min-height: 500px;
    background-color: #111111;
}
.PaidSpan span{
    font-size: 14px;
    font-weight: 700;
    color: #05054e;
    padding: 7px 20px;
    background-color: #22b427;
    border-radius: 15px;
}