.homecontoverall {
    width: 1156px;
    margin: 0 auto;
    padding-top: 72px;
}
.colorLink{
    color:#65B168;
}
.colorLinkPink{
    color:#F6655A;
}
.choose h5 {
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    color: #19191D;
}
.choose button i {
    font-size: 20px;
    padding: 7px 10px;
    border-radius: 5px;
    color: #B4B4BB;
    background-color: #EDEDF0;
}
.choosebox {
    width: 369px;
    background-color: #ECF7ED;
    border: solid 2px #A9D3AB;
    border-radius: 10px;
    padding: 12px 16px;
    margin-bottom: 37px;
}

.chooseboxpublic {
    width: 369px;
    background-color: #ECF7ED;
    border: solid 2px #A9D3AB;
    border-radius: 10px;
    padding: 12px 16px;
    margin-bottom: 37px;
}
.chooseboxprivate {
    width: 369px;
    background-color: #FEF2F1;
    border: solid 2px #dcc9c8;
    border-radius: 10px;
    padding: 12px 16px;
    margin-bottom: 37px;
}

.chooseboxpink {
    background-color: #FEE8E7;
    border: solid 2px #FAA9A3;
}

.choosebox p,.chooseboxprivate p,.chooseboxpublic p {
    color: #2B662E;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    margin-bottom: 28px;
    letter-spacing: 1px;
}

.choosebox h6 ,.chooseboxprivate h6,.chooseboxpublic h6 {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    color: #132C14;
}

.chooseboxspan {
    width: auto;
    background-color: #fff;
    border-radius: 25px;
    float: left;
}
.chooseboxspan button {
    padding: 4px 10px;
    color: #65B168;
    display: flex;
    align-items: center;
}
.chooseboxspan img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    object-fit: cover;
}

.chooseboxspan span {
    padding: 4px 10px;
    color: #65B168;
    display: flex;
    align-items: center;
}

.chooseboxspan i {
    font-size: 22px;
}

.chooseboxspanpink span {
    color: #F6655A;
}

.thumbsup span {
    color: #4F91FF;
}

.floatleftclass{
    float: left;
}

.thumbsup i {
    color: #9DC2FF;
}

.user span {
    color: #EF6292;
}

.user i {
    color: #EF6292;
}

.random span {
    color: #FB982E;
}

.random i {
    color: #FDC68B;
}

@media (max-width: 1350px) and (min-width: 1151px) {
    .homecontoverall {
        width: 970px;
        margin: 0 auto;
    }

    .choose h5 {
        font-size: 19px;
    }

    .choosebox {
        width: 307px;
        margin-bottom: 26px;
    }

    .choosebox p {
        font-size: 13px;
        line-height: 21px;
        margin-bottom: 20px;
    }

    .choosebox h6 {
        font-size: 16px;
        line-height: 25px;
    }

    .chooseboxspan img {
        width: 20px;
        height: 20px;
    }

    .chooseboxspan span {
        padding: 5px 8px;
        font-size: 13px;
    }

    .chooseboxspan i {
        font-size: 19px;
    }

    .choose button i {
        font-size: 15px;
    }
}

@media (max-width: 1151px) and (min-width: 950px) {
    .homecontoverall {
        width: 830px;
        margin: 0 auto;
    }

    .choose h5 {
        font-size: 20px;
    }

    .choosebox {
        width: 403px;
        margin-bottom: 26px;
    }

    .choosebox p {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 18px;
    }

    .choosebox h6 {
        font-size: 18px;
        line-height: 27px;
    }

    .chooseboxspan img {
        width: 22px;
        height: 22px;
    }

    .chooseboxspan span {
        padding: 5px 10px;
        font-size: 14px;
    }

    .chooseboxspan i {
        font-size: 20px;
    }

    .choose button i {
        font-size: 15px;
    }

    .choosebox:nth-child(even) {
        margin-right: 0px !important;
    }

    .choosebox:nth-child(odd) {
        margin-right: 24px !important;
    }
}

@media (max-width: 950px) and (min-width: 801px) {
    .homecontoverall {
        width: 700px;
        margin: 0 auto;
    }

    .choose h5 {
        font-size: 19px;
    }

    .choosebox {
        width: 338px;
        margin-bottom: 26px;
    }

    .choosebox p {
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 18px;
    }

    .choosebox h6 {
        font-size: 17px;
        line-height: 25px;
    }

    .chooseboxspan img {
        width: 22px;
        height: 22px;
    }

    .chooseboxspan span {
        padding: 5px 10px;
        font-size: 14px;
    }

    .chooseboxspan i {
        font-size: 20px;
    }

    .choose button i {
        font-size: 15px;
    }

    .choosebox:nth-child(even) {
        margin-right: 0px !important;
    }

    .choosebox:nth-child(odd) {
        margin-right: 24px !important;
    }
}

@media (max-width: 801px) and (min-width: 750px) {
    .homecontoverall {
        width: 580px;
        margin: 0 auto;
    }

    .choose h5 {
        font-size: 22px;
    }

    .choosebox {
        width: 580px;
        margin-bottom: 26px;
        margin-right: 0px !important;
    }

    .choosebox p {
        font-size: 22px;
        line-height: 34px;
        margin-bottom: 28px;
    }

    .choosebox h6 {
        font-size: 27px;
        line-height: 42px;
    }

    .chooseboxspan img {
        width: 31px;
        height: 31px;
    }

    .chooseboxspan span {
        padding: 5px 16px;
        font-size: 17px;
    }

    .chooseboxspan i {
        font-size: 26px;
    }

    .choose button i {
        font-size: 18px;
    }
}

@media (max-width: 750px) and (min-width: 650px) {
    .homecontoverall {
        width: 560px;
        margin: 0 auto;
    }

    .choose h5 {
        font-size: 22px;
    }

    .choosebox {
        width: 560px;
        margin-bottom: 26px;
        margin-right: 0px !important;
    }

    .choosebox p {
        font-size: 21px;
        line-height: 31px;
        margin-bottom: 24px;
    }

    .choosebox h6 {
        font-size: 24px;
        line-height: 38px;
    }

    .chooseboxspan img {
        width: 31px;
        height: 31px;
    }

    .chooseboxspan span {
        padding: 5px 16px;
        font-size: 18px;
    }

    .chooseboxspan i {
        font-size: 28px;
    }

    .choose button i {
        font-size: 18px;
    }
}
@media (max-width: 650px) and (min-width: 550px) {
    .homecontoverall {
        width: 460px;
        margin: 0 auto;
    }

    .choose h5 {
        font-size: 21px;
    }

    .choosebox {
        width: 460px;
        margin-bottom: 26px;
        margin-right: 0px !important;
    }

    .choosebox p {
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 28px;
    }

    .choosebox h6 {
        font-size: 22px;
        line-height: 37px;
    }

    .chooseboxspan img {
        width: 31px;
        height: 31px;
    }

    .chooseboxspan span {
        padding: 5px 16px;
        font-size: 16px;
    }

    .chooseboxspan i {
        font-size: 24px;
    }

    .choose button i {
        font-size: 18px;
    }
}
@media (max-width: 550px) and (min-width: 450px) {
    .homecontoverall {
        width: 380px;
        margin: 0 auto;
    }

    .choose h5 {
        font-size: 19px;
    }

    .choosebox {
        width: 380px;
        margin-bottom: 26px;
        margin-right: 0px !important;
    }

    .choosebox p {
        font-size: 17px;
        line-height: 27px;
        margin-bottom: 22px;
    }

    .choosebox h6 {
        font-size: 21px;
        line-height: 35px;
    }

    .chooseboxspan img {
        width: 27px;
        height: 27px;
    }

    .chooseboxspan span {
        padding: 5px 12px;
        font-size: 14px;
    }

    .chooseboxspan i {
        font-size: 22px;
    }

    .choose button i {
        font-size: 16px;
    }
}

@media (max-width: 450px) and (min-width: 400px){
    /* ipad pro and below */
    .homecontoverall {
        width: 330px;
        margin: 0 auto;
    }

    .choose h5 {
        font-size: 18px;
    }

    .choosebox {
        width: 330px;
        margin-bottom: 26px;
        margin-right: 0px !important;
    }

    .choosebox p {
        font-size: 14px;
        line-height: 27px;
        margin-bottom: 17px;
    }

    .choosebox h6 {
        font-size: 19px;
        line-height: 33px;
    }

    .chooseboxspan img {
        width: 24px;
        height: 24px;
    }

    .chooseboxspan span {
        padding: 5px 12px;
        font-size: 13px;
    }

    .chooseboxspan i {
        font-size: 22px;
    }

    .choose button i {
        font-size: 14px;
    }
}
@media (max-width: 400px) and (min-width: 300px){
    /* ipad pro and below */
    .homecontoverall {
        width: 275px;
        margin: 0 auto;
    }

    .choose h5 {
        font-size: 15px;
    }

    .choosebox {
        width: 275px;
        margin-bottom: 26px;
        margin-right: 0px !important;
    }

    .choosebox p {
        font-size: 11px;
        line-height: 23px;
        margin-bottom: 15px;
    }

    .choosebox h6 {
        font-size: 16px;
        line-height: 30px;
    }

    .chooseboxspan img {
        width: 24px;
        height: 24px;
    }

    .chooseboxspan span {
        padding: 4px 9px;
        font-size: 11px;
    }

    .chooseboxspan i {
        font-size: 20px;
    }

    .choose button i {
        font-size: 13px;
    }
}
@media (max-width: 300px){
    .homecontoverall {
        width: 225px;
        margin: 0 auto;
    }

    .choose h5 {
        font-size: 12px;
    }

    .choosebox {
        width: 225px;
        margin-bottom: 26px;
        margin-right: 0px !important;
    }

    .choosebox p {
        font-size: 13px;
        line-height: 20px;
        margin-bottom: 15px;
    }

    .choosebox h6 {
        font-size: 13px;
        line-height: 24px;
    }

    .chooseboxspan img {
        width: 18px;
        height: 18px;
    }

    .chooseboxspan span {
        padding: 3px 8px;
        font-size: 11px;
    }

    .chooseboxspan i {
        font-size: 14px;
    }

    .choose button i {
        font-size: 9px;
    }
}
