.PublicInformationOverall{
    width: 1156px;
    margin: 0 auto;
    padding-top: 100px;
}
.MyProfileRight{
    border: 1px solid #E1E1E3;
    border-radius: 12px;
}
.PublicInformationSection{
    padding: 25px 30px 25px 40px;
    border-bottom: 1px solid #E1E1E3;
}
.MyUniverseSection{
    padding: 25px 30px 25px 40px;
    border-bottom: 1px solid #E1E1E3;
}
.LastBoxBorder{
    border: 1px solid #E1E1E3!important;
    border-radius: 0px 0px 12px 12px!important;
}
.PublicInformationImg img{
    width: 170px;
    height: 170px;
    object-fit: cover;
    border-radius: 50%;
}
.PublicInformationImgSection{
    margin-right: 30px;
}
.PublicInformationImgEdit{
    width: 56px;
    height: 56px;
    background-color: #5A5B6A;
    border-radius: 50%;
    bottom: -1px;
    right: 3px;
    box-shadow: 1px 2px #E1E1E3;
}
.PublicInformationImgEdit i{
    font-size: 18px;
    color: #F7F7FA;
}
.MyProfileNameSection{
    margin-bottom: 15px;
}
.PublicInformationul li i{
    width: 30px;
    height: 30px;
    font-size: 17px;
    background-color: #F1F1F9;
    color: #B4B4BB;
    border-radius: 50%;
    margin-right: 7px;
}
.PublicInformationul li{
    margin-bottom: 12px;
}
.PublicInformationul li a{
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #19191D;
    cursor: pointer;
}
.PublicInformationConth5 h5{
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    color: #19191D;
}
.MyUniverseh6 h6{
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    color: #19191D;
}
.ListUniversesSpan{
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    color: #787885;
}
.MyUniversePrivate{
    margin-bottom: 24px;
}
.TimeUpgradeSection{    
    margin-bottom: 30px;
    padding: 12px 16px;
    background-color: #FEF2F1;
    border-radius: 10px;
    box-shadow: 0px 2px #dcc9c8;
}
.TimeUpgradeLogo{
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #C8372D;
    margin-right: 10px;
    margin-top: 9px;
}
.TimeUpgradeLogo i{
    color: #F7F7FA;
    font-size: 19px;
    top: 3px;
    left: 7px;
}
.TimeUpgradeh5 h5{
    font-size: 24px;
    font-weight: 700;
    line-height: 40px;
    color: #43130F;
}
.TimeUpgradeP p{
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    color: #43130F;
    letter-spacing: 1px;
}
.upgratenowspan span{
    font-size: 14px;
    font-weight: 700;
    padding: 12px 16px;
    background-color: #4F91FF;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
}
.MyUniverseBox{
    width: 100%!important;
    background-color: #ECF7ED;
    border: solid 2px #A9D3AB;
    border-radius: 10px;
    padding: 12px 16px;
    box-shadow: -1px 2px #A9D3AB;
}
.MyUniverseBoxpublic{
    width: 100%!important;
    background-color: #ECF7ED;
    border: solid 2px #A9D3AB;
    border-radius: 10px;
    padding: 12px 16px;
    box-shadow: -1px 2px #A9D3AB;
}
.MyUniverseBoxprivate{
    width: 100%!important;
    background-color: #FEF2F1;
    border-radius: 10px;
    box-shadow: 0px 2px #dcc9c8;
    /* background-color: #ECF7ED;
    border: solid 2px #A9D3AB; */
    border-radius: 10px;
    padding: 12px 16px;
    /* box-shadow: -1px 2px #A9D3AB; */
}
.MyUniverseBoxIcons{
    margin-top: 5px;
    margin-bottom: 5px;
}
.MyUniverseBox p {
    color: #2B662E;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 1px;
}
.MyUniverseBoxh6 h6 {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    color: #132C14;
}
.MyUniverseBoxSpan{
    width: auto;
    background-color: #fff;
    border-radius: 25px;
    float: left;
}
.MyUniverseBoxSpan span {
    padding: 4px 10px;
    color: #65B168;
    display: flex;
    align-items: center;
}
.MyUniverseBoxSpan i {
    font-size: 20px;
}
.Universethumbsup span {
    color: #4F91FF;
    font-size: 14px;
}
.Universethumbsup i {
    color: #9DC2FF;
}
.Universeuser span {
    color: #EF6292;
    font-size: 14px;
}
.Universeuser i {
    color: #EF6292;
}
.Universerandom span {
    color: #FB982E;
    font-size: 14px;
}
.Universerandom i {
    color: #FDC68B;
}

@media (max-width: 1250px) and (min-width: 1100px){
    .PublicInformationOverall{
        width: 980px;
    }
    .PublicInformationImgSection{
        margin-right: 50px;
    }
    .PublicInformationImgEdit{
        width: 56px;
        height: 56px;
        bottom: -1px;
        right: -30px;
    }
    .PublicInformationImgEdit i{
        font-size: 18px;
    }    
}
@media (max-width: 1100px) and (min-width: 992px){
    .PublicInformationOverall{
        width: 900px;
    }
    .PublicInformationImgSection{
        margin-right: 70px;
    }
    .PublicInformationImgEdit{
        width: 56px;
        height: 56px;
        bottom: -1px;
        right: -43px;
    }
    .PublicInformationImgEdit i{
        font-size: 18px;
    }  
}
@media (max-width: 992px) and (min-width: 850px){
    .PublicInformationOverall{
        width: 770px;
    }
    .PublicInformationImgSection{
        margin-right: 80px;
    }
    .PublicInformationImgEdit{
        width: 56px;
        height: 56px;
        bottom: -1px;
        right: -60px;
    }
    .PublicInformationImgEdit i{
        font-size: 18px;
    } 
} 
@media (max-width: 850px) and (min-width: 750px){
    .PublicInformationOverall{
        width: 670px;
    }
    .PublicInformationImgSection{
        margin-right: 80px;
    }
    .PublicInformationImg img{
        width: 145px;
        height: 145px;
        object-fit: cover;
        border-radius: 50%;
    }
    .PublicInformationImgEdit{
        width: 50px;
        height: 50px;
        bottom: 7px;
        right: -55px;
    }
    .PublicInformationImgEdit i{
        font-size: 18px;
    }
}
@media (max-width: 750px) and (min-width: 600px){
    .PublicInformationOverall{
        width: 550px;
    }
    .PublicInformationSection{
        padding: 15px 15px 0px 18px;
    }
    .PublicInformationImgSection{
        margin-right: 90px;
    }
    .PublicInformationImg img{
        width: 145px;
        height: 145px;
        object-fit: cover;
        border-radius: 50%;
    }
    .PublicInformationImgEdit{
        width: 50px;
        height: 50px;
        bottom: 7px;
        right: -70px;
    }
    .PublicInformationImgEdit i{
        font-size: 18px;
    }
    .MyUniverseh6 h6{
        font-size: 20px;
        line-height: 30px;
    }
    .TimeUpgradeh5 h5{
        font-size: 22px!important;
    }
    .MyUniverseBoxSpan span{
        font-size: 14px;
    }
}
@media (max-width: 600px) and (min-width: 500px){
    .PublicInformationOverall{
        width: 450px;
    }
    .PublicInformationSection{
        padding: 15px 15px 0px 18px;
    }
    .PublicInformationImgSection{
        margin-right: 100px;
    }
    .PublicInformationImg img{
        width: 135px;
        height: 135px;
        object-fit: cover;
        border-radius: 50%;
    }
    .PublicInformationImgEdit{
        width: 50px;
        height: 50px;
        bottom: 7px;
        right: -80px;
    }
    .PublicInformationImgEdit i{
        font-size: 18px;
    }
    .PublicInformationConth5 h5{
        font-size: 22px;
        line-height: 36px;
    }
    .TimeUpgradeh5 h5{
        font-size: 20px!important;
    }
    .MyUniverseh6 h6{
        font-size: 18px;
        line-height: 30px;
    }
    .MyProfileInformation li{
        font-size: 13px;
    }
    .MyUniverseSection {
        padding: 25px 30px 25px 30px;
    }
    .MyUniverseBoxSpan span{
        font-size: 14px;
    }
}
@media (max-width: 500px) and (min-width: 400px){
    .PublicInformationOverall{
        width: 370px;
    }
    .PublicInformationSection{
        padding: 25px 30px 0px 22px;
        border-bottom: 1px solid #E1E1E3;
    }
    .PublicInformationImgSection{
        margin-right: 80px;
    }
    .PublicInformationImg img{
        width: 115px;
        height: 115px;
    }
    .PublicInformationImgEdit{
        width: 40px;
        height: 40px;
        bottom: 18px;
        right: -62px;
    }
    .PublicInformationImgEdit i{
        font-size: 16px;
    }
    .PublicInformationul li i{
        width: 30px;
        height: 30px;
        font-size: 17px;
        margin-right: 7px;
    }
    .PublicInformationul li{
        margin-bottom: 12px;
    }
    .PublicInformationul li a{
        font-size: 13px;
        line-height: 20px;
    }
    .PublicInformationConth5 h5{
        font-size: 19px;
        line-height: 36px;
    }
    .MyProfileh6 h6{
        font-size: 15px;
        line-height: 0px;
    }
    .MyProfileA a{
        font-size: 13px!important;
    }
    .MyUniverseSection{
        padding: 15px 18px 22px 18px;
    }
    .MyUniversePrivate {
        margin-bottom: 12px;
    }
    .MyUniverseBoxSpan span{
        font-size: 14px;
    }
}
@media (max-width: 400px) and (min-width: 350px){
    .PublicInformationOverall{
        width: 320px;
    }
    .PublicInformationSection{
        padding: 13px 13px 0px 13px;
    }
    .PublicInformationImgSection{
        margin-right: 70px;
    }
    .PublicInformationImg img{
        width: 100px;
        height: 100px;
    }
    .PublicInformationImgEdit{
        width: 35px;
        height: 35px;
        bottom: 32px;
        right: -58px;
    }
    .PublicInformationImgEdit i{
        font-size: 13px;
    }
    .PublicInformationul li i{
        width: 30px;
        height: 30px;
        font-size: 17px;
        margin-right: 7px;
    }
    .PublicInformationul li{
        margin-bottom: 12px;
    }
    .PublicInformationul li a{
        font-size: 12px;
        line-height: 20px;
    }
    .PublicInformationConth5 h5{
        font-size: 18px;
        line-height: 36px;
    }
    .MyProfileh6 h6{
        font-size: 15px;
        line-height: 0px;
    }
    .MyUniverseSection{
        padding: 15px 18px 22px 18px;
    }
    .MyUniversePrivate {
        margin-bottom: 12px;
    }
    .MyUniverseh6 h6{
        font-size: 17px!important;
    }
    .ListUniversesSpan {
        font-size: 14px!important;
    }
    .TimeUpgradeh5 h5{
        font-size: 20px!important;
    }
    .TimeUpgradeP p{
        font-size: 14px!important;
    }
    .MyUniverseBox p{
        font-size: 14px!important;
    }
    .MyUniverseBoxh6 h6{
        font-size: 18px!important;
    }
    .MyUniverseBoxSpan span{
        font-size: 14px;
    }
}
@media (max-width: 350px) and (min-width: 275px){
    .PublicInformationOverall{
        width: 270px;
    }
    .PublicInformationSection{
        padding: 13px 13px 7px 13px;
    }
    .PublicInformationImgSection{
        margin-right: 50px;
    }
    .PublicInformationImg img{
        width: 80px;
        height: 80px;
    }
    .PublicInformationImgEdit{
        width: 25px;
        height: 25px;
        bottom: 23px;
        right: -42px;
    }
    .PublicInformationImgEdit i{
        font-size: 10px;
    }
    .PublicInformationul li i{
        width: 25px;
        height: 25px;
        font-size: 13px;
        margin-right: 7px;
    }
    .PublicInformationul li{
        margin-bottom: 6px;
    }
    .PublicInformationul li a{
        font-size: 10px;
        line-height: 18px;
    }
    .PublicInformationConth5 h5{
        font-size: 15px;
        line-height: 36px;
    }
    .MyProfileh6 h6{
        font-size: 15px;
        line-height: 0px;
    }
    .MyUniverseSection{
        padding: 15px 18px 22px 18px;
    }
    .MyUniversePrivate {
        margin-bottom: 12px;
    }
    .MyUniverseh6 h6{
        font-size: 17px!important;
        line-height: 22px;
    }
    .ListUniversesSpan {
        font-size: 13px!important;
    }
    .TimeUpgradeh5 h5{
        font-size: 18px!important;
    }
    .TimeUpgradeP p{
        font-size: 12px!important;
    }
    .MyUniverseBox p{
        font-size: 13px!important;
    }
    .MyUniverseBoxh6 h6{
        font-size: 16px!important;
    }
    .upgratenowspan span{
        font-size: 12px!important;
        padding: 9px 13px;
    }
    .Universethumbsup span{
        font-size: 12px!important;
    }
    .MyUniverseBoxSpan i{
        font-size: 18px!important;
        margin-right: 7px!important;
    }
    .MyUniverseBoxSpan span{
        padding: 2px 8px;
        font-size: 12px;
    }
}
@media (max-width: 275px){
    .PublicInformationOverall{
        width: 270px;
    }
    .PublicInformationSection{
        padding: 13px 13px 7px 13px;
    }
    .PublicInformationImgSection{
        margin-right: 50px;
    }
    .PublicInformationImg img{
        width: 80px;
        height: 80px;
    }
    .PublicInformationImgEdit{
        width: 25px;
        height: 25px;
        bottom: 23px;
        right: -42px;
    }
    .PublicInformationImgEdit i{
        font-size: 10px;
    }
    .PublicInformationul li i{
        width: 25px;
        height: 25px;
        font-size: 13px;
        margin-right: 7px;
    }
    .PublicInformationul li{
        margin-bottom: 6px;
    }
    .PublicInformationul li a{
        font-size: 10px;
        line-height: 18px;
    }
    .PublicInformationConth5 h5{
        font-size: 15px;
        line-height: 36px;
    }
    .MyProfileh6 h6{
        font-size: 15px;
        line-height: 0px;
    }
    .MyUniverseSection{
        padding: 15px 18px 22px 18px;
    }
    .MyUniversePrivate {
        margin-bottom: 12px;
    }
    .MyUniverseh6 h6{
        font-size: 17px!important;
        line-height: 22px;
    }
    .ListUniversesSpan {
        font-size: 13px!important;
    }
    .TimeUpgradeh5 h5{
        font-size: 18px!important;
    }
    .TimeUpgradeP p{
        font-size: 12px!important;
    }
    .MyUniverseBox p{
        font-size: 13px!important;
    }
    .MyUniverseBoxh6 h6{
        font-size: 16px!important;
    }
    .upgratenowspan span{
        font-size: 12px!important;
        padding: 9px 13px;
    }
    .Universethumbsup span{
        font-size: 12px!important;
    }
    .MyUniverseBoxSpan i{
        font-size: 18px!important;
        margin-right: 7px!important;
    }
    .MyUniverseBoxSpan span{
        padding: 2px 8px;
        font-size: 12px;
    }
}