.tooltip {
    position: relative;
    background: #0000;
    box-shadow: 0 0 1px 1px grey;
    color: black;
    /* visibility: hidden; */
    /* padding: 5px; */
    border-radius: 5px;
    transition-delay: 5s;
  }
  .tooltiparrow {
    position: absolute;
    padding-top: 10px;
    /* top: -30%; */
    /* left: 42%; */
    /* width: max-content; */
    /* background: rgba(0,0,0,0.5) !important; */
    width: 400px;
    border-radius: 5px;
    /* bottom: 40px; */
    margin-top: 30px;
    margin-left: -265px;
    width: 10;
    background: white;
    border-width: 5px;
    border-style: solid;
    box-shadow: 0 0 0px 1px grey;
    border-color: rgba(0, 0, 0, 0.7) transparent transparent;
    height: 350px;
    /* overflow: scroll; */
  }
  
/* 
  body {
    margin: 0;
    padding: 15px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  } */
  
  /* Popup style */
  .popupbox {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
  }
  
  .box {
    position: relative;
    width: 70%;
    margin: 0 auto;
    height: auto;
    max-height: 70vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
  }
  
  .closeicon {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: calc(15% - 30px);
    top: calc(100vh - 85vh - 33px);
    background: #ededed;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 20px;
  }

  .gridcontainer {
    /* display: grid;
    grid-template-columns: auto auto auto auto; */
    /* gap: 10px; */
    /* background-color: #2196F3;
    padding: 10px; */
    display: grid;
    grid-template-columns: auto auto auto auto;
    justify-items: center;
    align-items: center;
  }
  
  .gridcontainer > img {
    /* background-color: rgba(255, 255, 255, 0.8); */
    text-align: center;
    /* padding: 20px 0; */
    font-size: 30px;
    width: 40%;
    margin: 5px;
  }

  /* gallery display */
.photos {
  display: grid;
}

.photos ul {
  list-style: none;

}

.photos ul li {
    /* background: #fff; */
	display: inline;
	list-style: none;
  	float: left;
    /* margin: 0 10px 10px 0; */
  	padding: 0;
  	text-align:center; 
    color: #000;
    /* border: 1px solid #ccc; */
}

.photos ul li a {
  display: block;
  margin-bottom: 2px; 
  /* opacity: 0.75; */
  font-family: Verdana;
  /* font-size: 11px; */
  text-decoration: none;
  letter-spacing: 1px;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.photos ul li a:hover {
  opacity: 1.0;
}

.text{
  width: 28px;
    height: 28px;
    /* line-height: 500px; */
    border-radius: 50%;
    font-size: 20px;
    color: #fff;
    text-align: center;
    background: #a9d3ab;
    /* background: red; */
    margin-top: 7px;
    margin-bottom: 7px !important;
}
.popuptextalign{
  padding-top: 10px;
  padding-left: 10px;
}
.photos ul li a img {
  /* border: 6px solid #e1d9ec; */
}

.buttonval {
  background-color: #4CAF50;
  border: none;
  border-radius: 5px;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  /* margin: 4px 2px; */
  cursor: pointer;
  margin: 5px;
}

.buttonvalreject {
  background-color: #c9682f;
  border: none;
  border-radius: 5px;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  /* margin: 4px 2px; */
  cursor: pointer;
  margin: 5px;
}